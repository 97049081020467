import { Divider } from 'antd';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Detail, Footer, LeadingContent, Title } from '../Account/style';
import Section from '../_Misc/Section';
import { CardHolder } from './CardHolder';
import { CurrentPlanCard } from './CurrentPlanCard';
import Style from './style';
import { selectSubscription } from '../../../../redux/selectors';
import { Subscription } from '../../../../redux/typings/session';

export type CardProvider =
  | 'amex'
  | 'apple'
  | 'discover'
  | 'google'
  | 'jcb'
  | 'mastercard'
  | 'unionpay'
  | 'visa';

const Billing: React.FC = () => {
  const { Container } = Style;
  const subscription: Subscription | null | undefined = useSelector(selectSubscription);

  const billingInfo = useMemo(() => {
    if (!!subscription) {
      return (
        <>
          <Section title="Payment Method" description="Change how you paid your plan.">
            <LeadingContent>
              <Title>Card information</Title>
              <Detail>Edit your credit card on file</Detail>
            </LeadingContent>
            <Footer>
              <CardHolder
                provider={(subscription as Subscription).cardProvider as CardProvider}
                last4={(subscription as Subscription).last4}
                expiration={(subscription as Subscription).cardExpirity}
                isCanceled={(subscription as Subscription).isCanceled}
              />
            </Footer>
          </Section>
          <Divider />
        </>
      );
    } else {
      return <></>;
    }
  }, [subscription]);

  return (
    <Container>
      <Section
        title="Plans and Billing"
        description="Pick a plan that works best for you and your team."
      />
      <Divider />
      <Section
        title="Current Plan"
        description=""
        childrenBackground={false}
        childrenBorder={false}
      >
        <CurrentPlanCard />
      </Section>
      <Divider />

      {billingInfo}
    </Container>
  );
};

export default Billing;
