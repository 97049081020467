import { organizationDetailsStore } from '../../../stores/organization-details';
import { useEffect } from 'react';
import {
  changeName,
  fetch,
  toggleAllowJoinByDomain,
  changeGlobalMeetingsVisibility,
  disableManageMeetingsVisibilityGlobally,
  enableManageMeetingsVisibilityGlobally,
  updateCrmSettings,
  disconnectPrimaryProfile,
} from '../organizations.service';

export const useOrganizationDetails = () => {
  const {
    name,
    id,
    isFetching,
    fetchError,
    allowJoinByDomain,
    globalMeetingsVisibility,
    manageMeetingsVisibilityGlobally,
    permissions,
    doNotRecordList,
    crmData,
    slack,
  } = organizationDetailsStore();

  useEffect(() => {
    if (!id) {
      fetch();
    }
  }, [id]);

  return {
    fetchError,
    id,
    name,
    crmData,
    hasPrimaryProfileConnected: Boolean(!!crmData?.primaryProfile && !!crmData?.provider),
    isFetching,
    allowJoinByDomain,
    permissions,
    globalMeetingsVisibility,
    manageMeetingsVisibilityGlobally,
    doNotRecordList,
    slack,
    changeName,
    fetch,
    toggleAllowJoinByDomain,
    changeGlobalMeetingsVisibility,
    disableManageMeetingsVisibilityGlobally,
    enableManageMeetingsVisibilityGlobally,
    updateCrmSettings,
    disconnectPrimaryProfile,
    refetch: fetch,
  };
};
