import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

enum TrendDirectionValues {
  Positive = 'positive',
  Negative = 'negative',
  Neutral = 'neutral',
  NotAvailable = 'not_available',
}

export type PositiveDetectionFunction = (value: number, comparisonValue: number) => boolean;

class TrendDirection {
  private direction: TrendDirectionValues;

  constructor(
    value: number,
    comparisonValue: number | null,
    positiveFunction: (value: number, comparisonValue: number) => boolean,
  ) {
    if (comparisonValue === null) {
      this.direction = TrendDirectionValues.NotAvailable;
    } else if (comparisonValue === value) {
      this.direction = TrendDirectionValues.Neutral;
    } else if (positiveFunction(value, comparisonValue)) {
      this.direction = TrendDirectionValues.Positive;
    } else {
      this.direction = TrendDirectionValues.Negative;
    }
  }

  getDirection() {
    return this.direction;
  }

  getColor() {
    switch (this.direction) {
      case TrendDirectionValues.Positive:
        return `#4CAF50`;
      case TrendDirectionValues.Negative:
        return `#F44336`;
      case TrendDirectionValues.Neutral:
        return `#FFC107`;
      case TrendDirectionValues.NotAvailable:
        return `lightgray`;
    }
  }
}

const Indicator = styled.div<{ backgroundColor: string }>`
  padding: 4px 8px;
  color: white;
  text-align: center;
  border-radius: 4px;
  background-color: ${props => props.backgroundColor};
`;

interface Props {
  value: number;
  comparisonValue: number | null;
  positiveDetectionFunction: PositiveDetectionFunction;
}

export const Trend: React.FC<Props> = ({ value, comparisonValue, positiveDetectionFunction }) => {
  const [trendDirectionState, setTrendDirectionState] = useState(
    new TrendDirection(value, comparisonValue, positiveDetectionFunction),
  );

  useEffect(() => {
    setTrendDirectionState(new TrendDirection(value, comparisonValue, positiveDetectionFunction));
  }, [value, comparisonValue, positiveDetectionFunction]);

  return <Indicator backgroundColor={trendDirectionState.getColor()}>{value}</Indicator>;
};
