import React from 'react';
import Styles from './style';
import { ContactCard } from './ContactCard';
import { useAccountOverview } from '../../../../../features/account/account-overview/account-overview.hook';
import { Drawer } from 'antd';
import { ProfileDrawer } from './ProfileDrawer';
import { AccountsContact } from '../../../../../API/types';

const { Container, ContactGroup } = Styles;

export const Stakeholders: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [selectedStakeholder, setSelectedStakeholder] = React.useState<AccountsContact | null>(
    null,
  );

  const { selectedAccount } = useAccountOverview();

  const sortedContacts = [...selectedAccount.contacts];
  sortedContacts.sort((a, b) => a.email.localeCompare(b.email));

  return (
    <div style={{ position: 'relative' }}>
      <Container>
        {sortedContacts.length > 0 ? (
          <ContactGroup>
            {sortedContacts.map(contact => {
              const { email, id, name, noShows, talkRatio, totalMeetings, lastMeetingDate } =
                contact;
              return (
                <ContactCard
                  onClick={() => {
                    setSelectedStakeholder(contact);
                    setOpen(true);
                  }}
                  attendees={totalMeetings - noShows}
                  lastMeeting={lastMeetingDate}
                  noShows={noShows}
                  name={name}
                  meetings={totalMeetings}
                  percentage={talkRatio}
                  email={email}
                  key={id}
                />
              );
            })}
          </ContactGroup>
        ) : (
          'No stakeholders found.'
        )}
      </Container>
      <Drawer
        placement="right"
        key="stakeholders-drawer"
        width="500px"
        style={{ paddingBottom: '20px' }}
        closable={false}
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        <ProfileDrawer stakeholder={selectedStakeholder} />
      </Drawer>
    </div>
  );
};
