import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const SidebarExpandSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <g filter="url(#filter0_d_728_4861)">
        <rect x="10" y="8" width="24" height="24" rx="12" fill="white" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.2105 24.7699C20.0727 24.6266 19.9973 24.4345 20.0011 24.2357C20.0048 24.037 20.0873 23.8478 20.2305 23.7099L24.1685 19.9999L20.2305 16.2899C20.1561 16.2224 20.0961 16.1408 20.0539 16.0497C20.0117 15.9586 19.9882 15.86 19.9848 15.7597C19.9814 15.6594 19.9982 15.5594 20.0342 15.4657C20.0701 15.372 20.1245 15.2864 20.1941 15.2141C20.2638 15.1418 20.3472 15.0843 20.4395 15.0448C20.5318 15.0053 20.631 14.9848 20.7314 14.9844C20.8318 14.984 20.9312 15.0037 21.0238 15.0425C21.1164 15.0812 21.2003 15.1381 21.2705 15.2099L25.7705 19.4599C25.8431 19.5298 25.9008 19.6137 25.9402 19.7065C25.9797 19.7993 26 19.8991 26 19.9999C26 20.1007 25.9797 20.2005 25.9402 20.2933C25.9008 20.386 25.8431 20.4699 25.7705 20.5399L21.2705 24.7899C21.1272 24.9277 20.9351 25.003 20.7363 24.9993C20.5376 24.9955 20.3484 24.913 20.2105 24.7699Z"
          fill="#9EA9B1"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_728_4861"
          x="0"
          y="0"
          width="44"
          height="44"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_728_4861" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_728_4861"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export const SidebarExpand: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={SidebarExpandSVG} {...props} />;
};
