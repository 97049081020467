import React from 'react';
import { useAccountOverview } from '../../../../../features/account/account-overview/account-overview.hook';
import { useAccountInsightTrends } from '../../../../../features/account/account-insight-trends/account-insight-trends.hook';
import { Table } from '../../../../../componentsV2/Table';
import Styles from './styles';
import { Trend } from './components/Trend';
import { Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { MeetingTitleLink } from '../../../../../components/common/MeetingTitleLink';

const { MeetingDate, MeetingTitle, MeetingTitleCell } = Styles;

export const InsightTrends: React.FC = () => {
  const { selectedAccount } = useAccountOverview();
  const { data, isFetching } = useAccountInsightTrends({ accountId: selectedAccount.id });

  return (
    <Table
      loading={isFetching}
      data={data?.events || []}
      columns={[
        {
          title: 'Meeting',
          key: 'meeting',
          render: (v, r) => {
            return (
              <MeetingTitleCell>
                <MeetingTitleLink id={v.id} title={v.title} />
                <MeetingDate>
                  {format(new Date(v.scheduledStartDate), 'eeee, MMMM d, yyyy @ h:mma')}
                </MeetingDate>
              </MeetingTitleCell>
            );
          },
        },
        {
          title: 'Risks',
          key: 'risks',
          width: '150px',
          render: data => {
            const risksData = data.types['Risks'];

            if (!risksData) {
              return null;
            }

            return (
              <Trend
                value={Number(risksData.count)}
                comparisonValue={
                  risksData.previousInsightsAvg ? Number(risksData.previousInsightsAvg) : null
                }
                positiveDetectionFunction={(val, comp) => val < comp}
              />
            );
          },
        },
        {
          title: 'Product Feedback',
          key: 'product-feedback',
          width: '150px',
          render: data => {
            const risksData = data.types['Product Feedback'];

            if (!risksData) {
              return null;
            }

            return (
              <Trend
                value={Number(risksData.count)}
                comparisonValue={
                  risksData.previousInsightsAvg ? Number(risksData.previousInsightsAvg) : null
                }
                positiveDetectionFunction={(val, comp) => val < comp}
              />
            );
          },
        },
        {
          title: 'Advocacy',
          key: 'advocacy',
          width: '150px',
          render: data => {
            const risksData = data.types['Advocacy'];

            if (!risksData) {
              return null;
            }

            return (
              <Trend
                value={Number(risksData.count)}
                comparisonValue={
                  risksData.previousInsightsAvg ? Number(risksData.previousInsightsAvg) : null
                }
                positiveDetectionFunction={(val, comp) => val < comp}
              />
            );
          },
        },
        {
          title: 'Growth Opportunities',
          key: 'growth',
          width: '150px',
          render: data => {
            const risksData = data.types['Growth Opportunities'];

            if (!risksData) {
              return null;
            }

            return (
              <Trend
                value={Number(risksData.count)}
                comparisonValue={
                  risksData.previousInsightsAvg ? Number(risksData.previousInsightsAvg) : null
                }
                positiveDetectionFunction={(val, comp) => val < comp}
              />
            );
          },
        },
      ]}
    />
  );
};
