import React, { ReactNode } from 'react';
import Account from './Account';
import {
  Branding,
  Content,
  Divider,
  Footer,
  Header,
  ItemContainer,
  Nav,
  NavItem,
  NavItemText,
  Navigation,
  Organization,
  OrganizationControl,
  OrganizationDetails,
  OrganizationInfo,
  OrganizationLogo,
  OrganizationName,
  Plan,
} from './style';
import IconSvg from '../../components/common/IconSvg';
import {
  BarChartOutlined,
  CalendarOutlined,
  CloudUploadOutlined,
  LineChartOutlined,
  LoadingOutlined,
  PlayCircleOutlined,
  PlusSquareOutlined,
  SettingOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { Menu, MenuProps, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser, selectSidebarOption, toggleSidebar } from '../../redux/slices/sessionSlice';
import { SidebarLeft } from '../../features/shared/components/Icons/SidebarLeft';
import { SidebarExpand } from '../../features/shared/components/Icons/SidebarExpand';
import { useOrganizationMembers } from '../../features/organizations/hooks/useOrganizationMembers';
import { useOrganizationDetails } from '../../features/organizations/hooks/useOrganizationDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { useLearningCenter } from '../../features/help/learning-center/learning-center.hook';
import { selectUser } from '../../redux/selectors';
import { SubscribeToProPlanLink, UpgradeToProBadge } from '../UpgradeToPro';
import { useInviteBot } from '../../features/bot/invite-bot/invite-bot.hook';
import { OptionalTooltipWrapper } from '../OptionalTooltipWrapper';
import useFeatures from '../../hooks/useFeatures';
import { useUploadRecording } from '../../features/recordings/upload-recording/upload-recording.hook';

interface Props {
  collapsed?: boolean;
  selectedKey?: string;
}

type MenuItem = Required<MenuProps>['items'][number];

export const SideBar: React.FC<Props> = ({ collapsed, selectedKey }) => {
  const { open } = useLearningCenter();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { show } = useInviteBot();
  const features = useFeatures();
  const { show: showUploadRecordingModal } = useUploadRecording();

  const availableFeature = features.uploadRecordings;

  const user = useSelector(selectUser);

  const { name, isFetching } = useOrganizationDetails();
  const { membersCount } = useOrganizationMembers();

  const [isLoading, setIsLoading] = React.useState({ learning_center: false, first_steps: false });

  const logout = () => {
    dispatch(logoutUser());
    navigate('/');
  };

  const goToMyOrganization = () => {
    navigate('/settings?tab=my-organization');
  };

  const toNavItemComponent = (
    onClick: () => void,
    title: string | ReactNode,
    icon: JSX.Element,
  ) => (
    <NavItem onClick={onClick}>
      <ItemContainer>
        {icon}
        {!collapsed && <NavItemText>{title}</NavItemText>}
      </ItemContainer>
    </NavItem>
  );

  const items: MenuItem[] = [
    {
      key: 'meetings',
      label: toNavItemComponent(
        () => navigate('/meetings/recaps'),
        'Meetings',
        <CalendarOutlined style={{ fontSize: '24px', display: 'flex', verticalAlign: 'middle' }} />,
      ),
    },

    {
      key: 'account-insights',
      label: toNavItemComponent(
        () => navigate('/accounts'),
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          Account Insights{!user?.features.accountInsights && <UpgradeToProBadge />}
        </div>,
        <LineChartOutlined
          style={{ fontSize: '24px', display: 'flex', verticalAlign: 'middle' }}
        />,
      ),
    },
    {
      key: 'account-favourites',
      label: toNavItemComponent(
        () => navigate('/accounts/favorites'),
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          Account Favorites{!user?.features.accountInsights && <UpgradeToProBadge />}
        </div>,
        <StarOutlined style={{ fontSize: '24px', display: 'flex', verticalAlign: 'middle' }} />,
      ),
    },
    {
      key: 'customer_intelligence',
      label: toNavItemComponent(
        () => navigate('/insights'),
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          Portfolio Insights{!user?.features.portfolioInsights && <UpgradeToProBadge />}
        </div>,
        <BarChartOutlined style={{ fontSize: '24px', display: 'flex', verticalAlign: 'middle' }} />,
      ),
    },
    {
      key: 'my_library',
      label: toNavItemComponent(
        () => navigate('/libraries'),
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          My Library{!user?.features.favoritesFolder && <UpgradeToProBadge />}
        </div>,
        <PlayCircleOutlined
          style={{ fontSize: '24px', display: 'flex', verticalAlign: 'middle' }}
        />,
      ),
    },
    {
      key: 'settings',
      label: toNavItemComponent(
        () => navigate('/settings'),
        'Settings',
        <SettingOutlined style={{ fontSize: '24px', display: 'flex', verticalAlign: 'middle' }} />,
      ),
    },
  ];

  const supportItems: MenuItem[] = [
    {
      key: 'upload_meeting',
      disabled: !availableFeature,
      label: toNavItemComponent(
        () => showUploadRecordingModal(),
        <>Upload New Meeting {!availableFeature && <UpgradeToProBadge />}</>,
        <OptionalTooltipWrapper display={!availableFeature} value={<SubscribeToProPlanLink />}>
          <CloudUploadOutlined
            style={{ fontSize: '24px', display: 'flex', verticalAlign: 'middle' }}
          />
        </OptionalTooltipWrapper>,
      ),
    },
    {
      key: 'invite_bot',
      label: toNavItemComponent(
        () => show(),
        'Invite Bot to Meeting',
        <PlusSquareOutlined
          style={{ fontSize: '24px', display: 'flex', verticalAlign: 'middle' }}
        />,
      ),
    },
    {
      key: 'learning_center',
      label: toNavItemComponent(
        () => open(),
        'Learning Center',
        isLoading.learning_center ? (
          <Spin size="small" />
        ) : (
          <FontAwesomeIcon
            icon={faGraduationCap}
            size="xl"
            style={{ maxWidth: '24px', maxHeight: '24px' }}
          />
        ),
      ),
    },
    /*
    {
      key: 'first_steps',
      label: toNavItemComponent(
        async () => {
          await toAppcues('070581e2-99ac-4ffe-adb6-80cce1972d75', 'first_steps');
        },
        'Your First Steps',
        isLoading.first_steps ? (
          <Spin size="small" />
        ) : (
          <FontAwesomeIcon
            icon={faShoePrints}
            size="xl"
            style={{ maxWidth: '24px', maxHeight: '24px' }}
          />
        ),
      ),
    },*/
  ];

  const organizationInitial = name.length > 1 ? name.charAt(0) : '';

  return (
    <>
      <Content>
        <Nav>
          <Header onClick={() => dispatch(toggleSidebar())}>
            <Branding>
              {!collapsed ? (
                <>
                  <IconSvg
                    name="updateAiLogoStretched"
                    size="lg"
                    color="transparent"
                    width="130px"
                    height="32px"
                    clickable
                  />
                  <SidebarLeft />
                </>
              ) : (
                <>
                  <IconSvg
                    name="updateFull"
                    size="lg"
                    color="transparent"
                    width="28px"
                    height="32px"
                    clickable
                  />
                  <SidebarExpand
                    style={{
                      display: 'block',
                      position: 'absolute',
                      left: '56px',
                      top: '28px',
                    }}
                  />
                </>
              )}
            </Branding>
          </Header>

          <Organization>
            {!isFetching ? (
              <OrganizationControl onClick={goToMyOrganization}>
                <OrganizationLogo>{organizationInitial}</OrganizationLogo>
                {!collapsed && (
                  <OrganizationInfo>
                    <OrganizationName>{name ?? ''}</OrganizationName>

                    <OrganizationDetails>
                      <Plan>{`${membersCount} Member${membersCount > 1 ? 's' : ''}`}</Plan>
                    </OrganizationDetails>
                  </OrganizationInfo>
                )}
              </OrganizationControl>
            ) : (
              <div style={{ margin: '0 auto' }}>
                <LoadingOutlined style={{ fontSize: 16 }} spin />{' '}
              </div>
            )}
          </Organization>

          <Navigation>
            <Menu
              style={{ width: '100%', border: 'none' }}
              selectedKeys={[selectedKey || '']}
              onSelect={e => {
                dispatch(selectSidebarOption({ selectedSideBarOption: e.key }));
              }}
              theme={'light'}
              items={items}
            />

            <Menu
              style={{ width: '100%', border: 'none' }}
              selectedKeys={[selectedKey || '']}
              theme={'light'}
              items={supportItems}
            />
          </Navigation>
        </Nav>

        <Footer>
          <Divider></Divider>

          {user ? (
            <Account
              firstName={user.firstName}
              lastName={user.lastName}
              email={user.email}
              collapsed={collapsed}
              logout={logout}
            />
          ) : (
            <div style={{ margin: '0 auto' }}>
              <LoadingOutlined style={{ fontSize: 16 }} spin />{' '}
            </div>
          )}
        </Footer>
      </Content>
    </>
  );
};

{
  /*
          !collapsed ? (
            <>
              <Divider></Divider>
              <Ads
                title="Extend your Free Pro Plan Trial!"
                description="Get more free trial time when you share UpdateAI in your company's Slack."
                link="https://help.update.ai/en/articles/8194553-extend-your-free-trial-period-with-updateai"
                primaryButtonText="Find out more >>"
                imageUrl="/ad_rocket.png"
              />
            </>
          ) : null
          */
}
