import React, { useEffect, useMemo, useState } from 'react';
import Styles from './style';
import AccountsPageFilterBar from '../../../../../features/home/components/AccountsPageFilterBar';
import { MeetingRow } from './MeetingRow';
import { format } from 'date-fns';
import { ACCOUNT_DATE_FORMAT } from '..';
import { useAccountOverview } from '../../../../../features/account/account-overview/account-overview.hook';
import { Button, Checkbox, Flex, Input, Spin } from 'antd';
import { coreService } from '../../../../../services/core/core-service';
import { useToast } from '../../../../../hooks/useToast';
import { CopyOutlined } from '@ant-design/icons';
import Card from '../../../../../componentsV2/Card';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { MeetingTitleLink } from '../../../../../components/common/MeetingTitleLink';

const {
  Container,
  MeetingsList,
  Error,
  MeetingRowContainer,
  AISummaryContainer,
  AISummaryLabel,
  AISummaryText,
  GeneratedLabel,
  MeetingTitle,
} = Styles;

const eventVisibilityToLabel = (visibility: string) => {
  if (visibility === 'organization') {
    return 'Workspace';
  }
  if (visibility === 'invitees') {
    return 'Invitees';
  }

  return 'Other';
};

export const PastMeetings: React.FC = () => {
  const { selectedAccount, summarizationRequested } = useAccountOverview();
  const [meetingsSelected, setMeetingsSelected] = useState<any[]>([]);
  const [summarizations, setSummarizations] = useState<any[]>([]);
  const { success } = useToast();

  const { data } = useQuery({
    queryKey: ['user-event-summarization', selectedAccount.id],
    queryFn: () => {
      return coreService.getAccountEventSummarizations(selectedAccount.id);
    },
    refetchOnMount: false,
    refetchInterval: 15000,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setSummarizations(data?.summarizations || []);
  }, [data]);

  const handleSelectedChange = (selected: boolean, eventId: string) => {
    let selections = [...meetingsSelected];
    if (selected) {
      selections.push(eventId);
    } else {
      selections = selections.filter(sel => sel !== eventId);
    }
    setMeetingsSelected(selections);
  };

  const handleSummarize = async () => {
    await coreService
      .summarizeMeetings(selectedAccount.id, meetingsSelected)
      .then(summarization => {
        setMeetingsSelected([]);
        summarizationRequested();
        success('Summarization requested successfully');
        setSummarizations(preiousSum => {
          const sums = [...preiousSum];
          sums.unshift(summarization);
          return sums;
        });
      });
  };

  const rows =
    selectedAccount.pastMeetings?.map(meeting => {
      const { id, date, host, participants, title, visibility, insights, relatedMessages } =
        meeting;
      const formattedDate = format(new Date(date), ACCOUNT_DATE_FORMAT);

      return (
        <MeetingRowContainer>
          <Checkbox
            style={{ paddingTop: '8px' }}
            checked={meetingsSelected.includes(id)}
            disabled={false}
            onChange={e => {
              handleSelectedChange(e.target.checked, id);
            }}
          />
          <MeetingRow
            id={id}
            tlDr={insights.filter(insight => insight.category === 'TL;DR')}
            actionItems={insights.filter(insight => insight.category === 'Action Items')}
            messages={relatedMessages}
            onSelectedChange={selected => handleSelectedChange(selected, id)}
            description={formattedDate}
            host={host}
            onClick={() => window.open(`/recap/${id}`, '_blank')}
            meetingType={eventVisibilityToLabel(visibility)}
            participantsList={participants}
            title={title}
            key={`meetingprep_${id}`}
            meetingId={id}
          />
        </MeetingRowContainer>
      );
    }) || [];

  const sortedSummarizations = useMemo(() => {
    return summarizations.sort(
      (a, b) => new Date(b.lastRequestedAt).getTime() - new Date(a.lastRequestedAt).getTime(),
    );
  }, [summarizations]);

  return (
    <Container>
      <AccountsPageFilterBar
        onSummarize={handleSummarize}
        summarizeDisabled={meetingsSelected.length === 0}
      />

      <Card title="Past Meetings" containerStyle={{ width: '100%' }}>
        <MeetingsList>
          {rows?.length > 0 ? rows : <Error>No past meetings found.</Error>}
        </MeetingsList>
      </Card>

      <Card title="AI Summary History" containerStyle={{ width: '100%', overflow: 'auto' }}>
        {sortedSummarizations.length === 0 ? (
          <Error>No AI summarizations generated</Error>
        ) : (
          <Flex gap={16} vertical>
            {sortedSummarizations.map(sum => {
              return (
                <Flex gap={8} vertical key={sum.id}>
                  <Flex align="center" gap={4}>
                    <GeneratedLabel>Generated Date/Time: </GeneratedLabel>
                    <span>
                      {format(new Date(sum.lastRequestedAt), 'eeee, MMMM d, yyyy @ h:mma')}
                    </span>
                  </Flex>
                  <Flex gap={4}>
                    {sum.events.map((sumEv: any) => {
                      return <MeetingTitleLink id={sumEv.id} title={sumEv.title} />;
                    })}
                  </Flex>
                  <Input.TextArea
                    value={sum.isProcessing ? 'Summary generation in progress...' : sum.narrative}
                    style={{ maxHeight: '200px' }}
                    rows={sum.isProcessing ? 1 : 10}
                  />
                </Flex>
              );
            })}
          </Flex>
        )}
      </Card>
    </Container>
  );
};
