import { Switch, Tooltip } from 'antd';
import React from 'react';
import {
  Container,
  Content,
  Description,
  DescriptionContainer,
  LearnMore,
  RecommendedSetting,
  RecommendedSettingHref,
  Title,
  ToggleContainer,
  TitleContainer,
} from './style';

/**
 * Note from Richard: I hate adding switchPosition
 * but it is the only alternative without refactoring
 * the whole component to position the switch on the title
 * or the description.
 */
interface Props {
  title?: string | React.ReactNode;
  description?: string | React.ReactElement;
  recommendedSetting?: string;
  checked?: boolean;
  onChange?: (e: boolean) => unknown;
  children?: React.ReactNode;
  learnMore?: string;
  onClickRecommendedSetting?: () => void;
  hideToggle?: boolean;
  disabled?: boolean;
  tooltipTitle?: string | React.ReactNode;
  switchPosition?: 'title' | 'description';
}

const ToggleCard: React.FC<Props> = ({
  title,
  description,
  recommendedSetting,
  checked,
  onChange,
  children,
  learnMore,
  onClickRecommendedSetting,
  hideToggle,
  disabled,
  tooltipTitle,
  switchPosition = 'description',
}) => {
  const toggleComponent = (
    <ToggleContainer>
      {!hideToggle && (
        <>
          {disabled ? (
            <Tooltip title={tooltipTitle}>
              <Switch checked={checked} onChange={onChange} disabled />
            </Tooltip>
          ) : (
            <Switch checked={checked} onChange={onChange} />
          )}
        </>
      )}
    </ToggleContainer>
  );

  return (
    <Container>
      <Content>
        {title && (
          <TitleContainer>
            <Title>{title}</Title>
            {switchPosition === 'title' && toggleComponent}
          </TitleContainer>
        )}

        {description && (
          <Description>
            <DescriptionContainer>
              {description}
              {learnMore && (
                <LearnMore href={learnMore} target="_blank">
                  Learn More
                </LearnMore>
              )}
            </DescriptionContainer>
            {switchPosition === 'description' && toggleComponent}
          </Description>
        )}

        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          {children}

          <RecommendedSetting>
            Recommended settings:
            {onClickRecommendedSetting ? (
              <RecommendedSettingHref onClick={onClickRecommendedSetting}>
                {recommendedSetting}
              </RecommendedSettingHref>
            ) : (
              <div style={{ color: '#6A6A6A', fontWeight: '600', paddingLeft: '4px' }}>
                {recommendedSetting}
              </div>
            )}
          </RecommendedSetting>
        </div>
      </Content>
    </Container>
  );
};

export default ToggleCard;
