import React from 'react';
import Column from './Column';

import Styles from '../styles';
import { Button } from 'antd';
import { Trophy } from '../../../../../../../features/shared/components/Icons/Trophy';
import { OpenNew } from '../../../../../../../features/shared/components/Icons/OpenNew';
import { useEmail } from '../../../../../../../features/email/useEmail';

const { RedeemStepsContainer, StepsContainer, LabelDiscrete, Step, Link } = Styles;

const ExtendTrialColumn: React.FC = () => {
  const helpLink =
    'https://help.update.ai/en/articles/8194553-extend-your-free-trial-period-with-updateai';

  const openHelpLink = () => window.open(helpLink, '_blank');

  const { getEmailLink } = useEmail();

  return (
    <Column
      icon={<Trophy />}
      title="Extend your free trial"
      subtitle="We offer a 2-week extension to your free trial for each of the following actions. (And yes, these actions are stackable, just like pancakes! 🥞)"
    >
      <RedeemStepsContainer>
        <StepsContainer>
          <Step>
            <LabelDiscrete>Step 1:</LabelDiscrete>
            <span>
              Choose your activity from the list{' '}
              <Link
                target="_blank"
                href="https://help.update.ai/en/articles/8194553-extend-your-free-trial-period-with-updateai"
              >
                here.
              </Link>
            </span>
          </Step>
          <Step>
            <LabelDiscrete>Step 2:</LabelDiscrete>
            <span>
              Send your claim to{' '}
              <Link
                href={getEmailLink({
                  subject: 'Free trial extension',
                  to: ['concierge@update.ai'],
                  body: '',
                })}
                target="_blank"
                rel="noopener noreferrer"
              >
                concierge@update.ai.
              </Link>
            </span>
          </Step>
          <Step>
            <LabelDiscrete>Step 3:</LabelDiscrete>
            <span>We'll automatically apply your trial extension.</span>
          </Step>
        </StepsContainer>
        <Button
          type="primary"
          onClick={openHelpLink}
          icon={<OpenNew style={{ stroke: 'white' }} />}
        >
          Learn more
        </Button>
      </RedeemStepsContainer>
    </Column>
  );
};

export default ExtendTrialColumn;
