import React from 'react';
import {
  FilterPanel,
  DefaultPanel,
  FilterSection,
  Panel,
  PanelLink,
} from '../../../shared/components/PanelFilters';
import Styles from './styles';
import { Switch } from 'antd';
import { AccountFilterPanel } from '../../../shared/components/AccountFilterPanel';
import { ParticipantsTypeFilter } from '../../../shared/components/ParticipantsTypeFilter';
import { usePastMeetings } from '../../../meetings/past-meetings/past-meetings.hook';

const { Label, Option } = Styles;

export const PastMeetingsFilter: React.FC = () => {
  const {
    updateAccounts,
    meetingType,
    showMineOnly,
    showReadyOnly,
    updateAccountsFiltered,
    meetingTypeChanged,
    changeShowMineOnly,
    changeShowReadyOnly,
    resetFilters,
  } = usePastMeetings();

  const count =
    updateAccounts.length +
    (showMineOnly ? 1 : 0) +
    (showReadyOnly ? 1 : 0) +
    (['internal', 'external'].includes(meetingType) ? 1 : 0);
  const isClearDisabled = count === 0;

  const onClear = () => {
    resetFilters();
  };

  return (
    <FilterPanel count={count}>
      <DefaultPanel clear={{ disabled: isClearDisabled, onClear }}>
        <FilterSection title="General">
          <Option>
            <Label>Show mine only</Label>
            <Switch
              checked={showMineOnly}
              onChange={value => changeShowMineOnly(value)}
              size="small"
            />
          </Option>

          <Option>
            <Label>Show ready only</Label>
            <Switch
              checked={showReadyOnly}
              onChange={value => changeShowReadyOnly(value)}
              size="small"
            />
          </Option>
        </FilterSection>

        <FilterSection title="Accounts">
          <PanelLink
            label="Accounts"
            panelId="accounts"
            value={`Accounts (${updateAccounts.length})`}
          />
        </FilterSection>

        <FilterSection title="Meeting types">
          <ParticipantsTypeFilter
            value={meetingType}
            onParticipantTypeChanged={meetingTypeChanged}
          />
        </FilterSection>
      </DefaultPanel>

      <Panel panelId="accounts" title="Accounts" clear={{ disabled: isClearDisabled, onClear }}>
        <AccountFilterPanel
          filter={value => value}
          onAccountsSelectionChange={updateAccountsFiltered}
          placeholder="Select account"
          selectedAccounts={updateAccounts}
        />
      </Panel>
    </FilterPanel>
  );
};
