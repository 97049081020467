import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';
import Styles from './styles';
import { Space } from 'antd';
import { PropertyValue } from '../PropertyValue';

interface Props {
  actions?: {
    label: string;
    onClick: () => unknown;
  }[];
  name: string | ReactNode;
  value?: string | null | ReactElement;
}

const { PropertyWrapper, PropertyName, ActionText } = Styles;

export const Property: React.FC<PropsWithChildren<Props>> = ({
  actions,
  name,
  value,
  children,
}) => {
  return (
    <PropertyWrapper>
      <Space>
        <PropertyName>{name}</PropertyName>
        {actions && actions?.length > 0 ? (
          actions?.map(a => <ActionText onClick={a.onClick}>{a.label}</ActionText>)
        ) : (
          <></>
        )}
      </Space>
      {children || (value && <PropertyValue crmPropertyValue={value}>{value}</PropertyValue>)}
    </PropertyWrapper>
  );
};
