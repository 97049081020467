import styled from 'styled-components';

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export default {
  ActionsContainer,
  Contents,
  Row,
};
