import Styles from './styles';
import { Button } from 'antd';
import { useState } from 'react';
import { useToast } from '../../../../hooks/useToast';
import { capitalize } from '../../../../helpers/TextHelper';

interface Props {
  id: string;
  title: string;
  item: string;
  disabled?: boolean;
  onSave: (args: { id: string; value: string }) => Promise<unknown>;
}

export const MeddiccCard: React.FC<Props> = ({ id, title, item, disabled, onSave }) => {
  const { Container, Content, Buttons } = Styles;

  const [loading, setLoading] = useState(disabled || false);
  const [showButtons, setShowButtons] = useState(false);
  const [initialValue, setInitialValue] = useState(item);
  const [updatedValue, setUpdatedValue] = useState(item);

  const { success, error } = useToast();

  const handleSave = async () => {
    setLoading(true);
    try {
      const value = {
        id: id,
        value: updatedValue,
      };

      await onSave(value);

      success('Meddicc updated successfully');
      setInitialValue(updatedValue);
    } catch (e) {
      error('Failed to update Meddicc');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <h1>{capitalize(title.replace('_', ' '))}</h1>

      <Content
        value={updatedValue}
        onInput={t => setUpdatedValue(t.currentTarget.value)}
        onMouseEnter={() => setShowButtons(true)}
        onMouseLeave={() => setShowButtons(false)}
        disabled={disabled || loading}
      />

      <Buttons $visible={showButtons} onMouseEnter={() => setShowButtons(true)}>
        <Button
          size="small"
          type="primary"
          onClick={() => handleSave()}
          style={{ borderRadius: '8px' }}
          disabled={loading || initialValue === updatedValue}
          loading={loading}
        >
          Save
        </Button>

        <Button
          size="small"
          type="primary"
          onClick={() => {
            setUpdatedValue(initialValue);
            setShowButtons(false);
          }}
          style={{ borderRadius: '8px' }}
          disabled={loading || initialValue === updatedValue}
          loading={loading}
        >
          Cancel
        </Button>

        <Button
          size="small"
          type="primary"
          onClick={() => {
            navigator.clipboard.writeText(
              capitalize(title.replace('_', ' ')) + ':\n' + (updatedValue || ''),
            );
            setShowButtons(false);
            success('Copied to clipboard');
          }}
          style={{ borderRadius: '8px' }}
          disabled={loading}
          loading={loading}
        >
          Copy
        </Button>
      </Buttons>
    </Container>
  );
};
