import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`;

const GeneratedLabel = styled.span`
  color: #212121;
  font-family: 'Cera Pro Medium';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
`;

const MeetingTitle = styled.span`
  overflow: hidden;
  color: var(--Cool-Gray-700, #0a1e2c);
  text-overflow: ellipsis;
  font-family: 'Cera Pro Medium';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const AISummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

const AISummaryLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #212121;

  /* Text/h4 */
  font-family: 'Cera Pro';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
`;

const MeetingRowContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  gap: 8px;
`;

const MeetingsList = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 4px;
`;

const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  width: 100%;
  height: 100%;
`;

const AISummaryText = styled.div`
  padding: 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: var(--spacing-md, 8px);
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: var(--Global-white, #fafafa);
  white-space: pre-wrap;
  max-height: 200px;
  overflow-y: auto;
`;

export default {
  Error,
  Container,
  MeetingsList,
  MeetingRowContainer,
  AISummaryContainer,
  AISummaryLabel,
  AISummaryText,
  GeneratedLabel,
  MeetingTitle,
};
