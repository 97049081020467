import styled from 'styled-components';

const Container = styled.div`
  gap: 16px;
  width: 100%;
  padding: 12px 16px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: var(--N-900, #0d283b);
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: 1.68px;
  align-items: center;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
`;

const EmptyView = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Cool-Gray-400, #566976);
`;

export default { Container, Header, Buttons, EmptyView };
