import React from 'react';
import Styles from './styles';
import { Beta } from '../../Icons/Beta';
import { useEmail } from '../../../../email/useEmail';

const { Container, Label } = Styles;

const BetaBadge: React.FC = () => {
  const { getEmailLink } = useEmail();

  return (
    <Container
      href={getEmailLink({
        subject: 'User Feedback or Request on UpdateAI Feature',
        to: ['support@update.ai'],
        body: 'Hi UpdateAI Support Team, \n\nBelow is [feedback/issue/concern/idea] I have for the following feature found on this product URL [Insert URL]',
      })}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Beta />
      <Label>Beta</Label>
    </Container>
  );
};

export default BetaBadge;
