import styled from 'styled-components';

const Container = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  background: var(--cool-gray-950, #030c11);
  padding: 0px;

  :hover {
    cursor: pointer;
  }
`;

export default { Container };
