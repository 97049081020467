import React, { useEffect, useMemo, useState } from 'react';
import { Property } from '../Property';
import { coreService } from '../../../../../../services/core/core-service';
import { Select, Space, Tooltip } from 'antd';
import { PropertyValue } from '../PropertyValue';
import { useToast } from '../../../../../../hooks/useToast';
import { QuestionCircleOutlined } from '@ant-design/icons';

type Deal = {
  id: string;
  name: string;
};

interface Props {
  primaryDealId: string | null;
  deals: Deal[];
  accountId: string;
  onPrimaryDealChanged: (dealId: string) => unknown;
}

export const DealNameProperty: React.FC<Props> = ({
  deals,
  primaryDealId,
  accountId,
  onPrimaryDealChanged,
}) => {
  const [isChanging, setIsChanging] = useState(false);
  const [selectedPrimaryDeal, setSelectedPrimaryDeal] = useState<string | null>(primaryDealId);
  const { error, success } = useToast();

  useEffect(() => {
    setSelectedPrimaryDeal(primaryDealId);
  }, [primaryDealId]);

  const handleSave = async () => {
    try {
      if (selectedPrimaryDeal) {
        await coreService.changePrimaryDeal(accountId, selectedPrimaryDeal);
        onPrimaryDealChanged(selectedPrimaryDeal);
      }
      success('Primary deal changed successfully.');
    } catch (e) {
      console.error(e);
      error('Failed to change primary deal. Please try again.');
    } finally {
      setIsChanging(false);
    }
  };

  const handleChange = () => setIsChanging(true);

  const actions = isChanging
    ? [
        {
          label: 'Save',
          onClick: handleSave,
        },
        {
          label: 'Cancel',
          onClick: () => setIsChanging(false),
        },
      ]
    : [
        {
          label: 'Change',
          onClick: handleChange,
        },
      ];

  const primaryDealData = useMemo(() => {
    if (!Array.isArray(deals)) {
      return null;
    }
    return deals.find(deal => deal.id === selectedPrimaryDeal);
  }, [selectedPrimaryDeal, deals]);

  return (
    <Property
      name={
        <Space>
          <span>Deal Name</span>
          <Tooltip title="By default, we automatically map to a deal using our business logic. You can manually choose a different deal at any time.">
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      }
      actions={actions}
    >
      <>
        {isChanging ? (
          <Select
            onChange={value => setSelectedPrimaryDeal(value)}
            value={primaryDealId}
            options={deals.map(deal => ({ label: deal.name, value: deal.id }))}
            size="small"
          />
        ) : (
          <PropertyValue crmPropertyValue={primaryDealData?.name || 'No primary deal selected'} />
        )}
      </>
    </Property>
  );
};
