import styled from 'styled-components';

const Container = styled.div`
  width: 720px;
  height: 636px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Header = styled.div`
  gap: 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-family: Cera Pro;
  font-size: 23px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  align-items: center;
`;

const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #0d283b;
`;

const Items = styled.div`
  height: 462px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
`;

export default {
  Container,
  Header,
  Items,
  Title,
  SubTitle,
};
