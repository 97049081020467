import styled from 'styled-components';

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export default {
  ActionsContainer,
};
