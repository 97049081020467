import styled from 'styled-components';
import { colors } from '../../theme/colors';

interface Props {
  editable: boolean;
  editing: boolean;
  color: string;
  hoverColor: string;
  isOpen: boolean;
}

const Container = styled.li`
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
`;

export const ActionsContainer = styled.div`
  margin-top: 4px;
`;

export const Header = styled.li<{ hoverColor: string }>`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  gap: 8px;
  &:hover {
    background-color: ${props => props.hoverColor};
  }
`;

export const BulletEditable = styled.div<Props>`
  border: none;
  resize: none;
  color: ${props => (props.editing ? props.hoverColor : 'black')};
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  outline: none;
  font-family: 'Cera Pro';
  display: inline-block;
  flex: 1;
  vertical-align: middle;
  font-weight: ${props => (props.isOpen ? 'bolder' : 'regular')};
  cursor: ${props => (!props.editable ? 'default' : props.editing ? 'text' : 'pointer')};
  background-color: ${props => (props.editing ? props.color : 'transparent')};
  border: 1px solid ${props => (props.editing ? props.color : 'transparent')};
  font-size: 14px;
  border-radius: 4px;
  line-height: 24px;
  &:hover {
    background-color: ${props => props.color};
  }
`;

const FloatingBar = styled.div`
  display: none;
  padding: 8px 12px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  position: absolute;
  top: -34px;
  border-radius: 6px;
  border: 1px solid ${colors.coolGray[100]};
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(86, 105, 118, 0.2);
  right: 0px;
`;

const Space = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

const MessageList = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 24px;
  margin-left: 12px;
  padding-left: 12px;
  border-left: 2px solid ${props => props.color};
`;

export default {
  Container,
  FloatingBar,
  Space,
  MessageList,
};
