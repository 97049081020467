import { PropsWithChildren, useEffect, useRef } from 'react';
import { pastMeetingsContext } from './past-meetings.context';
import { createPastMeetingsStore } from './past-meetings.store';
import { useQuery } from '@tanstack/react-query';
import { coreAPI } from '../../../API/core';
import { FetchPastEventsArgs } from '../../../API/types';

export const PastMeetingsProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const store = useRef(createPastMeetingsStore()).current;
  const {
    dateRange,
    showMineOnly,
    showReadyOnly,
    searchTerm,
    pageSize,
    page,
    meetingType: participantsType,
    updateAccounts,
    contacts,
    fetchId,
    fetchStatusChanged,
    eventsFetched,
    paginationMetadataSet,
  } = store();

  const { data, isFetching } = useQuery({
    queryKey: [
      dateRange,
      showMineOnly,
      showReadyOnly,
      searchTerm,
      participantsType,
      updateAccounts,
      contacts,
      page,
      fetchId,
    ],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: () => {
      const searchArgs: FetchPastEventsArgs = {
        page,
        limit: pageSize,
        hostOnly: String(showMineOnly),
        readyOnly: String(showReadyOnly),
      };

      if (searchTerm) {
        searchArgs['search'] = searchTerm;
      }

      if (dateRange[0] && dateRange[1]) {
        searchArgs['from'] = dateRange[0].toISOString();
        searchArgs['to'] = dateRange[1].toISOString();
      }

      if (participantsType !== 'all') {
        searchArgs['participantsType'] = participantsType;
      }

      if (updateAccounts.length) {
        searchArgs['accountIds'] = updateAccounts.map(a => a.id).join(',');
      }

      if (contacts.length) {
        searchArgs['contactIds'] = contacts.join(',');
      }

      const api = new coreAPI();
      return api.fetchPastEvents(searchArgs);
    },
  });

  useEffect(() => {
    fetchStatusChanged(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (data) {
      eventsFetched(data.items);
      paginationMetadataSet(data.metadata);
    }
  }, [data]);

  return (
    <pastMeetingsContext.Provider
      value={{
        store,
      }}
    >
      {children}
    </pastMeetingsContext.Provider>
  );
};
