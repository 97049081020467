import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import Styled from './style';
import { Button } from 'antd';
import { Divider } from 'antd';
import { UpdateLoader } from '../../../../../../../componentsV2/UpdateLoader';
import { LogoHubspot } from '../../../../../../../features/shared/components/Icons/LogoHubspot';
import { LogoSalesforce } from '../../../../../../../features/shared/components/Icons/LogoSalesforce';
import { propertyMappingContext } from '../../../../../../../features/crm/property-mapping/property-mapping.context';
import { MappingCategory } from './MappingCategory';
import {
  CrmProperty,
  Mapping,
  Property,
} from '../../../../../../../features/crm/property-mapping/types';
import { UpdateMappingArgs } from '../../../../../../../services/types';
import { useSession } from '../../../../../../../hooks/useSession';
import { coreService } from '../../../../../../../services/core/core-service';
import { extractError } from '../../../../../../../utils/api';
import { useToast } from '../../../../../../../hooks/useToast';
import { useOrganizationDetails } from '../../../../../../../features/organizations/hooks/useOrganizationDetails';

const logoProvider: { [k: string]: ReactElement } = {
  hubspot: <LogoHubspot />,
  salesforce: <LogoSalesforce />,
};

const { Container, Title, Buttons, Columns, LeftColumn, RightColumn, Header, Body } = Styled;

interface Props {
  disableUserInteractions: boolean;
  provider: 'hubspot' | 'salesforce' | null;
}

export const MappingSection: React.FC<Props & { ref: React.Ref<HTMLDivElement> }> =
  React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { disableUserInteractions, provider } = props;
    const { store } = useContext(propertyMappingContext);
    const { user } = useSession();
    const [currentMapping, setCurrentMapping] = useState<Mapping[]>([]);
    const { crmData } = useOrganizationDetails();
    const state = store();
    const [isSaving, setIsSaving] = useState(false);
    const { error: errorToast, success: successToast } = useToast();

    const { isFetching, properties, mapping } = state;

    useEffect(() => {
      setCurrentMapping(mapping);
    }, [mapping]);

    const categories = useMemo(() => {
      const propertiesMap = new Map<string, Property[]>();

      properties.forEach(prop => {
        const propertiesList = propertiesMap.get(prop.category) || [];
        propertiesList.push(prop);
        propertiesMap.set(prop.category, propertiesList);
      });

      return Array.from(propertiesMap.keys()).map(category => {
        return {
          category,
          properties: propertiesMap.get(category) || [],
        };
      });
    }, [properties, mapping]);

    const handleMappingRemoved = (propertyId: string) => {
      const newMappings = mapping.map(mapp => {
        if (mapp.property.id === propertyId) {
          mapp.crmProperty = null;
        }
        return mapp;
      });
      setCurrentMapping(newMappings);
    };

    const handleMappingChanged = (propertyId: string, crmProperty: CrmProperty) => {
      const newMappings = mapping.map(mapp => {
        if (mapp.property.id === propertyId) {
          mapp.crmProperty = crmProperty;
        }
        return mapp;
      });
      setCurrentMapping(newMappings);
    };

    const handleSave = async () => {
      if (user?.membership.organization.id) {
        setIsSaving(true);
        try {
          const workspaceId = user.membership.organization.id;
          const validMappings = currentMapping.filter(mapping => mapping.crmProperty !== null);
          const args: UpdateMappingArgs = {
            workspaceId,
            mapping: validMappings.map(mapping => ({
              propertyId: mapping.property.id,
              crmPropertyId: mapping.crmProperty?.id || '',
            })),
          };
          await coreService.updateCrmMapping(args);
          successToast(`CRM field mapping updated`);
        } catch (error) {
          const message = extractError(error);
          errorToast(message);
        } finally {
          setIsSaving(false);
        }
      }
    };

    return (
      <>
        <Container ref={ref}>
          <Title>CRM Field Mapping</Title>

          <Divider style={{ margin: '12px 0px' }} />

          <Columns>
            <LeftColumn>
              <Header>UpdateAI Account Properties</Header>
            </LeftColumn>

            <RightColumn>
              <Header>CRM Values</Header>
              <div style={{ width: '310px', display: 'flex', justifyContent: 'center' }}>
                {provider && logoProvider[provider]}
              </div>
            </RightColumn>
          </Columns>

          {isFetching ? (
            <UpdateLoader min />
          ) : (
            <Body>
              {crmData.properties.isSynchronizing
                ? `Once properties are synchronized you will be able to map CRM properties to UpdateAI accounts.`
                : categories.map(category => {
                    return (
                      <MappingCategory
                        properties={category.properties}
                        mappings={currentMapping.filter(
                          m => m.property.category === category.category,
                        )}
                        title={category.category}
                        onRemoveMapping={handleMappingRemoved}
                        onMappingChanged={(propertyId, crmProperty) =>
                          handleMappingChanged(propertyId, {
                            category: category.category,
                            ...crmProperty,
                          })
                        }
                      />
                    );
                  })}
            </Body>
          )}
        </Container>

        <Buttons>
          <Button
            type="primary"
            loading={isSaving}
            disabled={disableUserInteractions}
            onClick={handleSave}
          >
            Save Changes
          </Button>
        </Buttons>
      </>
    );
  });
