import styled from 'styled-components';

const Container = styled.div`
  background-color: #ffffff;
  border: 1px solid #dfe2e6;
  box-shadow: 0px 20px 24px rgba(24, 30, 36, 0.06);
  border-radius: 6px;

  h1 {
    font-family: Cera Pro;
    font-size: 16px;
    font-weight: 500;
    line-height: 20.11px;
    text-align: left;
    margin: 0;
  }

  h2 {
    font-family: Cera Pro;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 15.08px;
    text-align: left;
    margin: 0;
  }

  p {
    font-family: Cera Pro;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin: 0;
    color: #6a6a6a;
    margin-top: -10px;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  & > p {
    font-size: 16px;
  }
`;

const Content = styled.div`
  padding: 24px 16px;
`;

const Title = styled.div`
  color: var(--Cool-Gray-500, #0d283b);
  font-family: 'Cera Pro';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1px solid #d2d2d2;
  padding: 16px;
`;

const Subtitle = styled.span`
  color: var(--Gray-700, #6a6a6a);
  /* text-sm/leading-5/font-normal */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const LearnMoreLink = styled.a`
  color: var(--P-500, #ff6c54);
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
`;

export default { Title, Content, Container, TitleContainer, LearnMoreLink, Subtitle };
