import React, { useState } from 'react';
import Styles from './styles';
import { Button, Input } from 'antd';
import { useToast } from '../../hooks/useToast';

const { Container } = Styles;

interface Props {
  onBack: () => unknown;
  backLabel: string;
  primaryLabel: string;
  getUrlFunction: (email: string) => Promise<string>;
}

export const SsoForm: React.FC<Props> = ({ onBack, backLabel, primaryLabel, getUrlFunction }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [isFetchingUrl, setIsFetchingUrl] = useState(false);
  const { error: errorToast } = useToast();
  const disabledButton = emailAddress === '';

  const handleClick = () => {
    setIsFetchingUrl(true);
    getUrlFunction(emailAddress)
      .then(data => {
        window.open(data, '_blank');
      })
      .catch(error => errorToast(`SSO is not enabled for your e-mail domain`))
      .finally(() => setIsFetchingUrl(false));
  };
  return (
    <Container>
      <Input
        onChange={ev => setEmailAddress(ev.target.value)}
        placeholder="Enter your e-mail address"
      />
      <Button
        onClick={handleClick}
        loading={isFetchingUrl}
        disabled={disabledButton}
        type="primary"
      >
        {primaryLabel}
      </Button>
      <Button
        type="default"
        href="#"
        onClick={ev => {
          ev.preventDefault();
          ev.stopPropagation();
          onBack();
        }}
      >
        {backLabel}
      </Button>
    </Container>
  );
};
