import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 12px;

  h1 {
    color: var(--Gray-950, #282828);
    font-family: 'Cera Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.08px;
    margin: 0;
    padding: 0;
  }

  h2 {
    color: var(--Gray-950, #282828);
    font-family: 'Cera Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.06px;
    margin: 0;
    padding: 0;
  }
`;

export const Detail = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  span {
    overflow: hidden;
    color: #212121;
    text-overflow: ellipsis;
    font-family: 'Cera Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  h3 {
    color: var(--Gray-950, #282828);
    font-family: 'Cera Pro Medium';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
    padding: 0;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export default { Container, Detail, Section };
