import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

export const Content = styled.div`
  display: flex;
  padding: 16px;
  width: 100%;
`;

export const Seats = styled.div`
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--new-color-shades-gray-100, #f0f0f0);
`;

export const Used = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--new-color-shades-gray-950, #282828);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

export const Licenses = styled.div`
  display: flex;
  gap: 50px;
  margin-top: 32px;
  margin-bottom: 32px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const LicensesContent = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Subs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 30px;

  > p {
    margin: 0;
    color: #282828;
    cursor: default;

    font-size: 12px;
    line-height: 1.2;
    background: #fff;
    display: flex;
    align-items: center;
    border-left: 3px solid #d1d1d1;
    padding: 0px 16px;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 5px;

  > h1 {
    color: var(--new-color-shades-gray-950, #282828);
    font-family: Cera Pro;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    line-height: normal;
    margin: 0;
  }

  > h2 {
    color: var(--new-color-shades-gray-950, #282828);

    font-style: normal;
    font-size: 14px;
    font-weight: 100;
    line-height: 18px;
    margin: 0;
  }
`;
