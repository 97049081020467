import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  display: flex;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #ff6c54;
  gap: 10px;
  cursor: pointer;
`;

const Headline = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #566976;

  span {
    font-weight: 700;
    background-color: yellow;
  }
`;

export default { Container, Title, Headline };
