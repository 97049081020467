import { useMemo, useEffect } from 'react';
import { Library, LibraryItem, librariesStore } from '../../../stores/libraries';
import { useSession } from '../../../hooks/useSession';
import { useQuery } from '@tanstack/react-query';
import { FolderItem } from '../types';
import { coreService } from '../../../services/core/core-service';
import { useToast } from '../../../hooks/useToast';
import { createFolder, removeFolder } from '../../../features/libraries/libraries.service';
import * as librariesActions from '../../../stores/libraries';
import * as libraryService from '../../../features/libraries/libraries.service';
import { useRecapAnalytics } from '../../recapv2/recap-analytics/hooks/useRecapAnalytics';

export type FolderItemContent = FolderItem & { libraryId: string; folderId: string };
export type SelectableFolderItem = FolderItemContent & { isSelected: boolean };

export const useLibraries = () => {
  const {
    isFetchingItems,
    isFetchingLibraries,
    libraries,
    selectedLibraryId,
    selectedFolderId,
    insightSelected,
  } = librariesStore();

  const { success, error } = useToast();
  const { user } = useSession();
  const { smartClip } = useRecapAnalytics();

  const { data, isFetching, refetch } = useQuery({
    queryKey: [user],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: async () => {
      const libraries = await coreService.fetchUserLibraries();
      return libraries;
    },
  });

  useEffect(() => {
    if (isFetching) {
      librariesActions.fetchStarted();
    } else {
      librariesActions.fetchEnded();
    }
  }, [isFetching]);

  useEffect(() => {
    if (data && data.libraries?.length > 0) {
      const libraries: Library[] = data.libraries.map(library => {
        return {
          id: library.id,
          type: library.type === 'user' ? 'user' : 'organization',
          folders: library.folders.map(folder => {
            return {
              id: folder.id,
              name: folder.name,
              isStarred: folder.systemFolder === true,
              items: [],
              itemsCount: folder.itemsCount,
            };
          }),
        };
      });
      librariesActions.librariesFetched(libraries);
    }
  }, [data]);

  const userLibrary = useMemo(() => {
    return libraries.find(library => library.type === 'user');
  }, [libraries]);

  const organizationLibrary = useMemo(() => {
    return libraries.find(library => library.type === 'organization');
  }, [libraries]);

  const selectLibrary = (libraryId: string) => {
    librariesActions.setSelectedLibraryId(libraryId);
    if (libraryId !== selectedLibraryId) {
      librariesActions.folderIdSelected('');
    }
  };

  const selectFolder = async (folderId: string) => {
    librariesActions.folderIdSelected(folderId);
    libraryService.fetchItems(folderId, selectedLibraryId || '');
  };

  const folders = useMemo(() => {
    const library = libraries.find(lib => lib.id === selectedLibraryId);
    if (library) {
      return library.folders;
    }
    return [];
  }, [selectedLibraryId, libraries]);

  const folder = useMemo(() => {
    const library = libraries.find(lib => lib.id === selectedLibraryId);
    if (library) {
      const folder = library.folders.find(folder => folder.id === selectedFolderId);
      return folder;
    }

    return null;
  }, [selectedLibraryId, selectedFolderId, libraries, folders]);

  const items = useMemo(() => {
    const library = libraries.find(lib => lib.id === selectedLibraryId);
    if (library) {
      const folder = library.folders.find(folder => folder.id === selectedFolderId);
      if (folder) {
        return folder.items;
      }
    }
    return [];
  }, [selectedLibraryId, selectedFolderId, libraries, folders]);

  const removeItemFromData = (libraryId: string, folderId: string, itemId: string) => {
    const library = libraries.find(lib => lib.id === libraryId);
    const libs = [...libraries.filter(lib => lib.id !== libraryId)];

    if (library && libs) {
      const folder = library.folders.find(folder => folder.id === folderId)!;
      const folders = library.folders.filter(folder => folder.id !== folderId);

      const updatedFolder = { ...folder, items: items.filter(item => item.id !== itemId) };
      const updatedLib = { ...library, folders: [...folders, updatedFolder] };

      librariesActions.librariesFetched([...libs, updatedLib]);
    }
  };

  const removeItem = async (libraryId: string, folderId: string, itemId: string) => {
    try {
      await libraryService.removeItem(libraryId, folderId, itemId);
      success('Smart Clip™️ removed from favorites folder');
      removeItemFromData(libraryId, folderId, itemId);
    } catch (e) {
      error((e as Error).message);
    } finally {
      libraryService.fetchItems(folderId, selectedLibraryId || '');
    }
  };

  const recentItems = useMemo(() => {
    if (!data || data.recent?.length === 0) return [];
    const recentItemsArray: (FolderItem & { libraryId: string; folderId: string })[] =
      data.recent?.map(folderItem => {
        return {
          ...folderItem,
          folderId: folderItem.folder.id,
          libraryId: folderItem.folder.libraryId,
          duration: folderItem.duration || '0',
          thumbnail: '',
          thumbail: '',
        };
      });
    return recentItemsArray;
  }, [data]);

  const manageInsight = (insightId: string) => {
    libraryService.selectInsightItem(insightId);
  };

  const quitManagingInsight = () => {
    librariesActions.insightToManageDeselected();
  };

  const handleCreateFolder = async (folderName: string, selectedLibraryId: string) => {
    const folderId = await createFolder(folderName, selectedLibraryId);
    await refetch();
    smartClip.favoriteFolder.created();
    return folderId;
  };

  const handleRenameFolder = async (libraryId: string, folderId: string, folderName: string) => {
    await libraryService.renameFolder(libraryId, folderId, folderName);
    await refetch();
    smartClip.favoriteFolder.updated();
  };

  const handleRemoveFolder = async (libraryId: string, folderId: string) => {
    await removeFolder(libraryId, folderId);
    await refetch();
    smartClip.favoriteFolder.removed();
  };

  const addItemsToFolder = async (folderId: string, insightIdsToSave: string[]) => {
    await coreService.addItemsToFolder(folderId, insightIdsToSave);
    refetch();
  };

  const itemMapper = (
    item: LibraryItem[],
    selectedLibraryId?: string,
    selectedFolderId?: string,
  ): SelectableFolderItem[] => {
    return item.map(i => {
      return {
        ...i,
        category: i.category,
        libraryId: selectedLibraryId || i.folder.libraryId,
        folderId: selectedFolderId || i.folder.id,
        isSelected: false,
      };
    });
  };

  return {
    refetchLibraries: refetch,
    insightSelected,
    quitManagingInsight,
    manageInsight,
    selectedLibraryId,
    selectedFolderId,
    isFetchingItems,
    selectFolder,
    isFetchingLibraries,
    userLibrary,
    createFolder: handleCreateFolder,
    organizationLibrary,
    selectLibrary,
    renameFolder: handleRenameFolder,
    removeFolder: handleRemoveFolder,
    items,
    removeItem,
    itemMapper,
    recentItems,
    addItemsToFolder,
    folder,
    folders,
  };
};
