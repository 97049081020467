import React from 'react';
import Styles from './style';
import Avatar from '../../../../../../features/shared/components/Avatar';
import { Progress } from 'antd';
import { format } from 'date-fns';
import { colors } from '../../../../../../theme/colors';
import { ACCOUNT_DATE_FORMAT } from '../..';
import { ContactCardContent } from './ContactCardContent';

const { Container } = Styles;

interface Props {
  name: string;
  percentage: number;
  meetings: number;
  attendees: number;
  email: string;
  lastMeeting: string;
  noShows: number;
  role?: string;
  onClick: () => void;
}

export const ContactCard: React.FC<Props> = ({ onClick, ...props }) => {
  return (
    <Container onClick={onClick}>
      <ContactCardContent {...props} />
    </Container>
  );
};
