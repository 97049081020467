import { useEffect, useState } from 'react';
import { coreAPI } from '../API/core';
import { useDebounce } from 'use-debounce';
import { ProviderIntegration } from '../redux/typings/store';

export const useSearchAccountOrEmailDomain = () => {
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebounce(search, 300);
  const [accounts, setAccounts] = useState<ProviderIntegration[]>([]);
  const [isSearching, setIsSearching] = useState(false);

  const clearAccounts = () => {
    setAccounts([]);
  };

  const searchAccountOrEmailDomain = () => {
    setIsSearching(true);
    setAccounts([]);

    const api = new coreAPI();
    api
      .fetchUserAccounts(search)
      .then(data => {
        setAccounts(data.accounts);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => setIsSearching(false));
  };

  useEffect(() => {
    if (search !== '' && search.length >= 3) searchAccountOrEmailDomain();
  }, [debouncedSearch]);

  return {
    accounts,
    isSearching,
    setSearch,
    setAccounts,
    search,
    clearAccounts,
  };
};
