import React, { ReactNode } from 'react';
import Styled from './styled';
import { Flex, Skeleton } from 'antd';
const { Container, Content, Title, TitleContainer, Subtitle, LearnMoreLink } = Styled;

interface Props {
  children: ReactNode;
  title?: string;
  subtitle?: string;
  learnMoreLink?: string;
  loading?: boolean;
  containerStyle?: React.CSSProperties;
  extra?: ReactNode;
}

const Card: React.FC<Props> = (props: Props) => {
  const { children, extra } = props;
  return (
    <Container style={props.containerStyle}>
      {props.title && (
        <TitleContainer>
          <Flex justify="space-between" align="center">
            <Title>{props.title}</Title>
            {extra}
          </Flex>
          {props.subtitle && (
            <Subtitle>
              {props.subtitle}{' '}
              {props.learnMoreLink ? (
                <LearnMoreLink href={props.learnMoreLink} target="_blank">
                  Learn more
                </LearnMoreLink>
              ) : (
                ''
              )}
            </Subtitle>
          )}
        </TitleContainer>
      )}
      <Content>{props.loading ? <Skeleton active /> : children}</Content>
    </Container>
  );
};

export default Card;
