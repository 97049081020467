import React, { ReactNode } from 'react';
import { Layout as LayoutComponent } from '../Layout';
import { Layout } from 'antd';
import { selectSession, selectUser } from '../../../../redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from '../../../../redux/slices/sessionSlice';
import Styles from './styles';
import { SideBar } from '../../../../componentsV2/SideBar';

const { Container, Title, Contents, Header, TitleDescription } = Styles;
interface Props {
  children: React.ReactElement | React.ReactElement[];
  layoutStyle?: React.CSSProperties;
  sider?: boolean;
}

const { Sider } = Layout;

export const VerticalFixedLayout: React.FC<Props> = ({
  children,
  layoutStyle = { height: '100vh' },
  sider = true,
}) => {
  const session = useSelector(selectSession);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  return (
    <>
      {/*<SiteNavigationBar />*/}
      <Layout style={layoutStyle}>
        {user && sider && (
          <Sider
            style={{ background: '#FFFFFF' }}
            theme="light"
            collapsible
            width={280}
            collapsedWidth={80}
            collapsed={session.sidebarCollapsed}
            onCollapse={() => dispatch(toggleSidebar())}
            trigger={null}
          >
            <SideBar
              collapsed={session.sidebarCollapsed}
              selectedKey={session.selectedSideBarOption}
            />
          </Sider>
        )}
        <LayoutComponent>{children}</LayoutComponent>
      </Layout>
    </>
  );
};

interface TitledVerticalFlexLayoutProps extends Props {
  title: ReactNode;
  titleDescription?: ReactNode;
  extraTitle?: ReactNode;
}

export const TitledVerticalFlexLayout: React.FC<TitledVerticalFlexLayoutProps> = ({
  children,
  title,
  titleDescription,
  layoutStyle,
  extraTitle,
}) => {
  return (
    <VerticalFixedLayout layoutStyle={layoutStyle}>
      <Container>
        <Header>
          <Title>{title}</Title>
          {extraTitle}
        </Header>

        {titleDescription && <TitleDescription>{titleDescription}</TitleDescription>}

        <Contents hasDescription={!!titleDescription}>{children}</Contents>
      </Container>
    </VerticalFixedLayout>
  );
};
