import React, { useEffect, useState } from 'react';
import { Mentions, TopMentionsType } from '../../../../../../services/types';
import { Meeting } from './components/Meeting';
import { Pagination, Spin } from 'antd';

import Styles from './styles';
const { Container } = Styles;

interface Props {
  fetching: boolean;
  mentions: TopMentionsType | null;
  onPageChange?: (page: number, size: number) => Promise<TopMentionsType>;
}

export const MentionsContainer: React.FC<Props> = ({ fetching, mentions, onPageChange }) => {
  const [elems, setElems] = useState<TopMentionsType | null>(mentions);
  const [loading, setLoading] = useState(false);

  const handlePageChange = async (page: number, size: number) => {
    if (!onPageChange) return;

    setLoading(true);
    try {
      const data = await onPageChange(page, size);
      setElems(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setElems(mentions);
  }, [mentions]);

  return (
    <Container>
      {loading || fetching ? (
        <Spin />
      ) : (
        (elems?.mentions || []).map((m: Mentions) => {
          return (
            <Meeting
              id={m.id}
              insights={m.mentions}
              account={m.account}
              title={m.title}
              scheduledStartDate={new Date(m.scheduledStartDate)}
            />
          );
        })
      )}
      <Pagination
        style={{
          alignSelf: 'flex-end',
        }}
        onChange={handlePageChange}
        hideOnSinglePage
        showSizeChanger={false}
        total={elems?.pagination?.items?.total || 1}
        pageSize={elems?.pagination?.pages?.pageSize || 10}
        current={elems?.pagination?.pages?.currentPage || 1}
      />
    </Container>
  );
};
