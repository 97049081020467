import React, { useState } from 'react';
import {
  Actions,
  BackPageButton,
  Details,
  ForwardPageButton,
  LoaderWrapper,
  MembersContainer,
  Navigation,
  PageButtons,
  TopBar,
} from './style';
import { useOrganizationMembers } from '../../../../../../features/organizations/hooks/useOrganizationMembers';
import { useOrganizationDetails } from '../../../../../../features/organizations/hooks/useOrganizationDetails';
import { UpdateLoader } from '../../../../../../componentsV2/UpdateLoader';
import { Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Section from '../../../_Misc/Section';
import MembersTable from '../MembersTable';
import ToggleCard from '../../../Notifications/ToggleCard';
import IconSvg from '../../../../../../components/common/IconSvg';

interface Props {
  disableUserInteractions?: boolean;
  onAddNewMember: () => unknown;
}

export const MembersSection: React.FC<Props> = ({ disableUserInteractions, onAddNewMember }) => {
  const { isFetching, members, membersCount, filterMembers } = useOrganizationMembers();
  const { allowJoinByDomain, toggleAllowJoinByDomain } = useOrganizationDetails();

  const [currentPage, setCurrentPage] = useState(1);
  const paginationSize = 5;

  const handleNextPage = () => {
    if (membersCount / paginationSize > currentPage) setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <Section
      title="Members"
      description={
        'Play maestro. Orchestrate your team members and their platform permissions! \n\nEmail concierge@update.ai to transfer ownership of your workspace.'
      }
    >
      {!disableUserInteractions && (
        <ToggleCard
          style={{ width: '100%' }}
          extraDescription="Allow new users to automatically join if their email domain matches"
          recommendedSetting="ON"
          checked={allowJoinByDomain}
          onChange={value => toggleAllowJoinByDomain(Boolean(value))}
        />
      )}
      <MembersContainer>
        <TopBar>
          <Actions>
            <Button
              type="primary"
              onClick={() => onAddNewMember()}
              disabled={disableUserInteractions}
              id="invite-new-member-button"
              style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
            >
              <IconSvg name="addNewMember" size="smd" color="transparent" />
              Invite New Member
            </Button>

            <Input
              placeholder="Search by name"
              style={{ width: 300, height: 32 }}
              onInput={e => filterMembers(e.currentTarget.value)}
              suffix={<SearchOutlined />}
            />
          </Actions>

          <Navigation>
            <Details>{`Showing ${currentPage * paginationSize - 4} to ${
              currentPage * paginationSize < membersCount
                ? currentPage * paginationSize
                : membersCount
            } of ${membersCount} results`}</Details>
            <PageButtons>
              <BackPageButton onClick={handlePreviousPage} disabled={currentPage === 1}>
                {'<'}
              </BackPageButton>
              <ForwardPageButton
                onClick={handleNextPage}
                disabled={currentPage * paginationSize > membersCount}
              >
                {'>'}
              </ForwardPageButton>
            </PageButtons>
          </Navigation>
        </TopBar>
      </MembersContainer>

      {isFetching && (
        <LoaderWrapper>
          <UpdateLoader min />
        </LoaderWrapper>
      )}

      {!isFetching && (
        <MembersTable
          data={members}
          page={currentPage}
          paginationSize={paginationSize}
          disableUserInteractions={disableUserInteractions}
        />
      )}
    </Section>
  );
};
