import none from '../../../../../../assets/svg/logo/upcoming/none.svg';
import meet from '../../../../../../assets/svg/logo/upcoming/meet.svg';
import teams from '../../../../../../assets/svg/logo/upcoming/teams.svg';
import zoom from '../../../../../../assets/svg/logo/upcoming/zoom.svg';

export const JoinMeetingAction: React.FC<{ url: string | null }> = ({ url }) => {
  if (!url) return <img src={none} />;

  let svg = none;
  if (url.toLowerCase().includes('.zoom.')) svg = zoom;
  if (url.toLowerCase().includes('.microsoft.')) svg = teams;
  if (url.toLowerCase().includes('.google.')) svg = meet;

  return <img src={svg} onClick={() => window.open(url)} style={{ cursor: 'pointer' }} />;
};
