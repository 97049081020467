import { AccountsContact } from '../../../../../../API/types';
import { Button } from 'antd';
import Card from '../../../../../../componentsV2/Card';
import Styles from './style';
import { CardContent } from '../../../../../../componentsV2/Card/styled';
import { useNavigate } from 'react-router-dom';
import { ProfileAvatar } from '../../../../../../componentsV2/ProfileAvatar';
import { AccountIcon } from '../../../../../../features/shared/components/AccountIcon';
import { useAccountOverview } from '../../../../../../features/account/account-overview/account-overview.hook';
import { coreService } from '../../../../../../services/core/core-service';
import { useToast } from '../../../../../../hooks/useToast';
import { useEffect, useState } from 'react';

interface Props {
  stakeholder: AccountsContact | null;
}

export const ProfileDrawer: React.FC<Props> = ({ stakeholder }) => {
  const { Container, Header } = Styles;

  const [loading, setLoading] = useState(!!stakeholder?.isGeneratingProfile);

  const navigate = useNavigate();
  const { selectedAccount } = useAccountOverview();
  const { success } = useToast();

  useEffect(() => {
    setLoading(!!stakeholder?.isGeneratingProfile);
  }, [stakeholder?.id]);

  if (!stakeholder) return <></>;

  return (
    <Container>
      <Header>
        <ProfileAvatar
          image={<>{(stakeholder.name || stakeholder.email).slice(0, 1).toUpperCase()}</>}
          title={stakeholder.name || stakeholder.email}
          subtitle={
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <AccountIcon provider={selectedAccount.provider} styles={{ width: '17px' }} />{' '}
              {selectedAccount.name}
            </div>
          }
          width={70}
          height={70}
          fontSize={35}
        />

        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            type="default"
            onClick={() => navigate(`/profile/${stakeholder.id}`)}
            disabled={!!!stakeholder.profile}
          >
            Go to Profile
          </Button>
        </div>
      </Header>

      {!stakeholder.profile && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100%',
            gap: '5px',
            color: '#8b8e92',
          }}
        >
          {loading ? (
            <p style={{ textAlign: 'center' }}>
              We’re generating a stakeholder profile for this person. Please wait a moment or check
              back shortly.
            </p>
          ) : (
            <p>A profile has not yet been generated for this stakeholder.</p>
          )}

          <Button
            disabled={loading}
            loading={loading}
            onClick={() => {
              setLoading(true);
              coreService.generateStakeholderProfile(stakeholder.id).then(() => {
                success(`Stakeholder profile is being generated`);
              });
            }}
          >
            Generate Profile
          </Button>
        </div>
      )}

      {stakeholder.profile && (
        <>
          <Card title="About">
            <CardContent>
              <h1>Role</h1>
              <p>{stakeholder.profile?.role.value || 'No data'}</p>

              <h1>Personal</h1>
              <p>{stakeholder.profile?.personal.value || 'No data'}</p>
            </CardContent>
          </Card>

          <Card title="Views">
            <CardContent>
              <h1>Positive Feedback</h1>
              <p>{stakeholder.profile?.positiveFeedback?.value || 'No data'}</p>

              <h1>Negative Feedback</h1>
              <p>{stakeholder.profile?.negativeFeedback?.value || 'No data'}</p>

              <h1>Product or Service Request</h1>
              <p>{stakeholder.profile?.requests?.value || 'No data'}</p>
            </CardContent>
          </Card>
        </>
      )}
    </Container>
  );
};
