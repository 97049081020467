import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Label = styled.span`
  text-align: center;

  /* Text Styles/Paragraph Small */

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
`;

export default {
  Container,
  Label,
};
