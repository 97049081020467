import { Space } from 'antd';
import { Table } from '../../../../../../componentsV2/Table';
import { MentionCount } from '../../../MentionCount';
import { TopicLabel } from '../../../TopicLabel';
import { AccountLabel } from '../../../../../../features/shared/components/AccountLabel';
import { format } from 'date-fns';
import { SelectedTopic } from '../../types';

export type TopAccountsItemType = {
  id: string;
  name: string;
  mentions: number;
};

export interface TopAccountRecord {
  account: {
    id: string;
    name: string;
    provider: string;
  };
  arr: number | null;
  renewalDate: Date | null;
  topRisk?: TopAccountsItemType;
  topProductFeedback?: TopAccountsItemType;
  topAdvocacy?: TopAccountsItemType;
  topGrowth?: TopAccountsItemType;
}

export interface Props {
  data: TopAccountRecord[];
  loading?: boolean;
  onTopicClicked: (args: SelectedTopic) => void;
}

export const TopAccountsTable: React.FC<Props> = ({ data, loading = false, onTopicClicked }) => {
  return (
    <Table
      pagination={false}
      loading={loading}
      columns={[
        {
          title: 'Account',
          key: 'account',
          sorter: (a, b) => a.account.name.localeCompare(b.account.name),
          render: value => {
            return <AccountLabel account={value.account} />;
          },
        },
        {
          title: 'ARR',
          key: 'arr',
          dataIndex: 'arr',
          render: value => `$ ${value}`,
          sorter: (a, b) => (a.arr || 0) - (b.arr || 0),
        },
        {
          title: 'Renewal',
          key: 'renewal',
          dataIndex: 'renewalDate',
          sorter: (a, b) => (a.renewalDate?.getTime() || 0) - (b.renewalDate?.getTime() || 0),
          render: value => {
            if (!value) return `-`;

            const differenceInDays = (value.getTime() - new Date().getTime()) / 1000 / 3600 / 24;
            if (differenceInDays < 0) {
              return `Today`;
            }

            if (differenceInDays <= 120) {
              return `In ${Math.floor(differenceInDays)} days`;
            }

            return format(value, 'yyyy-MM-dd');
          },
        },
        {
          title: 'Top Risk',
          key: 'top-risk',
          dataIndex: 'topRisk',
          sorter: (a, b) => (a.topRisk?.mentions || 0) - (b.topRisk?.mentions || 0),
          render: (value, record) => {
            if (!value) return `-`;

            return (
              <Space size={'small'}>
                <MentionCount count={value.mentions} />
                •
                <TopicLabel
                  name={value.name}
                  onClick={() =>
                    onTopicClicked({
                      topicId: value.id,
                      topicName: value.name,
                      account: record.account,
                    })
                  }
                />
              </Space>
            );
          },
        },
        {
          title: 'Top Product Feedback',
          key: 'top-pf',
          dataIndex: 'topProductFeedback',
          sorter: (a, b) =>
            (a.topProductFeedback?.mentions || 0) - (b.topProductFeedback?.mentions || 0),
          render: (value, record) => {
            if (!value) return `-`;

            return (
              <Space size={'small'}>
                <MentionCount count={value.mentions} />
                •
                <TopicLabel
                  name={value.name}
                  onClick={() =>
                    onTopicClicked({
                      topicId: value.id,
                      topicName: value.name,
                      account: record.account,
                    })
                  }
                />
              </Space>
            );
          },
        },
        {
          title: 'Top Growth',
          key: 'top-growth',
          dataIndex: 'topGrowth',
          sorter: (a, b) => (a.topGrowth?.mentions || 0) - (b.topGrowth?.mentions || 0),
          render: (value, record) => {
            if (!value) return `-`;

            return (
              <Space size={'small'}>
                <MentionCount count={value.mentions} />
                •
                <TopicLabel
                  name={value.name}
                  onClick={() =>
                    onTopicClicked({
                      topicId: value.id,
                      topicName: value.name,
                      account: record.account,
                    })
                  }
                />
              </Space>
            );
          },
        },
        {
          title: 'Top Advocacy',
          key: 'top-advocacy',
          dataIndex: 'topAdvocacy',
          sorter: (a, b) => (a.topAdvocacy?.mentions || 0) - (b.topAdvocacy?.mentions || 0),
          render: (value, record) => {
            if (!value) return `-`;

            return (
              <Space size={'small'}>
                <MentionCount count={value.mentions} />
                •
                <TopicLabel
                  name={value.name}
                  onClick={() =>
                    onTopicClicked({
                      topicId: value.id,
                      topicName: value.name,
                      account: record.account,
                    })
                  }
                />
              </Space>
            );
          },
        },
      ]}
      data={data}
    />
  );
};
