import React from 'react';
import Styled from './style';
import Section from '../../../_Misc/Section';
import { Button } from 'antd';
import { SuccessConnectionDataLabel } from '../../../Integrations/Components/SuccessConnectionDataLabel';
import { OptionalTooltipWrapper } from '../../../../../../componentsV2/OptionalTooltipWrapper';
import { useIntegrationRequest } from '../../../../../../features/integrations/hooks/useIntegrationRequest';
import { useOrganizationDetails } from '../../../../../../features/organizations/hooks/useOrganizationDetails';
import useFeatures from '../../../../../../hooks/useFeatures';
import {
  SubscribeToTeamsPlanLink,
  UpgradeToProBadge,
} from '../../../../../../componentsV2/UpgradeToPro';

interface Props {
  disableUserInteractions: boolean;
}

export const SlackSection: React.FC<Props> = ({ disableUserInteractions }) => {
  const { Content } = Styled;
  const { slack, refetch } = useOrganizationDetails();
  const {
    isIntegrating,
    isRemoving,
    hasIntegration,
    requestIntegration,
    removeWorkspaceIntegration,
  } = useIntegrationRequest({ source: 'SLACK', id: slack?.id, refetch: () => refetch() });

  const features = useFeatures();
  const availableFeature = features.slackIntegration;

  return (
    <div id="slack-integration">
      <Section
        title={
          <div style={{ display: 'flex', gap: '10px' }}>
            Slack {!availableFeature && <UpgradeToProBadge />}
          </div>
        }
        description="Receive meeting summaries and action items right on Slack."
        learnMore="https://help.update.ai/en/articles/6405273-installing-and-using-the-updateai-app-for-slack"
      >
        <Content>
          This unified Slack integration ensures all workspace members are associated with the same
          Slack integration. This allows for a seamless experience when using the Slack integration.
          <div style={{ alignSelf: 'left' }}>
            {!!hasIntegration && (
              <SuccessConnectionDataLabel label="Connected" value={slack?.email || ''} />
            )}
          </div>
          <div>
            <OptionalTooltipWrapper
              display={disableUserInteractions || !availableFeature}
              value={
                !availableFeature ? (
                  <SubscribeToTeamsPlanLink title="Upgrade to a team plan to push your insights to Slack." />
                ) : (
                  'Only Owners or Admins can manage slack integrations'
                )
              }
            >
              <Button
                type="primary"
                disabled={disableUserInteractions || !availableFeature}
                loading={isIntegrating || isRemoving}
                onClick={() => {
                  if (!!hasIntegration) {
                    removeWorkspaceIntegration();
                  } else {
                    requestIntegration();
                  }
                }}
              >
                {hasIntegration ? 'Remove Slack' : 'Connect Slack'}
              </Button>
            </OptionalTooltipWrapper>
          </div>
        </Content>
      </Section>
    </div>
  );
};
