import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const ChevronUpSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.55838 7.31245C9.67557 7.19541 9.83442 7.12967 10.0001 7.12967C10.1657 7.12967 10.3245 7.19541 10.4417 7.31245L16.6917 13.5624C16.8021 13.6809 16.8622 13.8376 16.8594 13.9996C16.8565 14.1615 16.7909 14.316 16.6764 14.4305C16.5619 14.545 16.4074 14.6106 16.2455 14.6134C16.0836 14.6163 15.9269 14.5562 15.8084 14.4458L10.0001 8.63745L4.19172 14.4458C4.07324 14.5562 3.91653 14.6163 3.75461 14.6134C3.5927 14.6106 3.43821 14.545 3.3237 14.4305C3.20919 14.316 3.14359 14.1615 3.14074 13.9996C3.13788 13.8376 3.19798 13.6809 3.30838 13.5624L9.55838 7.31245Z"
        fill="#9EA9B1"
      />
    </svg>
  );
};

export const ChevronUp: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={ChevronUpSVG} {...props} />;
};
