import React from 'react';
import {
  FilterPanel,
  DefaultPanel,
  FilterSection,
  Panel,
  PanelLink,
} from '../../../shared/components/PanelFilters';
import { AccountFilterPanel } from '../../../shared/components/AccountFilterPanel';
import { ParticipantsTypeFilter } from '../../../shared/components/ParticipantsTypeFilter';
import { useUpcomingMeetings } from '../../../meetings/upcoming-meetings/upcoming-meetings.hook';

export const UpcomingMeetingsFilter: React.FC = () => {
  const { meetingType, meetingTypeChanged, updateAccounts, updateAccountsFiltered } =
    useUpcomingMeetings();

  const count = updateAccounts.length + (['internal', 'external'].includes(meetingType) ? 1 : 0);
  const isClearDisabled = count === 0;

  const onClear = () => {
    updateAccountsFiltered([]);
  };

  return (
    <FilterPanel count={count}>
      <DefaultPanel clear={{ disabled: isClearDisabled, onClear }}>
        <FilterSection title="Accounts">
          <PanelLink
            label="Accounts"
            panelId="accounts"
            value={`Accounts (${updateAccounts.length})`}
          />
        </FilterSection>
        <FilterSection title="Meeting types">
          <ParticipantsTypeFilter
            value={meetingType}
            onParticipantTypeChanged={meetingTypeChanged}
          />
        </FilterSection>
      </DefaultPanel>
      <Panel panelId="accounts" title="Accounts" clear={{ disabled: isClearDisabled, onClear }}>
        <AccountFilterPanel
          filter={value => value}
          onAccountsSelectionChange={updateAccountsFiltered}
          placeholder="Select account"
          selectedAccounts={updateAccounts}
        />
      </Panel>
    </FilterPanel>
  );
};
