import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { EventWithActionItems } from './types';
import { coreService } from '../../../services/core/core-service';

interface Args {
  accountId: string;
  search?: string;
  includeCompleted: boolean;
  from: Date;
  to: Date;
}

export const useAccountActionItems = (args: Args) => {
  const { data, isFetching } = useQuery({
    queryKey: [args.accountId, args.search, args.includeCompleted, args.from, args.to],
    queryFn: async () => {
      return coreService.getAccountActionItems({
        accountId: args.accountId,
        search: args.search,
        from: args.from,
        to: args.to,
        includeCompleted: String(args.includeCompleted),
      });
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 1000,
    cacheTime: 0,
  });

  return {
    events: data?.events || [],
    isFetching,
  };
};
