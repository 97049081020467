import { date } from 'yup';
import {
  AccountsFilterStore,
  DateRangeFilterStore,
  KeyToFilterStore,
  ListFilterStore,
  NumberRangeFilterStore,
  SelectionFilterStore,
} from '../../features/shared/dashboard-filters/types';
import { Filter } from '../../services/types';

export const timelineToObj = (
  timelineSeries: { name: string; data: { x: string; y: number }[] }[],
) => {
  const seriesObj: { name: string; date: string; value: number }[] = [];

  timelineSeries.forEach(v => {
    v.data.forEach(vd => {
      seriesObj.push({
        name: v.name,
        date: vd.x,
        value: vd.y,
      });
    });
  });

  return seriesObj;
};

// export const storeToFilters = (f?: Partial<KeyToFilterStore>): Filter => {
//   const filter = {} as Filter;

//   if (!f) return filter;

//   const geo = f.geography as ListFilterStore | null;
//   const life = f.lifecycleStage as ListFilterStore | null;
//   const ind = f.industry as ListFilterStore | null;
//   const del = f.dealStage as ListFilterStore | null;
//   const rev = f.revenueSegment as ListFilterStore | null;
//   const acc = f.account as AccountsFilterStore | null;
//   const ren = f.daysUntilRenewal as SelectionFilterStore | null;
//   const arr = f.arr as NumberRangeFilterStore | null;
//   const dat = f.dateRange as DateRangeFilterStore | null;

//   if (geo?.value.options) filter.geography = geo.value.options;
//   if (life?.value.options) filter.lifecycleStage = life.value.options;
//   if (ind?.value.options) filter.industry = ind.value.options;
//   if (del?.value.options) filter.dealStage = del.value.options;
//   if (rev?.value.options) filter.revenueSegment = rev.value.options;
//   if (acc?.value.selected) filter.accountIds = acc.value.selected;
//   if (ren?.value.selected) filter.renewalIn = ren.value.selected;
//   if (dat?.value.interval[0]) filter.dateRange = dat.value.interval;
//   if (arr?.value.interval[0]) filter.contractValueMin = arr.value.interval[0];
//   if (arr?.value.interval[1]) filter.contractValueMax = arr.value.interval[1];

//   return filter;
// };

export const filtersToQueryObject = (f: KeyToFilterStore) => {
  const geo = f.geography as ListFilterStore;
  const life = f.lifecycleStage as ListFilterStore;
  const ind = f.industry as ListFilterStore;
  const del = f.dealStage as ListFilterStore;
  const rev = f.revenueSegment as ListFilterStore;
  const acc = f.account as AccountsFilterStore;
  const ren = f.daysUntilRenewal as SelectionFilterStore;
  const arr = f.arr as NumberRangeFilterStore;
  const insightType = f.insightType as SelectionFilterStore;

  return {
    geography: geo.value.options.filter(v => v.selected).map(v => v.id),
    lifecycleStage: life.value.options.filter(v => v.selected).map(v => v.id),
    industry: ind.value.options.filter(v => v.selected).map(v => v.id),
    dealStage: del.value.options.filter(v => v.selected).map(v => v.id),
    accountIds: acc.value.selected.map(v => v.id),
    revenueSegment: rev.value.options.filter(v => v.selected).map(v => v.id),
    renewalIn:
      ren.value.selected && typeof ren.value.selected === 'number' && ren.value.selected > 0
        ? ren.value.selected
        : undefined,
    contractValueMin: arr.value.interval[0] || undefined,
    contractValueMax: arr.value.interval[1] || undefined,
    insightType:
      typeof insightType?.value.selected === 'string' ? insightType.value.selected : undefined,
  };
};
