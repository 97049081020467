import React from 'react';
import Column from './Column';
import Styles from '../styles';
import UserItem from './UserItem';
import { RequestUpgrade } from '../../../../../../../features/shared/components/Icons/RequestUpgrade';
import { useOrganizationMembers } from '../../../../../../../features/organizations/hooks/useOrganizationMembers';

const { AdminsContainer, Label, AdminsList } = Styles;

const RequestUpgradeColumn: React.FC = () => {
  const { admins } = useOrganizationMembers();

  const adminEmails = admins.map(admin => {
    return {
      email: admin.user.email,
      fullName: `${admin.user.firstName} ${admin.user.lastName || ''}`,
    };
  });

  return (
    <Column
      icon={<RequestUpgrade />}
      title="Request upgrade"
      subtitle="Account upgrades within your workspace can only be performed by owners or admins. Kindly contact any of the members who hold these roles."
    >
      <AdminsContainer>
        <Label>Owners and Admins of your Workspace</Label>
        <AdminsList>
          {adminEmails.map(admin => {
            return <UserItem key={admin.email} email={admin.email} fullName={admin.fullName} />;
          })}
        </AdminsList>
      </AdminsContainer>
    </Column>
  );
};

export default RequestUpgradeColumn;
