import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const MicrophoneSVG = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0002 3.83337V8.50004C10.0002 9.60461 9.10473 10.5 8.00016 10.5C6.89559 10.5 6.00016 9.60461 6.00016 8.50004V3.83337C6.00016 2.7288 6.89559 1.83337 8.00016 1.83337C9.10473 1.83337 10.0002 2.7288 10.0002 3.83337Z"
        fill="#D1D1D1"
      />
      <path
        d="M12.6668 7.16671V8.50004C12.6668 11.0774 10.5775 13.1667 8.00016 13.1667M3.3335 7.16671V8.50004C3.3335 11.0774 5.42283 13.1667 8.00016 13.1667M8.00016 13.1667V15.1667M5.3335 15.1667H10.6668M8.00016 10.5C6.89559 10.5 6.00016 9.60461 6.00016 8.50004V3.83337C6.00016 2.7288 6.89559 1.83337 8.00016 1.83337C9.10473 1.83337 10.0002 2.7288 10.0002 3.83337V8.50004C10.0002 9.60461 9.10473 10.5 8.00016 10.5Z"
        stroke="#D1D1D1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const Microphone: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={MicrophoneSVG} {...props} />;
};
