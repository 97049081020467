import Styles from './styles';
import { Button, Flex } from 'antd';
import { CopyFilled, ReloadOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useState } from 'react';
import { MeddiccCard } from './Card';
import { useQuery } from '@tanstack/react-query';
import { OptionalTooltipWrapper } from '../../../componentsV2/OptionalTooltipWrapper';
import { SubscribeToProPlanLink } from '../../../componentsV2/UpgradeToPro';
import { useToast } from '../../../hooks/useToast';
import { capitalize } from '../../../helpers/TextHelper';
import { UpdateLoader } from '../../../componentsV2/UpdateLoader';
import Card from '../../../componentsV2/Card';

const meddiccOrder = [
  'metrics',
  'economic_buyer',
  'decision_criteria',
  'decision_process',
  'identify_pain',
  'champion',
  'competition',
];

type Section = {
  id: string;
  value: string;
};

interface Props {
  isFeatureAvailable: boolean;
  hasPermissions: boolean;
  fetchFunction: () => Promise<{ isProcessing: boolean; sections: Section[] }>;
  processFunction: () => unknown;
  updateFunction: (section: Section) => unknown;
}

export const MeddiccForm: React.FC<Props> = ({
  processFunction,
  fetchFunction,
  updateFunction,
  isFeatureAvailable,
  hasPermissions,
}) => {
  const { Container, Header, Buttons, EmptyView } = Styles;
  const { success, error } = useToast();
  const [isFetching, setIsFetching] = useState(true);
  const [reportSections, setReportSections] = useState<Section[]>([]);
  const [loading, setLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    fetchFunction()
      .then(results => {
        setReportSections(results.sections);
        setLoading(results.isProcessing);
        setRefreshData(results.isProcessing);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [fetchFunction]);

  const handleRegenerate = async () => {
    setLoading(true);
    try {
      await processFunction();
      setRefreshData(true);
    } catch (e) {
      console.error(e);
      error('Error generating MEDDICC notes. Please try again.');
      setLoading(false);
      setRefreshData(false);
    }
  };

  const handleCopyAll = () => {
    const text = sectionsToDisplay
      .map(section => `${capitalize(section.id.replace('_', ' '))}:\n${section.value}`)
      .join('\n\n');
    navigator.clipboard.writeText(text || '');
    success('Copied to clipboard');
  };

  const { data: refreshedData, isStale } = useQuery({
    queryKey: ['meddicc', refreshData, fetchFunction],
    queryFn: async () => {
      if (refreshData) {
        const data = await fetchFunction();
        return data;
      }
      return null;
    },
    refetchInterval: 15000,
    staleTime: 1000,
  });

  useEffect(() => {
    if (refreshedData && !refreshedData.isProcessing && !isStale && refreshData) {
      setReportSections(refreshedData.sections);
      setLoading(false);
      setRefreshData(false);
    }
  }, [refreshedData, isStale]);

  const sectionsToDisplay = useMemo(() => {
    return (
      reportSections.sort((a, b) => meddiccOrder.indexOf(a.id) - meddiccOrder.indexOf(b.id)) || []
    );
  }, [reportSections]);

  const meddicContents = useMemo(() => {
    if (isFetching) {
      return <UpdateLoader min />;
    }

    if (loading && reportSections.length === 0) {
      return (
        <EmptyView>
          Your MEDDICC notes are being generated. Come back anytime to this tab to check the
          progress.
        </EmptyView>
      );
    }

    if (sectionsToDisplay.length) {
      return sectionsToDisplay.map(section => (
        <MeddiccCard
          key={section.id}
          id={section.id}
          title={section.id}
          item={section.value}
          disabled={loading || !isFeatureAvailable}
          onSave={async val => {
            await updateFunction(val);
            const sections = reportSections.map(s =>
              s.id === val.id ? { ...s, value: val.value } : s,
            );
            setReportSections(sections);
          }}
        />
      ));
    }

    return (
      <EmptyView
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}
      >
        <span>
          {' '}
          Click the “Generate” button to automatically create MEDDICC notes from this meeting.
        </span>
        <Button onClick={handleRegenerate} type="primary">
          Generate
        </Button>
      </EmptyView>
    );
  }, [isFetching, loading, sectionsToDisplay, handleRegenerate, updateFunction]);

  return (
    <Card
      title="MEDDICC Report"
      extra={
        <Buttons>
          <Button
            icon={<CopyFilled />}
            onClick={() => handleCopyAll()}
            style={{ borderRadius: '8px' }}
            disabled={!!!reportSections.length || !isFeatureAvailable}
          >
            Copy All
          </Button>

          {(reportSections.length || loading) && (
            <OptionalTooltipWrapper
              display={!isFeatureAvailable}
              value={<SubscribeToProPlanLink />}
            >
              <Button
                type="default"
                onClick={handleRegenerate}
                icon={<ReloadOutlined />}
                disabled={loading || !isFeatureAvailable || !hasPermissions}
                loading={loading}
              >
                Regenerate
              </Button>
            </OptionalTooltipWrapper>
          )}
        </Buttons>
      }
    >
      <Flex vertical gap={24}>
        {meddicContents}
      </Flex>
    </Card>
  );
};
