import React, { Dispatch } from 'react';
import { GenerativeAIItem } from '../../../../redux/typings/recap';

interface IChunkNavigationContext {
  messageIdPlaying: string;
  setMessageIdPlaying: (messageId: string) => unknown;
  itemData: {
    color: string;
    highlightColor: string;
    item: GenerativeAIItem;
    index: number;
    category: string;
  } | null;
  setItemData: Dispatch<
    React.SetStateAction<{
      color: string;
      highlightColor: string;
      item: GenerativeAIItem;
      index: number;
      category: string;
    } | null>
  > | null;
}

export const ChunkNavigationContext = React.createContext<IChunkNavigationContext>({
  itemData: null,
  setItemData: () => null,
  messageIdPlaying: '',
  setMessageIdPlaying: () => console.log('setMessageIdPlaying called'),
});
