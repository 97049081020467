import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, InputNumber, Space } from 'antd';
import React, { useState } from 'react';
import Style from './styles';

interface Props {
  label: string;
  range: [number, number];
  onChange: (value: [number, number]) => void;
}

const { Container, Row } = Style;

export const InputNumberRange: React.FC<Props> = ({ label, range, onChange }) => {
  const [value, setValue] = useState(range);

  const handleClick = () => {
    onChange(value);
  };

  return (
    <Dropdown
      placement="bottomRight"
      dropdownRender={() => (
        <Container>
          <Row>
            <div style={{ width: '150px' }}>Min</div>
            <div style={{ width: '150px' }}>Max</div>
          </Row>

          <Row>
            <InputNumber
              min={0}
              defaultValue={Math.min(value[0], value[1])}
              prefix="$"
              step={1}
              style={{ width: '150px' }}
              onChange={v => setValue([v || 0, value[1]])}
            />
            <InputNumber
              min={0}
              defaultValue={Math.max(value[0], value[1])}
              prefix="$"
              step={1}
              style={{ width: '150px' }}
              onChange={v => setValue([value[1], v || 0])}
            />
            <Button type="primary" onClick={handleClick}>
              {'>'}
            </Button>
          </Row>
        </Container>
      )}
    >
      <Button>
        <Space>
          <span>{label}</span>
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};
