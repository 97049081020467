import {
  AccountFilterConfig,
  AccountsFilterStore,
  DateRangeFilterConfig,
  DateRangeFilterStore,
  KeyToFilterConfig,
  KeyToFilterStore,
  ListFilterConfig,
  ListFilterStore,
  NumberFilterConfig,
  NumberFilterStore,
  NumberRangeFilterConfig,
  NumberRangeFilterStore,
  SelectionFilterConfig,
  SelectionFilterStore,
} from './types';

export const listConfigToType = (typeConfig: ListFilterConfig): ListFilterStore => {
  const allowsNoValueSet = !!typeConfig.config.allowNoValueSet;

  let allOptions = typeConfig.config.options;

  if (allowsNoValueSet && !allOptions.find(option => option.id === '(No value set)')) {
    allOptions = [{ id: '(No value set)', label: '(No value set)', selected: true }, ...allOptions];
  }

  return {
    type: 'list',
    value: {
      options: allOptions,
    },
    componentProps: typeConfig.componentProps,
  };
};

export const dateRangeConfigToType = (typeConfig: DateRangeFilterConfig): DateRangeFilterStore => {
  return {
    type: 'dateRange',
    value: {
      value: typeConfig.config.value,
      interval: typeConfig.config.interval,
    },
    componentProps: typeConfig.componentProps,
  };
};

export const numberConfigToType = (typeConfig: NumberFilterConfig): NumberFilterStore => {
  return {
    type: 'number',
    value: {
      value: typeConfig.config.value,
    },
    componentProps: typeConfig.componentProps,
  };
};

export const selectConfigToType = (typeConfig: SelectionFilterConfig): SelectionFilterStore => {
  return {
    type: 'select',
    value: {
      defaultValue: typeConfig.config.defaultValue,
      options: typeConfig.config.options,
      selected: typeConfig.config.selected,
    },
    componentProps: typeConfig.componentProps,
  };
};

export const accountConfigToType = (typeConfig: AccountFilterConfig): AccountsFilterStore => {
  return {
    type: 'account',
    value: {
      selected: typeConfig.config.selected || [],
    },
    componentProps: typeConfig.componentProps,
  };
};

export const numberRangeConfigToType = (
  typeConfig: NumberRangeFilterConfig,
): NumberRangeFilterStore => {
  return {
    type: 'numberRange',
    value: {
      interval: typeConfig.config.interval || [0, 0],
    },
    componentProps: typeConfig.componentProps,
  };
};

export const filtersConfigToStore = (filtersArgs: KeyToFilterConfig) => {
  const filters: KeyToFilterStore = Object.keys(filtersArgs).reduce<KeyToFilterStore>(
    (previousValue, currentValue) => {
      const currentFilter = filtersArgs[currentValue];
      if (currentFilter) {
        switch (currentFilter.type) {
          case 'list':
            previousValue[currentValue] = listConfigToType(currentFilter);
            break;
          case 'dateRange':
            previousValue[currentValue] = dateRangeConfigToType(currentFilter);
            break;
          case 'number':
            previousValue[currentValue] = numberConfigToType(currentFilter);
            break;
          case 'select':
            previousValue[currentValue] = selectConfigToType(currentFilter);
            break;
          case 'account':
            previousValue[currentValue] = accountConfigToType(currentFilter);
            break;
          case 'numberRange':
            previousValue[currentValue] = numberRangeConfigToType(currentFilter);
            break;
        }
      }
      return previousValue;
    },
    {},
  );
  return filters;
};
