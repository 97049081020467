import styled from 'styled-components';

const MeetingTitleCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const MeetingTitle = styled.span`
  overflow: hidden;
  color: #0a1e2c;
  text-overflow: ellipsis;
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const MeetingDate = styled.span`
  color: #566976;
  font-family: 'Cera Pro';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 120% */
`;

export default {
  MeetingTitleCell,
  MeetingTitle,
  MeetingDate,
};
