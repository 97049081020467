import React from 'react';
import Styled from './style';
import { Modal } from '../../../../../../../../componentsV2/Modal';

const { Content, Description } = Styled;

export interface Props {
  isLoading: boolean;
  onConfirm: () => unknown;
  onCancel: () => unknown;
  onClose: () => unknown;
  isOpen: boolean;
}

export const DisconnectCRMModal: React.FC<Props> = ({
  isLoading,
  onConfirm,
  onCancel,
  onClose,
  isOpen,
}) => {
  return (
    <Modal
      title="Disconnect Primary Profile"
      width={602}
      primaryAction={{
        label: 'Disconnect Primary Profile',
        loading: isLoading,
        onClick: onConfirm,
      }}
      secondaryAction={{ label: 'Cancel', onClick: onCancel }}
      onClose={onClose}
      open={isOpen}
      helpArticle={{
        label: 'More about CRM & Primary Profiles',
        url: 'https://help.update.ai/en/articles/9056845-how-do-i-make-sure-every-meeting-in-my-workspace-is-pushed-to-our-crm',
      }}
    >
      <Content>
        <Description>
          You are about to disconnect your primary CRM profile. This will prevent any future meeting
          recaps from being mapped to your CRM.
        </Description>
      </Content>
    </Modal>
  );
};
