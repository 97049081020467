import Styles from './styles';
import { Button } from 'antd';
import { CopyFilled, ReloadOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { coreService } from '../../../../services/core/core-service';
import { MeddiccCard } from './Card';
import { useToast } from '../../../../hooks/useToast';
import { capitalize } from '../../../../helpers/TextHelper';
import { useRecapManagement } from '../../../recapv2/recap-management/hook';
import { useQuery } from '@tanstack/react-query';
import useFeatures from '../../../../hooks/useFeatures';
import { OptionalTooltipWrapper } from '../../../../componentsV2/OptionalTooltipWrapper';
import { SubscribeToProPlanLink } from '../../../../componentsV2/UpgradeToPro';

const meddiccOrder = [
  'metrics',
  'economic_buyer',
  'decision_criteria',
  'decision_process',
  'identify_pain',
  'champion',
  'competition',
];

export const Meddicc: React.FC = () => {
  const { Container, Header, Buttons, EmptyView } = Styles;
  const { success, error } = useToast();
  const { reports, id, permissions, requestReport } = useRecapManagement();
  const [data, setData] = useState(reports.find(report => report.id === 'meddicc'));
  const [loading, setLoading] = useState(data?.isProcessing || false);
  const [refreshData, setRefreshData] = useState(data?.isProcessing || false);

  const feature = useFeatures();
  const availableFeature = feature.meddicc;

  const handleRegenerate = async () => {
    setLoading(true);
    try {
      await requestReport('meddicc');
      setRefreshData(true);
    } catch (e) {
      console.error(e);
      error('Error generating MEDDICC notes. Please try again.');
      setLoading(false);
      setRefreshData(false);
    }
  };

  useEffect(() => {
    const meddicc = reports.find(report => report.id === 'meddicc');
    if (meddicc) {
      const isProcessing = meddicc.isProcessing;
      setData(meddicc);
      setLoading(isProcessing);
      setRefreshData(isProcessing);
    }
  }, [reports]);

  const handleCopyAll = () => {
    const text = data?.sections
      .map(section => `${capitalize(section.id.replace('_', ' '))}:\n${section.value}`)
      .join('\n\n');
    navigator.clipboard.writeText(text || '');
    success('Copied to clipboard');
  };

  const { data: refreshedData, isStale } = useQuery({
    queryKey: [id, 'meddicc', refreshData],
    queryFn: async () => {
      if (refreshData) {
        const data = await coreService.getEventReport(id, 'meddicc');
        if (data && data.report) {
          return {
            ...data.report,
            sections: data.report.sections.map(s => ({ ...s, modifier: 'Not found' })),
          };
        }
      }
      return null;
    },
    refetchInterval: 15000,
    staleTime: 1000,
  });

  const sections =
    (data ? [...data.sections] : []).sort(
      (a, b) => meddiccOrder.indexOf(a.id) - meddiccOrder.indexOf(b.id),
    ) || [];

  useEffect(() => {
    if (refreshedData && !refreshedData.isProcessing && !isStale && refreshData) {
      setData(refreshedData);
      setLoading(false);
      setRefreshData(false);
    }
  }, [refreshedData, isStale]);

  return (
    <Container>
      <Header>
        <>MEDDICC</>

        <Buttons>
          <Button
            icon={<CopyFilled />}
            onClick={() => handleCopyAll()}
            style={{ borderRadius: '8px' }}
            disabled={!!!data || !availableFeature}
          >
            Copy All
          </Button>

          {(data || loading) && (
            <OptionalTooltipWrapper display={!availableFeature} value={<SubscribeToProPlanLink />}>
              <Button
                type="default"
                onClick={handleRegenerate}
                icon={<ReloadOutlined />}
                disabled={loading || !availableFeature || !permissions.canGenerateReports}
                loading={loading}
              >
                Regenerate
              </Button>
            </OptionalTooltipWrapper>
          )}
        </Buttons>
      </Header>

      {data && !loading ? (
        sections.map(section => (
          <MeddiccCard
            key={section.id}
            id={section.id}
            title={section.id}
            item={section.value}
            recapId={id}
            disabled={loading || !availableFeature}
            onSave={val => {
              const sections = data.sections.map(s =>
                s.id === val.id ? { ...s, value: val.value } : s,
              );
              setData({ ...data, sections });
            }}
          />
        ))
      ) : loading ? (
        <EmptyView>
          Your MEDDICC notes are being generated. Come back anytime to this tab to check the
          progress.
        </EmptyView>
      ) : (
        <EmptyView
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}
        >
          <span>
            {' '}
            Click the “Generate” button to automatically create MEDDICC notes from this meeting.
          </span>
          <Button onClick={handleRegenerate} type="primary">
            Generate
          </Button>
        </EmptyView>
      )}
    </Container>
  );
};
