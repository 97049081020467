// import { EventResponse } from '../redux/typings/home';
import { removeBullet } from '../componentsV2/Insight/utils';
import { CreateUser } from '../hooks/useSignUp';
import { Invitation, OrganizationInfo } from '../redux/typings/organizations';
import { Chunk, Event, Speaker } from '../redux/typings/recap';
import {
  Credentials,
  Organization,
  OrganizationInvitation,
  OrganizationInvite,
  OrganizationMember,
  User,
} from '../redux/typings/session';
import { ProviderIntegration } from '../redux/typings/store';
import { createAxiosInstance } from './axiosInstance';
import {
  Account,
  AccountResponse,
  FetchPastEventsArgs,
  FetchPastEventsResponse,
  FetchUpcomingEventsArgs,
  FetchUpcomingEventsResponse,
  GetOrganizationPropertiesResponse,
  ISmartClip,
  UpdateOrganizationPropertiesDTO,
  ZoomTestResults,
  searchSmartSummaryByKeyResponse,
} from './types';
import { mapMessages } from './utils';

const BASE_URL = process.env.REACT_APP_API_CORE || 'http://localhost:3001';

export interface BulletItem {
  id: string;
  text: string;
  chunks: {
    from: number;
    to: number;
    messageId: string;
    id: string;
  }[];
}

export interface CategoryDescription {
  type: string;
  items: BulletItem[];
}

export class coreAPI {
  private axiosInstance;

  constructor() {
    this.axiosInstance = createAxiosInstance(BASE_URL);
  }

  async acceptInvite(invitationId: string) {
    await this.axiosInstance.post(`/invitations/${invitationId}/accept`);
  }

  async updatePublicVisibilityConfig(
    eventId: string,
    config: {
      [k: string]: boolean;
    },
  ) {
    await this.axiosInstance.put(`/events/${eventId}/public-visibility-config`, config);
  }

  async updateOrganizationVisibilityConfig(
    eventId: string,
    config: {
      [k: string]: boolean | string;
    },
  ) {
    await this.axiosInstance.put(`/events/${eventId}/organization-visibility`, config);
  }

  async removeRecap(eventId: string) {
    await this.axiosInstance.delete(`/events/${eventId}`);
  }

  async editActionItem(eventId: string, actionItemId: string, text: string) {
    await this.axiosInstance.patch(`/events/${eventId}/action-items/${actionItemId}`, {
      text,
    });
  }

  async removeActionItemBullet(eventId: string, actionItemId: string) {
    await this.axiosInstance.delete(`/events/${eventId}/action-items/${actionItemId}`);
  }

  async editSummaryItem(eventId: string, summaryItemId: string, text: string) {
    await this.axiosInstance.patch(`/events/${eventId}/summary-items/${summaryItemId}`, {
      text,
    });
  }

  async removeSummaryItem(eventId: string, summaryItemId: string) {
    await this.axiosInstance.delete(`/events/${eventId}/summary-items/${summaryItemId}`);
  }

  async removeMoment(eventId: string, momentId: string) {
    await this.axiosInstance.delete(`/events/${eventId}/moments/${momentId}`);
  }

  async editMoment(eventId: string, momentId: string, category: string, text: string) {
    await this.axiosInstance.patch(`/events/${eventId}/moments/${momentId}`, {
      text,
      category,
    });
  }

  async addSummaryItem(eventId: string) {
    const { data } = await this.axiosInstance.post(`/events/${eventId}/summary-items`, {
      text: 'Add an item of your own',
    });
    return data;
  }

  async addMoment(eventId: string, category: string) {
    const { data } = await this.axiosInstance.post(`/events/${eventId}/moments`, {
      text: 'Add an item of your own',
      category,
    });
    return data;
  }

  async addActionItem(eventId: string) {
    const { data } = await this.axiosInstance.post(`/events/${eventId}/action-items`, {
      text: 'Add an item of your own',
    });
    return data;
  }

  async updateMeetingType(eventId: string, type: string) {
    await this.axiosInstance.put(`/events/${eventId}/type`, {
      value: type,
    });
  }

  async getEventStatus(eventId: string, secret?: string): Promise<{ status: string }> {
    const { data } = await this.axiosInstance.get(`/events/${eventId}/status`, {
      params: {
        secret,
      },
    });
    return data;
  }

  // Events.
  fetchRecap = async (eventId: string, secret: string | null) => {
    const recap = await (
      await this.axiosInstance.get(`/events/${eventId}`, {
        params: {
          secret,
        },
      })
    ).data;

    recap.messages = mapMessages(recap);

    recap.isPublic = Boolean(secret);

    const parsedSummary = recap.summary
      ? recap.summary.split('•').filter((each: string) => {
          return each.length > 0;
        })
      : [];

    const parsedSpeaker = recap.speakerAnalytics?.speakers
      ? recap.speakerAnalytics
      : { speakers: [] };

    parsedSpeaker.speakers = parsedSpeaker.speakers.sort((a: Speaker, b: Speaker) => {
      return b.talkRatio - a.talkRatio;
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    recap.summaryItems = recap.summaryItems.map((summaryItem: any) => ({
      ...summaryItem,
      text: removeBullet(summaryItem.text),
    }));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    recap.actionItems = recap.actionItems.map((actionItem: any) => ({
      ...actionItem,
      text: removeBullet(actionItem.text),
    }));

    recap.account = !recap.account || recap.account.id === 'unknown' ? null : recap.account;

    return {
      ...recap,
      summary: parsedSummary,
      speakerAnalytics: parsedSpeaker,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      messages: recap.messages.sort((a: any, b: any) => a.startedTime - b.startedTime),
    } as Event;
  };

  /*
  fetchRecaps = async (data: {
    page: number;
    search: string;
    limit: number;
    account?: string;
    domain?: string;
    showAllStatus: boolean;
    from?: string;
    to?: string;
  }): Promise<EventResponse> => {
    const response = await this.axiosInstance.get('/users/me/events/past', {
      params: {
        page: data.page,
        limit: data.limit,
        search: data.search,
        accountId: data.account,
        domain: data.domain,
        from: data.from,
        to: data.to,
        readyOnly: data.showAllStatus,
      },
    });
    return response.data;
  };

  fetchEvents = async (data: {
    page: number;
    search: string;
    limit: number;
    account?: string;
    domain?: string;
    from?: string;
    to?: string;
  }): Promise<EventResponse> => {
    const response = await this.axiosInstance.get('/users/me/events/upcoming', {
      params: {
        page: data.page,
        limit: data.limit,
        search: data.search,
        accountId: data.account,
        domain: data.domain,
        from: data.from,
        to: data.to,
      },
    });
    return response.data;
  };*/

  async fetchUpcomingEvents(data: FetchUpcomingEventsArgs): Promise<FetchUpcomingEventsResponse> {
    const response = await this.axiosInstance.get<FetchUpcomingEventsResponse>(
      '/users/me/events/upcoming',
      {
        params: {
          page: data.page,
          limit: data.limit,
          search: data.search,
          accountIds: data.accounts?.map(account => account.id).join(','),
          domain: data.domain,
          from: data.from,
          to: data.to,
          participantsType: data.meetingType,
        },
      },
    );
    return response.data;
  }

  async fetchPastEvents(data: FetchPastEventsArgs): Promise<FetchPastEventsResponse> {
    const response = await this.axiosInstance.get<FetchPastEventsResponse>(
      '/users/me/events/past',
      {
        params: {
          page: data.page,
          limit: data.limit,
          search: data.search,
          accountId: data.account,
          accountIds: data.accountIds,
          contactIds: data.contactIds,
          domain: data.domain,
          from: data.from,
          to: data.to,
          readyOnly: data.readyOnly,
          hostOnly: data.hostOnly,
          participantsType: data.participantsType,
        },
      },
    );
    return response.data;
  }

  fetchMeetingAudio = async (id: string): Promise<string> => {
    const response = await this.axiosInstance.get(`/meetings/${id}/recording/link`);
    return response.data.recordingURL;
  };

  updateEvent = async (eventData: Partial<Event>) => {
    return await this.axiosInstance.patch(`/events/${eventData.id}`, {
      ...eventData,
      id: undefined,
    });
  };

  async updateEventNote(eventId: string, note: string) {
    await this.axiosInstance.put(`/events/${eventId}/note`, { value: note });
  }

  getPublicRecapURL = async (id: string): Promise<string> => {
    const response = await this.axiosInstance.post(`/events/public-token/${id}`);
    return response.data.url;
  };

  setRecapAccount = async (
    eventId: string,
    accountId: string,
    provider: string,
    name: string,
  ): Promise<{ account: { id: string; name: string; provider: string } }> => {
    const response = await this.axiosInstance.put(`/events/${eventId}/account`, {
      providerId: accountId,
      provider,
      name,
    });
    return response.data;
  };

  removeRecapAccount = async (eventId: string): Promise<void> => {
    await this.axiosInstance.delete(`/events/${eventId}/account`);
  };

  // Action Items.
  createActionItem = async (payload: {
    eventId: string;
    messageId: string;
    actionItemId: string;
  }) => {
    return await this.axiosInstance.post(`/events/${payload.eventId}/action-items`, {
      messageId: payload.messageId,
      actionItemId: payload.actionItemId,
    });
  };

  removeActionItem = async (payload: { messageId: string; eventId: string }) => {
    return await this.axiosInstance.delete(
      `/events/${payload.eventId}/messages/${payload.messageId}/action-items`,
    );
  };

  // Key Moments.
  createKeyMoment = async (payload: { eventId: string; chunks: Chunk[]; keyMomentId: string }) => {
    const response = await this.axiosInstance.post(`/events/${payload.eventId}/key-moments`, {
      keyMomentId: payload.keyMomentId,
      chunks: payload.chunks,
    });
    return response.data;
  };

  deleteKeyMoment = async (payload: { eventId: string; keyMomentId: string }) => {
    const { eventId, keyMomentId } = payload;
    return await this.axiosInstance.delete(`/events/${eventId}/key-moments/${keyMomentId}`);
  };

  // Login & Logout.
  login = async (credentials: Credentials): Promise<{ user: User; token: string }> => {
    const response = await this.axiosInstance.post(`/users/authenticate`, credentials);
    return response.data;
  };

  logout = async (): Promise<void> => {
    return await this.axiosInstance.post(`/users/logout`);
  };

  // Password.
  recoverPassword = async (payload: { email: string; from: string }) => {
    return await this.axiosInstance.post(`/users/forgot-password`, { email: payload.email });
  };

  resetPassword = async (payload: { password: string }) => {
    return await this.axiosInstance.post(`/users/me/reset-password`, {
      password: payload.password,
    });
  };

  // Users.
  createUser = async (
    payload: CreateUser,
  ): Promise<{
    statusCode: number;
    message?: string;
    error?: string;
  }> => {
    const response = await this.axiosInstance.post(`/users/signup`, payload);
    return response.data;
  };

  fetchUser = async (): Promise<User> => {
    const response = await this.axiosInstance.get(`/users/me`);
    return response.data;
  };

  fetchUserAccounts = async (name: string): Promise<{ accounts: ProviderIntegration[] }> => {
    const response = await this.axiosInstance.get(`/users/me/accounts?name=${name}`);
    return response.data;
  };

  updateUser = async (payload: Partial<User>) => {
    const response = await this.axiosInstance.post(`/users/me/update`, payload);
    return response.data;
  };

  updatePassword = async (password: string) => {
    const response = await this.axiosInstance.put(`/users/me/password`, { password: password });
    return response.data;
  };

  // Settings.
  integrateCalendar = async (args: { provider?: string }) => {
    let url = '/users/me/integrate/calendar';

    if (args.provider) {
      url = url.concat(`?provider=${args.provider}`);
    }

    const response = await this.axiosInstance.get(url);
    return response.data.url;
  };

  integrateZoom = async () => {
    const response = await this.axiosInstance.get(`/users/me/integrate/zoom`);
    return response.data.url;
  };

  integrateSlack = async () => {
    const response = await this.axiosInstance.get(`/users/me/integrate/slack`);
    return response.data.url;
  };

  integrateSalesforce = async () => {
    const response = await this.axiosInstance.get(`/users/me/integrate/salesforce`);
    return response.data.url;
  };

  integrateHubspot = async () => {
    const response = await this.axiosInstance.get(`/users/me/integrate/hubspot`);
    return response.data.url;
  };

  removeIntegration = async (payload: { integrationId: string }) => {
    const response = await this.axiosInstance.delete(
      `/users/me/integrations/${payload.integrationId}`,
    );
    return response.data.url;
  };

  changeSkipBehavior = async (args: { eventId: string; value: boolean }) => {
    const { eventId, value } = args;
    await this.axiosInstance.put(`/events/${eventId}/skip`, { value: !value });
  };

  // Onboarding
  nextOnboardingStep = async () => {
    const response = await this.axiosInstance.put(`/users/me/onboarding/next`);
    return response.data;
  };

  // Organizations
  getOrganization = async (payload: Partial<Organization>) => {
    const response = await this.axiosInstance.get(`/organizations/${payload.id}`);
    return response.data;
  };

  createOrganization = async (payload: Partial<Organization>) => {
    const response = await this.axiosInstance.post(`/organizations`, payload);
    return response.data;
  };

  deleteOrganization = async (payload: Partial<Organization>) => {
    const response = await this.axiosInstance.delete(`/organizations/${payload.id}`);
    return response.data;
  };

  patchOrganization = async (payload: Partial<Organization>) => {
    const response = await this.axiosInstance.patch(`/organizations/${payload.id}`, {
      name: payload.name,
    });
    return response.data;
  };

  setPrimaryOrganization = async (payload: Partial<Organization>) => {
    const response = await this.axiosInstance.put(
      `/users/me/organizations/${payload.id}/primary`,
      payload,
    );
    return response.data;
  };

  acceptInvitation = async (payload: Partial<OrganizationInvitation>) => {
    const response = await this.axiosInstance.post(`/invitations/${payload.id}/accept`, payload);
    return response.data;
  };

  declineInvitation = async (payload: Partial<OrganizationInvitation>) => {
    const response = await this.axiosInstance.post(`/invitations/${payload.id}/decline`, payload);
    return response.data;
  };

  cancelInvitation = async (payload: Partial<OrganizationInvitation>) => {
    const response = await this.axiosInstance.delete(`/invitations/${payload.id}`);
    return response.data;
  };

  removeMember = async (payload: Partial<OrganizationMember>) => {
    const response = await this.axiosInstance.delete(
      `/organizations/${payload.organizationId}/users/${payload.userId}`,
    );
    return response.data;
  };

  getUserInvitations = async () => {
    const response = await this.axiosInstance.get(`/invitations`);
    return response.data;
  };

  fetchCalendars = async (): Promise<{
    calendars: {
      id: string;
      name: string;
      description: string;
      isPrimary: boolean;
    }[];
  }> => {
    const response = await this.axiosInstance.get('/users/me/calendars');
    return response.data;
  };

  putCalendar = async (id: string) => {
    const response = await this.axiosInstance.put('/users/me/calendar', {
      calendarId: id,
    });
    return response.data;
  };

  putEndToFirstUse = async () => {
    const response = await this.axiosInstance.put('/users/me/first-time-use/next');
    return response.data;
  };

  // Invitations
  fetchInvitations = async (): Promise<Invitation[]> => {
    const response = await this.axiosInstance.get('/users/me/invitations');
    return response.data.invitations;
  };

  async toggleAlowJoinByDomain(organizationId: string, value: boolean): Promise<void> {
    await this.axiosInstance.put(`/organizations/${organizationId}/allow-join-by-domain`, {
      value,
    });
  }

  async joinOrganization(organizationId: string): Promise<void> {
    await this.axiosInstance.post(`/organizations/${organizationId}/join`);
  }

  async fetchJoinableOrganizations(): Promise<Invitation[]> {
    const response = await this.axiosInstance.get('/users/me/joinable-organizations');
    return response.data.organizations;
  }

  getOrganizationInfo = async (): Promise<OrganizationInfo> => {
    const response = await this.axiosInstance.get(`/users/me/organization`);
    return response.data;
  };

  inviteUserToOrganization = async (payload: Partial<OrganizationInvite>) => {
    const response = await this.axiosInstance.post(`/invitations`, payload);
    return response.data;
  };

  inviteUsersToOrganization = async (userEmails: string[]) => {
    const requests = userEmails.map((email: string) =>
      this.axiosInstance.post('/invitations', { email: email, role: 'MEMBER' }),
    );
    try {
      const responses = await Promise.all(requests);
      return responses.map(response => response.data);
    } catch (error) {
      console.error('Failed API calls:', error);
      throw error;
    }
  };

  setUserMembershipType = async (userId: string, membershipType: string) => {
    const response = await this.axiosInstance.put(`/users/${userId}/membership/type`, {
      value: membershipType,
    });
    return response.data;
  };

  setUserMembershipRole = async (userId: string, role: string) => {
    const response = await this.axiosInstance.put(`/users/${userId}/membership/role`, {
      value: role,
    });
    return response.data;
  };

  revokeUserInvitation = async (invitationId: string) => {
    const response = await this.axiosInstance.post(`/invitations/${invitationId}/cancel`);
    return response.data;
  };

  resendUserInvitation = async (invitationId: string) => {
    const response = await this.axiosInstance.post(`/invitations/${invitationId}/resend`);
    return response.data;
  };

  deleteUserFromOrganization = async (userId: string) => {
    const response = await this.axiosInstance.delete(`/users/${userId}/membership`);
    return response.data;
  };

  // Subscriptions
  subscribe = async (organizationId: string, seats: number, plan: string) => {
    const response = await this.axiosInstance.get(
      `/subscription/checkout?priceId=${plan}&seats=${seats}&organizationId=${organizationId}`,
    );
    return response.data;
  };

  updateSubscription = async (organizationId: string, seats: number, plan: string) => {
    const response = await this.axiosInstance.put(`/subscription/update`, {
      seats: seats,
      organizationId: organizationId,
      plan: plan,
    });
    return response.data;
  };

  cancelSubscription = async (organizationId: string) => {
    const response = await this.axiosInstance.post(
      `/subscription/cancel?organizationId=${organizationId}`,
    );
    return response.data;
  };

  editPaymentMethod = async () => {
    const response = await this.axiosInstance.get('/subscription/portal');
    return response.data;
  };

  editOrganizationInfo = async (organizationId?: string, organizationName?: string) => {
    const response = await this.axiosInstance.patch(`/organizations/${organizationId}`, {
      name: organizationName,
    });
    return response.data;
  };

  refreshUserIntegration = async () => {
    const response = await this.axiosInstance.post(`users/me/integrations/sync`);
    return response.data;
  };

  refreshUserIntegrationByUserId = async (userId?: string) => {
    const response = await this.axiosInstance.post(`users/${userId}/integrations/sync`);
    return response.data;
  };

  deleteUser = async () => {
    const response = await this.axiosInstance.delete('/users/me');
    return response.data;
  };

  testZoomIntegration = async (): Promise<ZoomTestResults> => {
    const response = await this.axiosInstance.post('/users/me/zoom/test');
    return response.data;
  };

  async getAccounts(): Promise<ProviderIntegration[]> {
    const { data } = await this.axiosInstance.get<AccountResponse>(`/accounts`);
    return data.accounts;
  }

  async getAccount(
    accountId: string,
    from: Date | null,
    to: Date | null,
    hostOnly?: boolean,
  ): Promise<Account> {
    const { data } = await this.axiosInstance.get<Account>(`/accounts/${accountId}/aggregation`, {
      params: {
        from: from?.toISOString(),
        to: to?.toISOString(),
        hostOnly: hostOnly ? 'true' : 'false',
      },
    });
    return data;
  }

  async searchSmartSummaryByKey(
    accountId: string,
    keyword: string,
    userId: string,
    from?: string,
    to?: string,
  ): Promise<searchSmartSummaryByKeyResponse> {
    const { data } = await this.axiosInstance.get<searchSmartSummaryByKeyResponse>(
      `/smart-summaries`,
      {
        params: { accountId, from, to, keyword, userId },
      },
    );

    return data;
  }

  async getOrganizationCRMProperties(
    organizationId: string,
  ): Promise<GetOrganizationPropertiesResponse> {
    const { data } = await this.axiosInstance.get<GetOrganizationPropertiesResponse>(
      `/organizations/${organizationId}/properties`,
    );
    return data;
  }

  async fetchFavouritedSmartClipsByAccount(accountId: string): Promise<{ items: ISmartClip[] }> {
    const { data } = await this.axiosInstance.get(`/favourite-clips/${accountId}`);
    return data;
  }

  async fetchMeetingPrep(eventId: string): Promise<{ nextMeetingPrep: string }> {
    const { data } = await this.axiosInstance.get(`/events/${eventId}/next-meeting-prep`);
    return data;
  }

  async updateOrganizationCRMProperties(
    organizationId: string,
    newMapping: UpdateOrganizationPropertiesDTO[],
  ) {
    const { data } = await this.axiosInstance.post(`/organizations/${organizationId}/properties`, {
      newMapping: newMapping,
    });
    return data;
  }
}
