import { PropsWithChildren, useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { coreAPI } from '../../../API/core';
import { FetchUpcomingEventsArgs } from '../../../API/types';
import { createUpcomingMeetingsStore } from './upcoming-meetings.store';
import { upcomingMeetingsContext } from './upcoming-meetings.context';

export const UpcomingMeetingsProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const store = useRef(createUpcomingMeetingsStore()).current;
  const {
    searchTerm,
    page,
    meetingType,
    updateAccounts,
    fetchId,
    pageSize,
    fetchStatusChanged,
    eventsFetched,
    paginationMetadataSet,
  } = store();

  const { data, isFetching } = useQuery({
    queryKey: [searchTerm, meetingType, updateAccounts, page, fetchId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: () => {
      const searchArgs: FetchUpcomingEventsArgs = {
        page,
        limit: pageSize,
      };

      if (searchTerm) {
        searchArgs['search'] = searchTerm;
      }

      if (meetingType !== 'all') {
        searchArgs['meetingType'] = meetingType;
      }

      if (updateAccounts.length) {
        searchArgs['accounts'] = updateAccounts;
      }

      const api = new coreAPI();
      return api.fetchUpcomingEvents(searchArgs);
    },
  });

  useEffect(() => {
    fetchStatusChanged(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (data) {
      eventsFetched(data.items);
      paginationMetadataSet(data.metadata);
    }
  }, [data]);

  return (
    <upcomingMeetingsContext.Provider value={{ store }}>
      {children}
    </upcomingMeetingsContext.Provider>
  );
};
