import { Collapse } from 'antd';
import styled from 'styled-components';

const StyledColapse = styled(Collapse)`
  margin: 0;

  border-radius: 8px;
  border: 1px solid #e7eaeb;
  background: #fff;

  flex: 1 !important;

  .ant-collapse-header {
    padding: 0px !important;
    border-radius: 12px !important;
  }

  .ant-collapse-item {
    border-bottom: none !important;
  }

  .ant-collapse ant-collapse-icon-position-start ant-collapse-small {
    border: 0px !important;
    background-color: none !important;
  }

  .ant-collapse-content-active {
    border: 0px !important;
  }
`;

const InsightSectionTitle = styled.div`
  color: var(--N-900, #0d283b);
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: #eff6ff;
  padding: 2px;
  padding: 8px 12px;
  border-radius: 8px;
`;

const InsightSectionContianer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    color: gray;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
`;

export default { StyledColapse, InsightSectionContianer, InsightSectionTitle };
