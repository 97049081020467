import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Progress, Radio } from 'antd';
import { ModalContent, ModalDescription, ModalTitle, RadioContainer } from '../style';
import { Col, Container, Content, Licenses, LicensesContent, Seats, Subs, Used } from './style';
import DeleteModal from '../../Organizations/components/DeleteModal';
import Section from '../../_Misc/Section';
import ExtendTrial from '../components/ExtendTrial';
import { useToast } from '../../../../../hooks/useToast';
import { useQuery } from '../../../../../hooks/useQuery';
import { coreAPI } from '../../../../../API/core';
import { selectSubscription, selectUser } from '../../../../../redux/selectors';
import { fetchUser } from '../../../../../redux/slices/sessionSlice';
import { Subscription } from '../../../../../redux/typings/session';
import { extractError } from '../../../../../utils/api';

export const Plans = {
  annual: {
    id: 'UPDATE_ANNUAL',
    pricePerMonth: 39,
    label: 'Annual',
  },
  monthly: {
    id: 'UPDATE_MONTHLY',
    pricePerMonth: 49,
    label: 'Monthly',
  },
  default: {
    pricePerMonth: 0,
    label: 'Monthly',
  },
};

export const PlansToPrices = (plan: string) => {
  if (Plans.annual.id === plan) return Plans.annual;
  if (Plans.monthly.id === plan) return Plans.monthly;
  return Plans.default;
};

export const CurrentPlanCard = () => {
  const { success, error } = useToast();

  const subscription: Subscription | null | undefined = useSelector(selectSubscription);
  const query = useQuery();
  const user = useSelector(selectUser);

  const [possiblePlan, setPossiblePlan] = useState<string>(subscription?.plan || Plans.monthly.id);
  const [possibleSeats, setPossibleSeats] = useState<number>(subscription?.seats || 2);
  const [users, setUsers] = useState<{ role: string; type: string; user: any }[]>([]);

  const [loading, setLoading] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [onlyAdminCanUpgradeModal, setOnlyAdminCanUpgrademodal] = useState(false);

  const isMember = user?.membership.role === 'MEMBER';

  const api = new coreAPI();
  const dispatch = useDispatch();

  const getOrgInfo = () => {
    api.getOrganizationInfo().then(data => {
      setUsers(data.members);
    });
  };

  const subscribe = async () => {
    setLoading(true);
    const url: string = await api
      .subscribe(user?.membership.organization.id!, possibleSeats, possiblePlan)
      .then(response => {
        return response;
      })
      .catch(errorMessage => {
        error(extractError(errorMessage));
      })
      .finally(() => {
        setLoading(false);
      });
    if (url) window.location.replace(url);
  };

  const updateSubscription = async (seats: number, plan: string) => {
    setLoading(true);
    try {
      if (!subscription) {
        const url: string = await api
          .subscribe(user?.membership.organization.id!, seats, plan)
          .then(response => {
            success('Subscription created successfully');
            return response;
          })
          .catch(errorMessage => {
            error(extractError(errorMessage));
          });
        if (url) window.location.replace(url);
      } else {
        await api
          .updateSubscription(user?.membership.organization.id!, seats, plan)
          .then(() => {
            success('Susbscription updated successfully');
          })
          .catch(errorMessage => {
            error(extractError(errorMessage));
          });
      }
    } finally {
      setLoading(false);
      setUpgradeModal(false);
      dispatch(fetchUser());
    }
  };

  const handleOnClick = () => {
    if (!subscription) {
      if (!isMember) {
        setUpgradeModal(true);
      } else {
        setOnlyAdminCanUpgrademodal(true);
      }
    } else {
      if (!isMember) {
        setCancelModal(true);
      }
    }
  };

  useEffect(() => {
    getOrgInfo();
  }, [user]);

  useEffect(() => {
    const hasValue = query.get('upgrade');
    if (hasValue) {
      if (!isMember) setUpgradeModal(true);
      else setOnlyAdminCanUpgrademodal(true);
    }
  }, [query]);

  return (
    <>
      <Container>
        <Section>
          <Content>
            <Col>
              <h1>{subscription ? 'Profesional' : 'Basic'}</h1>

              <h2>
                ${PlansToPrices(subscription?.plan).pricePerMonth} / Month -{' '}
                {subscription
                  ? 'All features in Basic + Premium'
                  : 'Basic features, limited visibility'}
              </h2>

              <div style={{ display: 'flex', gap: '5px' }}>
                <Button
                  style={{ width: '148px' }}
                  type="primary"
                  onClick={handleOnClick}
                  disabled={
                    (subscription && user?.membership.role === 'MEMBER') || subscription?.isCanceled
                  }
                >
                  {subscription ? 'Cancel subscription' : 'Upgrade plan'}
                </Button>

                <Button
                  style={{ width: '148px' }}
                  type="default"
                  onClick={() => window.open('https://update.ai/pricing/')}
                >
                  Explore plans
                </Button>
              </div>
            </Col>

            {subscription && (
              <Col>
                <h1>Plan management</h1>

                <h2>
                  {subscription?.seats} Seats / {PlansToPrices(subscription?.plan).label}
                </h2>

                <div style={{ display: 'flex', gap: '5px' }}>
                  <Button
                    style={{ width: '170px' }}
                    type="default"
                    onClick={() => setUpgradeModal(true)}
                    disabled={
                      !!!(subscription && user?.membership.role !== 'MEMBER') ||
                      subscription.isCanceled
                    }
                  >
                    Manage plan
                  </Button>
                </div>
              </Col>
            )}
          </Content>
        </Section>

        {subscription && (
          <Section>
            <Content>
              <Col>
                <h1>Licenses in your plan</h1>

                <Progress
                  percent={
                    (users.filter(e => {
                      return e.type === 'PAID';
                    }).length /
                      subscription!.seats) *
                    100
                  }
                  status="active"
                  showInfo={false}
                  strokeColor={'#21A6FF'}
                  style={{ margin: 0, marginInlineEnd: 0 }}
                />

                <Used>
                  <Seats>
                    {
                      users.filter(e => {
                        return e.type === 'PAID';
                      }).length
                    }
                  </Seats>
                  <span>of {subscription?.seats} licenses used</span>
                </Used>

                <div style={{ display: 'flex', gap: '5px' }}>
                  <Button
                    style={{ width: '148px' }}
                    type="default"
                    onClick={() => (window.location.href = '/settings?tab=my-organization')}
                    disabled={subscription.isCanceled}
                  >
                    Manage members
                  </Button>
                </div>
              </Col>
            </Content>
          </Section>
        )}
      </Container>

      <DeleteModal
        shouldShow={cancelModal}
        closeModal={() => setCancelModal(false)}
        heading="Cancel your subscription"
        loading={loading}
        detail={
          'Are you sure you want to cancel? This will end the subscription for all workspace members beginning in the next billing cycle. \n\nYour subscription will remain in tact for the remainder of the current billing cycle.'
        }
        confirmButtonText="Yes, cancel it now"
        onConfirm={async () => {
          setLoading(true);
          await api.cancelSubscription(user?.membership.organization.id!);
          dispatch(fetchUser());
          setLoading(false);
          setCancelModal(false);
        }}
      />

      <ExtendTrial
        open={onlyAdminCanUpgradeModal}
        onClose={() => setOnlyAdminCanUpgrademodal(false)}
      />

      <Modal
        centered
        destroyOnClose
        open={upgradeModal}
        title={false}
        closable={false}
        footer={false}
        width="500px"
      >
        <ModalContent>
          <ModalTitle>Upgrade your plan</ModalTitle>

          <ModalDescription>
            You have{' '}
            {
              users.filter(e => {
                return e.type === 'PAID';
              }).length
            }{' '}
            of {subscription?.seats} licenses used, update billing cycle of all of them.
          </ModalDescription>

          <Radio.Group
            name="radiogroup"
            defaultValue={possiblePlan}
            style={{ marginTop: '36px', display: 'flex', flexDirection: 'column' }}
            onChange={e => setPossiblePlan(e.target.value)}
          >
            <RadioContainer position="up" selected={possiblePlan === Plans.monthly.id}>
              <Radio value={Plans.monthly.id}>
                <div>Monthly</div>
                <div style={{ fontStyle: 'italic' }}>Billed month-to-month</div>
              </Radio>
            </RadioContainer>

            <RadioContainer position="down" selected={possiblePlan === Plans.annual.id}>
              <Radio value={Plans.annual.id}>
                <div>Annual</div>
                <div style={{ fontStyle: 'italic' }}>Get 20% off the price of monthly</div>
              </Radio>
            </RadioContainer>
          </Radio.Group>

          <Licenses>
            Total licenses in plan
            <LicensesContent>
              <Button
                type="default"
                style={{ width: '50px', fontSize: '20px' }}
                onClick={() => {
                  setPossibleSeats(v => {
                    const used = users.filter(e => {
                      return e.type === 'PAID';
                    }).length;

                    const min = v - 1;
                    if (min < used) {
                      error('Current licenses in use exceed the amount selected.');
                      return v;
                    }

                    if (min === 0) {
                      error('Cancel subscription to remove all licenses.');
                      return v;
                    }

                    return min;
                  });
                }}
              >
                -
              </Button>

              {possibleSeats}

              <Button
                type="default"
                style={{ width: '50px', fontSize: '20px' }}
                onClick={() => {
                  setPossibleSeats(v => {
                    return v + 1;
                  });
                }}
              >
                +
              </Button>
            </LicensesContent>
          </Licenses>

          <Subs>
            {subscription && possibleSeats !== subscription?.seats ? (
              <p>
                {possibleSeats < subscription.seats
                  ? 'Licenses removed from your plan will take effect in the next billing cycle.'
                  : 'Licenses added to your plan will take effect immediately, and the charge will be prorated for the current billing cycle.'}
              </p>
            ) : (
              <></>
            )}
          </Subs>

          <div style={{ display: 'flex', gap: '100px', width: '100%' }}>
            <Button
              type="default"
              children="Cancel"
              onClick={() => {
                setPossibleSeats(subscription?.seats || 1);
                setPossiblePlan(subscription?.plan || Plans.monthly.id);
                setUpgradeModal(false);
              }}
              style={{ flex: 1, marginTop: '24px' }}
              disabled={loading}
            />

            <Button
              type="primary"
              children="Upgrade"
              loading={loading}
              onClick={() => {
                if (!subscription) {
                  subscribe();
                } else {
                  updateSubscription(possibleSeats, possiblePlan);
                }
              }}
              style={{ flex: 1, marginTop: '24px' }}
            />
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};
