import style from './style';
import { Radio, Space } from 'antd';
import { AlignLeftOutlined, ClockCircleFilled, ReloadOutlined } from '@ant-design/icons';
import { FilterFavoriteClip } from '../../../features/shared/components/Icons/FilterFavoriteClip';
import { OptionalTooltipWrapper } from '../../OptionalTooltipWrapper';

const { Container, Buttons, Button, LastGeneratedLabel } = style;

interface Props {
  defaultValue: string;
  disableGenerate: boolean;
  isGenerating?: boolean;
  lastGeneratedAt: Date | null;
  onClick: (v: string) => void;
  onGenerate: () => void;
  onFilter?: () => void;
}

export const ActionBar: React.FC<Props> = ({
  defaultValue,
  disableGenerate,
  isGenerating,
  lastGeneratedAt,
  onClick,
  onGenerate,
  onFilter,
}) => {
  const wasGenerated = Boolean(lastGeneratedAt);
  return (
    <Container>
      <Buttons>
        <Radio.Group
          defaultValue={defaultValue}
          disabled={!wasGenerated}
          size="middle"
          onChange={t => onClick(t.target.value)}
        >
          <Radio.Button value="timeline" data-testid="account-story-timeline-button">
            <Space>
              <ClockCircleFilled />
              Timeline View
            </Space>
          </Radio.Button>
          <Radio.Button value="narrative" data-testid="account-story-narrative-button">
            <Space>
              <AlignLeftOutlined />
              Narrative View
            </Space>
          </Radio.Button>
        </Radio.Group>
        {/*<LastGeneratedLabel>
          Last generated:{' '}
          {lastGeneratedAt ? format(lastGeneratedAt, 'dd-MMM hh:mm aa') : 'Not generated yet'}
  </LastGeneratedLabel>*/}
      </Buttons>
      <Space>
        <OptionalTooltipWrapper display={disableGenerate} value="Account story is being generated">
          <Button
            type="default"
            onClick={onGenerate}
            icon={<ReloadOutlined />}
            disabled={disableGenerate}
            loading={isGenerating}
          >
            Regenerate
          </Button>
        </OptionalTooltipWrapper>
        {onFilter ? (
          <Button
            size="middle"
            style={{ justifyContent: 'center', alignContent: 'center' }}
            onClick={onFilter}
            data-testid="account-story-filter-button"
            disabled={defaultValue === 'narrative'}
          >
            <FilterFavoriteClip style={{ marginTop: '2px', marginLeft: '3px' }} />
          </Button>
        ) : (
          <></>
        )}
      </Space>
    </Container>
  );
};
