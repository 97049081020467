import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { View } from './style';
import { selectSlackIntegration, selectUser } from '../../redux/selectors';
import { useIntegrations } from '../../features/shared/hooks/useIntegrations';
import { IntegrationData } from '../../redux/typings/session';
import { TitledVerticalFlexLayout } from '../../features/shared/components/VerticalFixedLayout/VerticalFixedLayout';
import { useUserSettings } from '../../features/user/hooks/useUserSettings';
import { useLocationChange } from '../../features/analytics/hooks/useLocationChange';
import Account from './Tabs/Account';
import Billing from './Tabs/Billing';
import Integrations from './Tabs/Integrations';
import Notifications from './Tabs/Notifications';
import Organizations from './Tabs/Organizations';
import Recordings from './Tabs/Recordings';
import TabV2 from '../../features/shared/components/TabV2';

const { TabItem, Tab } = TabV2;

export const Settings: React.FC = () => {
  const slackIntegration: IntegrationData = useSelector(selectSlackIntegration)!;
  const user = useSelector(selectUser);
  useUserSettings();
  useLocationChange();
  const [params] = useSearchParams();

  const shouldShowOrganizationModal = params.get('invite');
  const askedTab = params.get('tab') || 'account';

  const [selectedTab, setSelectedTab] = useState(askedTab);

  const { hasCrmIntegration } = useIntegrations();

  const items = [
    {
      key: 'account',
      label: <TabItem label="My Account" isActive={selectedTab === 'account'} />,
      children: (
        <Account
          email={user?.email}
          firstName={user?.firstName ?? ''}
          lastName={user?.lastName ?? ''}
          role={user?.membership?.role}
          disableChangePassword={user?.signUpMethod === 'GOOGLE'}
        />
      ),
    },
    {
      key: 'my-organization',
      label: <TabItem label="My Workspace" isActive={selectedTab === 'my-organization'} />,
      children: (
        <Organizations
          disableUserInteractions={user?.membership?.role === 'MEMBER'}
          showInviteModal={!!shouldShowOrganizationModal}
        />
      ),
    },
    {
      key: 'plan-and-billing',
      label: <TabItem label="Plan and Billing" isActive={selectedTab === 'plan-and-billing'} />,
      children: <Billing />,
    },
    {
      key: 'recordings-config',
      label: <TabItem label="Recording Settings" isActive={selectedTab === 'recordings-config'} />,
      children: (
        <Recordings setSelectedTab={setSelectedTab} crmIntegrationEnabled={hasCrmIntegration} />
      ),
    },
    {
      key: 'notifications',
      label: <TabItem label="Notifications" isActive={selectedTab === 'notifications'} />,
      children: (
        <Notifications
          setSelectedTab={setSelectedTab}
          slackIntegrationEnabled={!!slackIntegration.id}
        />
      ),
    },
    {
      key: 'integrations',
      label: <TabItem label="Integrations" isActive={selectedTab === 'integrations'} />,
      children: <Integrations />,
    },
  ];

  if (user?.membership.organization.isFreeForeverProgram) {
    items.splice(2, 1);
  }

  useEffect(() => {
    setSelectedTab(askedTab);
  }, [askedTab]);

  return (
    <TitledVerticalFlexLayout title="Settings">
      <View>
        <Tab
          items={items}
          activeKey={selectedTab}
          onChange={(tab: string) => setSelectedTab(tab)}
        />
      </View>
    </TitledVerticalFlexLayout>
  );
};
