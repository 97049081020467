import styled from 'styled-components';
import { Modal as AntModal } from 'antd';

const Modal = styled(AntModal)`
  width: 876px !important;
  height: 540px !important;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const Column = styled.div`
  padding: 0px 16px;
  width: 446px;
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

const ColumnHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;

const ColumnSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.div`
  color: var(--new-color-shades-gray-950, #282828);
  text-align: center;
  font-family: Cera Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 100% */
`;

const Subtitle = styled.div`
  color: var(--new-color-shades-gray-700, #6a6a6a);
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const Divider = styled.div`
  height: 492px;
  width: 1px;
  background: #e4e4e4;
`;

const Label = styled.span`
  color: var(--new-color-shades-gray-500, #a6a6a6);
  font-family: Cera Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */
`;

const AdminsContainer = styled.div`
  padding: 24px 0px;
`;

const AdminsList = styled.div`
  padding-top: 8px;
  overflow-y: auto;
  height: 224px;
`;

const UserItemContainer = styled.div`
  display: flex;
  gap: 16px;
  padding: 8px 9px;
`;

const UserDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const UserName = styled.div`
  color: var(--new-color-shades-gray-950, #282828);
  text-align: justify;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const LabelDiscrete = styled.div`
  color: var(--new-color-shades-gray-500, #a6a6a6);
  text-align: justify;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const RedeemStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0px;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  gap: 8px;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Link = styled.a`
  color: var(--new-color-shades-primary-500, #ff6c54);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
`;

export default {
  Container,
  Column,
  Divider,
  ColumnHeader,
  ColumnSubtitle,
  Modal,
  Title,
  Subtitle,
  Label,
  AdminsContainer,
  AdminsList,
  UserItemContainer,
  UserName,
  LabelDiscrete,
  UserDataContainer,
  RedeemStepsContainer,
  StepsContainer,
  Step,
  Link,
};
