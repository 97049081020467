import React, { MouseEvent } from 'react';
import Styles from './style';
import { Divider, Tooltip } from 'antd';
import { Participants as ParticipantsSVG } from '../../../../../../../features/shared/components/Icons/Participants';
import { Microphone } from '../../../../../../../features/shared/components/Icons/Microphone';
import { Lock } from '../../../../../../../features/shared/components/Icons/Lock';
import { ChevronUp } from '../../../../../../../features/shared/components/Icons/ChevronUp';
import { ChevronDown } from '../../../../../../../features/shared/components/Icons/ChevronDown';
import { flatMap } from 'lodash';
import { MeetingTitleLink } from '../../../../../../../components/common/MeetingTitleLink';

const {
  Container,
  DateAndTitleSection,
  Description,
  Host,
  HostSection,
  MeetingTypeSection,
  ParticipantsSection,
  Participants,
  Title,
  TitleFrame,
  ActionsSection,
  Type,
} = Styles;

interface Props {
  isSelected: boolean;
  title: string;
  id: string;
  description: string;
  participantsList: { email: string }[];
  host: string;
  meetingType: string;
  onSelectedChange: (selected: boolean) => unknown;
}

export const MeetingRowPanelHeader: React.FC<Props> = ({
  isSelected,
  title,
  description,
  participantsList,
  id,
  onSelectedChange,
  host,
  meetingType,
}) => {
  const divider = (
    <Divider
      type="vertical"
      style={{ height: '24px', margin: 0, position: 'absolute', left: '10px' }}
    />
  );

  const participantsTooltip = (
    <div>
      {participantsList.map(p => (
        <>
          {p.email}
          <br></br>
        </>
      ))}
    </div>
  );

  return (
    <Container isSelected={isSelected}>
      <DateAndTitleSection>
        <TitleFrame>
          <Tooltip
            title={
              <div style={{ width: '250px' }}>
                Click to go to recap: <br></br>
                {title}
              </div>
            }
          >
            <MeetingTitleLink title={title} id={id} />
          </Tooltip>
        </TitleFrame>

        <Description>{description}</Description>
      </DateAndTitleSection>

      <ParticipantsSection>
        {divider}

        <Tooltip title={participantsTooltip} style={{ wordBreak: 'break-all' }}>
          <Participants>
            <ParticipantsSVG />
            Participants: {participantsList.length}
          </Participants>
        </Tooltip>
      </ParticipantsSection>

      <HostSection>
        {divider}

        <Microphone />
        <Tooltip title={host}>
          <Host>Host: {host}</Host>
        </Tooltip>
      </HostSection>

      <MeetingTypeSection>
        {divider}

        <Type>
          <Lock />
          {meetingType}
        </Type>

        <ActionsSection style={{ position: 'absolute', right: 0 }}>
          {isSelected ? <ChevronUp /> : <ChevronDown />}
        </ActionsSection>
      </MeetingTypeSection>
    </Container>
  );
};
