import React from 'react';
import Styles from './styles';
import { Mapping, Property } from '../../../../../../../../features/crm/property-mapping/types';
import { MappingField } from './components/MappingField';

const { GroupTitle, MappingGroup } = Styles;

interface Props {
  title: string;
  mappings: Mapping[];
  properties: Property[];
  onRemoveMapping: (propertyId: string) => unknown;
  onMappingChanged: (propertyId: string, crmProperty: { id: string; label: string }) => unknown;
}

export const MappingCategory: React.FC<Props> = ({
  title,
  mappings,
  properties,
  onRemoveMapping,
  onMappingChanged,
}) => {
  return (
    <MappingGroup>
      <GroupTitle>{title}</GroupTitle>
      {mappings.map(mapping => {
        return (
          <MappingField
            key={mapping.property.id}
            onMappingChanged={crmProperty => onMappingChanged(mapping.property.id, crmProperty)}
            label={mapping.property.label}
            value={mapping.crmProperty?.id || ''}
            options={properties}
            disableUserInteractions={false}
            onRemoveMapping={() => onRemoveMapping(mapping.property.id)}
          />
        );
      })}
    </MappingGroup>
  );
};
