import React, { useState } from 'react';
import style from './style';
import { Button, ConfigProvider, Input, Space, Switch } from 'antd';
import { SearchModal } from './SearchModal';
import { selectUser } from '../../../../redux/selectors';
import { useSelector } from 'react-redux';
import useDebounce from '../../../../hooks/useDebounce';
import { useAccountOverview } from '../../../account/account-overview/account-overview.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagicWandSparkles } from '@fortawesome/free-solid-svg-icons';
import { OptionalTooltipWrapper } from '../../../../componentsV2/OptionalTooltipWrapper';

const { Search } = Input;
const { Content, FilterSpacer, Shadow, SwitchContainer } = style;

interface Props {
  summarizeDisabled?: boolean;
  onSummarize: () => unknown;
}

const AccountsPageFilterBar: React.FC<Props> = ({ summarizeDisabled = false, onSummarize }) => {
  const { selectedAccount, dateRange, setMyMeetingsOnly, myMeetingsOnly } = useAccountOverview();
  const user = useSelector(selectUser);
  const [showModal, setShowModal] = useState(false);
  const [inputSearchTerm, setInputSearchTerm] = useState('');
  const debouncedInput = useDebounce(inputSearchTerm, 600);

  return (
    <Content>
      <FilterSpacer>
        <Space>
          <Shadow>
            <Search
              placeholder="Search by keyword"
              onSearch={v => {
                setShowModal(true);
                setInputSearchTerm(v);
              }}
              onChange={e => {
                setInputSearchTerm(e.target.value);
              }}
              style={{ width: 200 }}
            />
          </Shadow>

          <SwitchContainer>
            My meetings only
            <Switch
              size="small"
              onChange={v => setMyMeetingsOnly(v)}
              defaultChecked={myMeetingsOnly}
            />
          </SwitchContainer>
        </Space>
        <OptionalTooltipWrapper
          display={false}
          value="A summary of the selected meetings with this account is being generated."
        >
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#7230FF',
              },
            }}
          >
            <Button
              disabled={summarizeDisabled || false}
              onClick={onSummarize}
              type="primary"
              loading={false}
              style={{
                width: '250px',
                gap: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              icon={
                !false ? (
                  <FontAwesomeIcon
                    icon={faMagicWandSparkles}
                    color={summarizeDisabled || false ? '#E0E6EE' : 'white'}
                  />
                ) : (
                  <></>
                )
              }
            >
              <span>Summarize Meeting History</span>
            </Button>
          </ConfigProvider>
        </OptionalTooltipWrapper>
      </FilterSpacer>

      <SearchModal
        open={showModal}
        onClose={() => setShowModal(false)}
        search={{
          accountId: selectedAccount.id,
          from: dateRange[0]?.toISOString() || undefined,
          to: dateRange[1]?.toISOString() || undefined,
          keywords: debouncedInput,
          userId: user?.id || '',
        }}
      />
    </Content>
  );
};

export default AccountsPageFilterBar;
