import React from 'react';
import style from './style';
import { format } from 'date-fns';
import ReactHtmlParser from 'react-html-parser';
import { ACCOUNT_DATE_FORMAT } from '../../../../../pages/accounts/components/Tabs';

interface Props {
  title: string;
  onClick: () => unknown;
  date: string;
  headline: string;
}

export const SearchItem: React.FC<Props> = ({ title, date, headline, onClick }) => {
  const { Container, Title, Headline } = style;

  const handleTitleClicked = () => {
    onClick();
  };

  return (
    <Container>
      <Title onClick={handleTitleClicked}>
        {format(new Date(date), ACCOUNT_DATE_FORMAT)} - {title}
      </Title>
      <Headline>
        {ReactHtmlParser(headline.replace('<b>', '<span>').replace('</b>', '</span>'))}
      </Headline>
    </Container>
  );
};
