import React, { useState } from 'react';
import Card from '../../../../componentsV2/Card';
import { Filter } from '../../../../services/types';
import { TopicType } from './TopicByType/TopicType';

import Styles from './styles';
const { Row } = Styles;

export const TopTopicsByTypeSection: React.FC<{
  loading?: boolean;
  filterOptions: Filter | null;
}> = ({ loading }) => {
  const [riskSortBy, setRiskSortBy] = useState({
    id: 'mentions',
    label: '# of Mentions',
  });
  const [feedbackSortBy, setFeedbackSortBy] = useState({
    id: 'mentions',
    label: '# of Mentions',
  });
  const [advocacySortBy, setAdvocacySortBy] = useState({
    id: 'mentions',
    label: '# of Mentions',
  });
  const [growthSortBy, setGrowthSortBy] = useState({
    id: 'mentions',
    label: '# of Mentions',
  });

  return (
    <>
      <Row>
        <Card containerStyle={{ flex: '1' }} title="Top Risks">
          <TopicType
            insightType={'Risks'}
            loading={loading}
            sortBy={riskSortBy}
            setSortBy={setRiskSortBy}
          />
        </Card>

        <Card containerStyle={{ flex: '1' }} title="Top Product Feedback">
          <TopicType
            insightType={'Product Feedback'}
            loading={loading}
            sortBy={feedbackSortBy}
            setSortBy={setFeedbackSortBy}
          />
        </Card>
      </Row>

      <Row>
        <Card containerStyle={{ flex: '1' }} title="Top Advocacy">
          <TopicType
            insightType={'Advocacy'}
            loading={loading}
            sortBy={advocacySortBy}
            setSortBy={setAdvocacySortBy}
          />
        </Card>

        <Card containerStyle={{ flex: '1' }} title="Top Growth Opportunities">
          <TopicType
            insightType={'Growth Opportunities'}
            loading={loading}
            sortBy={growthSortBy}
            setSortBy={setGrowthSortBy}
          />
        </Card>
      </Row>
    </>
  );
};
