import React, { useState } from 'react';
import Styled from './style';
import Section from '../../../_Misc/Section';
import { InsightCategoriesProvider } from '../../../../../../features/insights/context/InsightCategoriesProvider';
import { Button, Select } from 'antd';
import { ManageCategoriesModal } from '../../../../../insights/components/ManageCategoriesModal';
import { useSession } from '../../../../../../hooks/useSession';
import { TagsOutlined } from '@ant-design/icons';
import { CategoryManagementProvider } from '../../../../../../features/insights/context/CategoryManagementProvider';
import { OptionalTooltipWrapper } from '../../../../../../componentsV2/OptionalTooltipWrapper';
import useFeatures from '../../../../../../hooks/useFeatures';
import { SubscribeToTeamsPlanLink } from '../../../../../../componentsV2/UpgradeToPro';

const { Container, FormContainer } = Styled;

interface Props {
  disableUserInteractions: boolean;
}

export const CustomInsightCategorySection: React.FC<Props> = ({ disableUserInteractions }) => {
  const [type, setType] = useState('Risks');
  const [showModal, setShowModal] = useState(false);

  const features = useFeatures();
  const availableFeature = features.customInsightsCategories;

  const { user } = useSession();
  const isManageEnabled = user?.membership?.role !== 'MEMBER' && availableFeature;

  return (
    <Container>
      <CategoryManagementProvider>
        <InsightCategoriesProvider insightType={type}>
          <Section
            title="Custom Insight Categories"
            description="Customize the AI insights your workspace sees on the Portfolio Insights dashboard."
            learnMore="https://help.update.ai/en/articles/9058485-how-can-i-customize-my-ai-insights"
          >
            <FormContainer>
              <span>Type: </span>
              <Select
                disabled={!isManageEnabled}
                style={{ width: '200px' }}
                onChange={value => setType(value)}
                value={type}
                options={[
                  { label: 'Risks', value: 'Risks' },
                  { label: 'Growth Opportunities', value: 'Growth Opportunities' },
                  { label: 'Product Feedback', value: 'Product Feedback' },
                  { label: 'Advocacy', value: 'Advocacy' },
                ]}
              ></Select>
              <OptionalTooltipWrapper
                display={!isManageEnabled}
                value={
                  !availableFeature ? (
                    <SubscribeToTeamsPlanLink title="Upgrade to a Business plan or higher to create custom insight categories." />
                  ) : (
                    'Only Owners or Admins can manage custom categories'
                  )
                }
              >
                <Button
                  disabled={!isManageEnabled}
                  icon={<TagsOutlined />}
                  onClick={() => setShowModal(true)}
                >
                  Manage categories
                </Button>
              </OptionalTooltipWrapper>

              <ManageCategoriesModal
                open={showModal}
                onClose={() => setShowModal(false)}
                onDiscard={() => setShowModal(false)}
                onChangesSaved={console.log}
              />
            </FormContainer>
          </Section>
        </InsightCategoriesProvider>
      </CategoryManagementProvider>
    </Container>
  );
};
