import React, { PropsWithChildren, ReactElement } from 'react';
import Styles from '../styles';

const { Column: StyledColumn, ColumnHeader, ColumnSubtitle, Title, Subtitle } = Styles;

interface Props {
  icon: ReactElement;
  title: string;
  subtitle: string;
}

const Column: React.FC<PropsWithChildren<Props>> = ({ icon, title, subtitle, children }) => {
  return (
    <StyledColumn>
      <ColumnHeader>
        {icon}
        <ColumnSubtitle>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </ColumnSubtitle>
      </ColumnHeader>
      {children}
    </StyledColumn>
  );
};

export default Column;
