import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const LogoSalesforceSVG = () => {
  return (
    <div style={{ display: 'flex', gap: '8px' }}>
      <svg
        width="89"
        height="20"
        viewBox="0 0 89 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3143_23065)">
          <path
            d="M51.3535 0C51.7956 0 52.2376 0.0236811 52.6665 0.14998C53.0586 0.265754 53.0796 0.294698 52.9665 0.665702C52.9296 0.786739 52.8902 0.905144 52.8402 1.02355C52.7086 1.3314 52.7086 1.74188 52.4429 1.92869C52.1876 2.10762 51.8429 1.8787 51.5351 1.84976C50.5721 1.75766 49.8537 2.2076 49.5301 3.1259C49.4038 3.48375 49.3117 3.84949 49.2617 4.22839C49.2301 4.4731 49.3117 4.57834 49.5617 4.57571C50.0879 4.57045 50.6142 4.56782 51.1404 4.57571C51.5667 4.58361 51.5982 4.63097 51.5456 5.06512C51.4369 5.94395 50.9308 6.38425 50.0274 6.386C49.7958 6.386 49.5643 6.38074 49.3327 6.386C48.8696 6.39653 48.8696 6.39916 48.7854 6.87541C48.2829 9.71188 47.7856 12.5483 47.2751 15.3822C47.1251 16.211 46.9041 17.0214 46.5515 17.7924C45.8858 19.2396 44.7623 19.9711 43.1704 20C42.6205 20.0105 42.0758 19.9868 41.5496 19.8053C41.1838 19.679 41.1654 19.6369 41.2838 19.2527C41.3154 19.1527 41.3522 19.0528 41.3891 18.9554C41.5022 18.6607 41.5601 18.3186 41.7495 18.0871C41.9995 17.7845 42.331 18.1213 42.631 18.1318C43.7651 18.1766 44.2387 17.8976 44.6676 16.8346C45.0491 15.89 45.1728 14.8823 45.3543 13.8903C45.7674 11.6353 46.1621 9.37771 46.5647 7.12275C46.5857 6.99908 46.6094 6.87541 46.6357 6.75174C46.6936 6.47283 46.5726 6.36758 46.2989 6.37811C46.0042 6.39126 45.7095 6.38863 45.4148 6.37811C44.9912 6.36232 44.957 6.31496 45.0202 5.88081C45.0228 5.85976 45.0254 5.83871 45.0307 5.81766C45.1491 5.43613 45.0386 4.94409 45.3227 4.67833C45.6174 4.40205 46.0884 4.59413 46.4831 4.57308C46.9778 4.54677 46.9909 4.56256 47.0857 4.07315C47.1883 3.53375 47.3199 3.00487 47.5225 2.49441C48.1987 0.778845 49.3959 0 51.3535 0Z"
            fill="#717982"
          />
          <path
            d="M4.13606 4.32576C5.38326 4.31787 6.4568 4.57573 7.4593 5.1125C7.87504 5.33352 7.88556 5.36773 7.71453 5.79925C7.56982 6.16762 7.55666 6.67282 7.23828 6.87016C6.90938 7.07277 6.56205 6.66493 6.2121 6.54915C5.56745 6.33602 4.91227 6.1992 4.24394 6.12815C3.86241 6.08605 3.48614 6.14131 3.13356 6.29918C2.7415 6.47285 2.48101 6.75965 2.43365 7.19907C2.38366 7.6569 2.63362 7.95686 2.9941 8.18052C3.40984 8.43575 3.8703 8.58836 4.33077 8.73834C4.97016 8.9462 5.62007 9.13302 6.22789 9.42509C7.06725 9.82504 7.79873 10.3513 8.03291 11.3248C8.32761 12.5536 7.94082 14.0534 6.61994 14.8139C6.00423 15.1691 5.3359 15.3322 4.63336 15.3717C3.10461 15.4585 1.66007 15.177 0.347081 14.356C-0.0502347 14.1087 -0.0554972 14.085 0.0892206 13.6456C0.181314 13.3667 0.281301 13.0904 0.389181 12.8141C0.562843 12.3721 0.597049 12.351 1.01541 12.6194C2.05212 13.2877 3.17303 13.6482 4.41497 13.593C4.73598 13.5798 5.0412 13.5272 5.32537 13.3693C6.16211 12.8983 6.19105 11.8879 5.37537 11.3933C5.16224 11.2643 4.92543 11.1643 4.68598 11.0828C4.22815 10.9249 3.76505 10.7828 3.30459 10.6433C2.84149 10.5013 2.38102 10.346 1.9495 10.1276C0.94174 9.61191 0.294457 8.84885 0.281301 7.6569C0.268144 6.40706 0.797022 5.46508 1.87583 4.84674C2.60731 4.42838 3.40984 4.28892 4.13869 4.32576H4.13606Z"
            fill="#717982"
          />
          <path
            d="M39.308 4.3231C40.5867 4.3231 41.6629 4.56781 42.6707 5.09405C43.1101 5.32297 43.1127 5.35718 42.9443 5.83343C42.8601 6.07024 42.7733 6.30968 42.6812 6.54386C42.4996 7.00433 42.4786 7.02538 42.0497 6.81488C41.3024 6.44914 40.5104 6.25969 39.6947 6.14655C39.2711 6.08866 38.8501 6.10445 38.4502 6.26232C38.0371 6.42546 37.7292 6.69647 37.6713 7.17009C37.6108 7.65687 37.8739 7.96473 38.2607 8.19628C38.7896 8.51202 39.379 8.68042 39.9579 8.86724C40.5999 9.07511 41.2261 9.30666 41.8287 9.61451C44.1468 10.7959 43.5469 13.8061 41.9865 14.7349C41.3366 15.1217 40.6446 15.3243 39.8973 15.369C38.3923 15.4558 36.9662 15.198 35.6611 14.4086C35.1611 14.106 35.1638 14.1034 35.3506 13.5587C35.4111 13.3798 35.4742 13.2009 35.5374 13.022C35.7847 12.3457 35.7874 12.3431 36.3873 12.7088C36.9846 13.0746 37.6187 13.3482 38.3055 13.4929C38.8449 13.6061 39.3895 13.6245 39.9368 13.5693C40.3499 13.5272 40.7078 13.3561 40.9656 13.022C41.3656 12.4983 41.2709 11.8668 40.7393 11.4774C40.5341 11.3274 40.3052 11.2169 40.0657 11.138C39.5658 10.9722 39.0685 10.8065 38.5659 10.6538C38.1028 10.5118 37.645 10.3539 37.2109 10.1381C36.2189 9.64872 35.5611 8.9146 35.519 7.7516C35.4716 6.48334 35.9847 5.50716 37.0846 4.86513C37.8134 4.43888 38.6159 4.29416 39.308 4.32573V4.3231Z"
            fill="#717982"
          />
          <path
            d="M75.3217 15.3348C74.8797 15.3269 74.4429 15.3085 74.0035 15.227C72.0037 14.8665 70.7986 13.6535 70.2803 11.7353C69.9566 10.5407 69.9593 9.32247 70.254 8.11474C70.7907 5.93081 72.4431 4.51784 74.6876 4.3547C75.7427 4.2784 76.7899 4.36786 77.803 4.6994C78.2266 4.83885 78.2292 4.85201 78.0898 5.28353C77.9977 5.56244 77.9003 5.84135 77.7951 6.115C77.6319 6.53336 77.5977 6.55705 77.1346 6.43864C76.4979 6.2755 75.8532 6.21499 75.1981 6.22814C74.0008 6.25445 73.1404 6.81228 72.6984 7.92002C72.1984 9.17775 72.1879 10.4776 72.6642 11.7406C73.0668 12.8141 73.9377 13.3298 75.0481 13.4219C75.8032 13.4851 76.5584 13.4561 77.2899 13.1983C77.6977 13.0536 77.7082 13.0746 77.8766 13.5009C77.9924 13.7929 78.1029 14.0903 78.2029 14.3876C78.3266 14.7612 78.3161 14.7981 77.924 14.927C77.0794 15.2112 76.2137 15.3559 75.3217 15.3322V15.3348Z"
            fill="#717982"
          />
          <path
            d="M22.5314 7.61479C22.5314 9.84608 22.5314 12.0747 22.5314 14.306C22.5314 14.4534 22.5261 14.6007 22.5314 14.7481C22.5366 14.9717 22.4551 15.1112 22.2104 15.1112C21.7052 15.1112 21.2 15.1112 20.6948 15.1112C20.4606 15.1112 20.3475 14.998 20.3527 14.7639C20.3527 14.6376 20.3527 14.5113 20.3527 14.385C20.3527 9.90396 20.3527 5.42034 20.3527 0.939354C20.3527 0.876205 20.3527 0.813055 20.3527 0.749906C20.3659 0.20524 20.3738 0.194716 20.9316 0.186822C21.3315 0.181559 21.7315 0.192084 22.1314 0.186822C22.4288 0.181559 22.5551 0.315752 22.5366 0.607819C22.5261 0.755168 22.5366 0.902517 22.5366 1.04987C22.5366 3.23905 22.5366 5.42561 22.5366 7.61479H22.5314Z"
            fill="#717982"
          />
          <path
            d="M65.6097 5.40192C65.7859 5.4256 65.8649 5.27562 65.9754 5.19142C67.0147 4.39153 68.1777 4.34154 69.3934 4.61518C69.646 4.67307 69.746 4.80463 69.6407 5.07039C69.4934 5.44139 69.3644 5.81766 69.2381 6.19656C69.1592 6.43863 69.0066 6.52546 68.7592 6.49125C68.5935 6.46757 68.4251 6.45968 68.2567 6.45705C66.8358 6.43337 65.9491 7.15959 65.7228 8.5594C65.6491 9.01461 65.5991 9.47507 65.6018 9.93817C65.6097 11.4722 65.607 13.0062 65.6018 14.5428C65.6018 15.1033 65.5912 15.1085 65.0413 15.1164C64.6835 15.1217 64.3256 15.1243 63.9704 15.1164C63.4363 15.1059 63.4363 15.1006 63.4205 14.5928C63.4205 14.5086 63.4205 14.4244 63.4205 14.3402C63.4205 11.3564 63.4205 8.36995 63.4205 5.38614C63.4205 5.23879 63.4257 5.09144 63.4205 4.94409C63.4099 4.67044 63.552 4.57308 63.8099 4.57572C64.2519 4.58098 64.694 4.58624 65.1334 4.57572C65.4202 4.56782 65.5676 4.67307 65.5597 4.97303C65.557 5.11512 65.5255 5.2651 65.6097 5.39929V5.40192Z"
            fill="#717982"
          />
          <path
            d="M18.1159 10.9643H18.0975C18.0975 9.87237 18.1369 8.77515 18.0896 7.68582C18.008 5.89658 16.9766 4.76252 15.2136 4.4573C13.6428 4.18628 12.1167 4.3731 10.6353 4.94407C10.459 5.01249 10.2932 5.10458 10.1301 5.19667C9.96695 5.2914 9.89854 5.42296 9.97485 5.6203C10.1143 5.97289 10.2459 6.32547 10.3695 6.68332C10.4616 6.94907 10.6142 6.98328 10.851 6.85698C11.2221 6.65701 11.6194 6.51755 12.0298 6.42283C12.9587 6.2097 13.8928 6.09129 14.8321 6.36231C15.4899 6.55176 15.8425 7.00433 15.932 7.67529C16.0425 8.52518 15.9925 8.56991 15.1373 8.45677C14.0875 8.31731 13.0402 8.3331 12.0141 8.63306C10.6011 9.04617 9.18285 10.096 9.35914 12.3273C9.45649 13.5482 10.0406 14.427 11.1642 14.9217C11.7878 15.1954 12.4508 15.2953 13.1271 15.3348C14.6769 15.4216 16.1819 15.1454 17.6817 14.8112C18.008 14.7375 18.1264 14.5796 18.1238 14.2481C18.108 13.1562 18.1185 12.0616 18.1185 10.9696L18.1159 10.9643ZM15.6294 13.4219C14.8584 13.5508 14.0848 13.6219 13.306 13.5666C13.0981 13.5535 12.8903 13.5035 12.6903 13.443C11.9641 13.2193 11.5983 12.7851 11.5431 12.1037C11.4773 11.3064 11.7878 10.7302 12.4824 10.4091C12.6903 10.3118 12.9139 10.2276 13.1402 10.1934C13.977 10.0671 14.8163 10.1013 15.653 10.225C15.8872 10.2592 15.9741 10.3618 15.9662 10.5933C15.953 10.9933 15.9635 11.3932 15.9635 11.7932C15.9635 12.1931 15.9556 12.5931 15.9662 12.993C15.9714 13.2377 15.9083 13.3745 15.6346 13.4219H15.6294Z"
            fill="#717982"
          />
          <path
            d="M33.4876 6.99644C33.1324 6.05709 32.5193 5.30456 31.6247 4.85199C29.4908 3.77055 25.8491 4.25996 24.7966 7.64373C24.3546 9.06459 24.3335 10.5039 24.7282 11.9379C25.1492 13.4745 26.1307 14.527 27.641 15.0059C29.3987 15.5637 31.1721 15.4716 32.9114 14.8717C33.5139 14.6639 33.5086 14.6296 33.2902 13.9982C33.2218 13.7982 33.1587 13.5982 33.085 13.4009C32.9114 12.9457 32.8824 12.9141 32.4535 13.0799C31.5089 13.4482 30.5275 13.5061 29.5328 13.4482C27.9567 13.3588 27.07 12.5299 26.8464 10.9617C26.7753 10.4644 26.8095 10.417 27.3095 10.4144C28.2988 10.4092 29.2881 10.4144 30.2775 10.4144V10.4197C31.2879 10.4197 32.2983 10.4249 33.3087 10.4197C33.7639 10.417 33.8165 10.3749 33.8507 9.91974C33.9244 8.92251 33.8428 7.94106 33.4902 6.99907L33.4876 6.99644ZM31.3826 8.74621C30.0196 8.74621 28.6567 8.75148 27.291 8.74621C26.8806 8.74621 26.8595 8.70148 26.9227 8.3068C27.1726 6.78331 28.3777 5.92816 29.8775 6.21234C30.4696 6.32548 30.9142 6.64912 31.2326 7.14906C31.5326 7.62005 31.6536 8.13577 31.6457 8.70938C31.5405 8.72516 31.4615 8.74621 31.38 8.74621H31.3826Z"
            fill="#717982"
          />
          <path
            d="M88.2908 8.49626C88.075 6.20183 86.7252 4.63361 84.5623 4.38891C82.0337 4.10474 79.9077 5.32037 79.1973 7.83846C78.8999 8.88832 78.8578 9.96186 78.9999 11.0275C79.263 13.0062 80.2603 14.4613 82.2337 15.048C83.9308 15.5532 85.6438 15.4585 87.3225 14.8981C87.9829 14.677 87.9829 14.6323 87.7619 14.0008C87.6856 13.7824 87.6119 13.564 87.5251 13.3483C87.3593 12.9404 87.3409 12.9167 86.9199 13.0825C85.9964 13.443 85.0386 13.5035 84.065 13.4561C82.3968 13.3772 81.468 12.4931 81.3022 10.8486C81.2601 10.4407 81.268 10.4223 81.6917 10.4197C82.7021 10.4118 83.7098 10.4197 84.7202 10.4197V10.425C85.7306 10.425 86.7384 10.4302 87.7488 10.425C88.254 10.4223 88.2829 10.4039 88.304 9.89082C88.325 9.43035 88.3355 8.96462 88.2934 8.50416L88.2908 8.49626ZM85.8043 8.74623C84.4597 8.74623 83.1152 8.75149 81.7706 8.74623C81.3364 8.74623 81.2996 8.68571 81.3864 8.27261C81.718 6.70176 82.8863 5.89924 84.3755 6.21762C84.9176 6.33339 85.3359 6.62809 85.6464 7.07803C85.9779 7.55691 86.1095 8.09895 86.1253 8.65677C86.0148 8.74886 85.9095 8.75149 85.8043 8.75149V8.74623Z"
            fill="#717982"
          />
          <path
            d="M61.5105 8.74358C61.4684 8.41204 61.3868 8.08314 61.3 7.7595C60.629 5.31508 58.4793 4.09945 56.0244 4.38889C54.1193 4.61254 52.7879 5.63083 52.1617 7.4569C51.6065 9.07511 51.617 10.7249 52.188 12.3405C52.7432 13.9061 53.8589 14.8665 55.4876 15.2322C56.048 15.3585 56.6164 15.3664 57.1795 15.3217C58.9319 15.1875 60.237 14.3586 61.0053 12.7536C61.4394 11.8458 61.5868 10.8749 61.5815 9.87238C61.5605 9.49611 61.5578 9.11458 61.5105 8.74095V8.74358ZM58.9056 12.0721C58.5003 13.0614 57.7847 13.5351 56.7085 13.5482C55.6455 13.564 54.8666 13.1114 54.493 12.1116C53.9246 10.5986 53.9246 9.06459 54.5193 7.55689C54.8719 6.66227 55.5797 6.24391 56.5374 6.2097C57.5531 6.17023 58.3477 6.51229 58.8161 7.45954C59.1897 8.2147 59.2845 9.02249 59.2739 9.89869C59.295 10.6144 59.2003 11.3617 58.9108 12.0747L58.9056 12.0721Z"
            fill="#717982"
          />
        </g>
        <defs>
          <clipPath id="clip0_3143_23065">
            <rect width="88.32" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <svg
        width="24"
        height="18"
        viewBox="0 0 24 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.963 7.46202C23.8649 6.56673 23.5278 5.7143 22.9871 4.99407C22.0474 3.74114 20.7978 3.03132 19.2325 2.89323C18.4181 2.82176 17.5985 2.95189 16.8463 3.27214C16.7376 3.31761 16.7368 3.31761 16.6779 3.21909C16.5022 2.92258 16.2906 2.64883 16.048 2.40402C15.0612 1.4256 13.8765 1.00375 12.4981 1.16205C11.7057 1.25512 10.9586 1.58062 10.3509 2.09753C10.2288 2.19857 10.1143 2.30803 9.98635 2.42339L9.82215 2.22888C9.22142 1.52846 8.42638 1.02201 7.53768 0.773644C6.64898 0.525281 5.70657 0.54617 4.82974 0.833667C3.95292 1.12116 3.1811 1.66234 2.61199 2.3887C2.04288 3.11505 1.70207 3.99393 1.6327 4.91407C1.57923 5.6034 1.68317 6.29587 1.93667 6.93912C2.0015 7.10753 1.99898 7.10332 1.85247 7.20352C0.696377 7.98238 0.0842305 9.07027 0.00592291 10.4537C-0.0246636 11.0631 0.0887005 11.6712 0.336835 12.2287C0.642841 12.9457 1.15127 13.5579 1.79987 13.9904C2.44847 14.4229 3.20911 14.6569 3.98866 14.6638C4.23416 14.6657 4.47924 14.6434 4.72037 14.5973C4.82815 14.5771 4.82899 14.5779 4.87109 14.6815C4.93809 14.8486 5.01542 15.0114 5.10265 15.169C5.52931 15.9492 6.18809 16.5774 6.98769 16.9665C7.78729 17.3555 8.68812 17.4863 9.56534 17.3406C10.9799 17.1065 12.0249 16.3259 12.7103 15.0671C12.7448 15.0031 12.7751 14.9366 12.8063 14.8701C12.8526 14.7741 12.8526 14.7741 12.9444 14.8153L12.9679 14.8255C13.8127 15.1991 14.765 15.2513 15.6455 14.9722C16.5261 14.6931 17.2744 14.1019 17.7497 13.3098C17.807 13.2164 17.8062 13.2172 17.9106 13.234C18.3176 13.3054 18.7316 13.3294 19.1441 13.3056C19.6428 13.2749 20.1344 13.1716 20.6033 12.9991C21.7049 12.5891 22.6359 11.8194 23.2456 10.8145C23.8553 9.80965 24.1081 8.62842 23.963 7.46202Z"
          fill="#189BD7"
        />
      </svg>
    </div>
  );
};
export const LogoSalesforce: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={LogoSalesforceSVG} {...props} />;
};
