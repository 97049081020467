import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DetailLabel = styled.span`
  overflow: hidden;
  color: var(--N-900, #0d283b);
  text-overflow: ellipsis;
  font-family: 'Cera Pro Medium';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const CardContents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default {
  Container,
  Details,
  DetailLabel,
  ActionsContainer,
  CardContents,
};
