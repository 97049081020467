import { Table } from '../../../../../../componentsV2/Table';
import { TopicLabel } from '../../../TopicLabel';
import { Topic } from '../../../../../../services/types';
import { SelectedTopic } from '../../types';

export interface Props {
  data?: Topic[];
  loading?: boolean;
  onTopicClicked: (args: SelectedTopic) => void;
}

export const TopTopicsTable: React.FC<Props> = ({ data, loading = false, onTopicClicked }) => {
  return (
    <Table
      pagination={false}
      loading={loading}
      columns={[
        {
          title: 'Topics',
          key: 'topic',
          dataIndex: 'topic',
          sorter: (a, b) => a.name.localeCompare(b.name),
          render: (v, record) => {
            return (
              <TopicLabel
                name={record.name}
                onClick={() => {
                  onTopicClicked({
                    topicId: record.id,
                    topicName: record.name,
                  });
                }}
              />
            );
          },
        },
        {
          title: '# Accounts',
          key: 'accounts',
          dataIndex: 'accounts',
          sorter: (a, b) => a.accounts - b.accounts,
        },
        {
          title: '# Mentions',
          key: 'mentions',
          dataIndex: 'mentions',
          sorter: (a, b) => a.mentions - b.mentions,
        },
        {
          title: '# Meetings',
          key: 'meetings',
          dataIndex: 'meetings',
          sorter: (a, b) => a.meetings - b.meetings,
        },
        {
          title: 'Total ARR',
          key: 'total-arr',
          dataIndex: 'totalArr',
          sorter: (a, b) => a.totalArr - b.totalArr,
          render: value => `$ ${value}`,
        },
      ]}
      data={data || []}
    />
  );
};
