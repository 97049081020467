import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { TrendEvent } from './types';
import { coreService } from '../../../services/core/core-service';

interface Props {
  accountId: string;
}

export const useAccountInsightTrends = (props: Props) => {
  const { data, isFetching } = useQuery({
    queryKey: ['account-insight-trends', props.accountId],
    queryFn: async accountId => {
      return coreService.getAccountTrends(props.accountId);
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  return { data, isFetching };
};
