import React from 'react';
import Styles from './styles';
import { getStatusConfig } from '../../../../RecapsTab/components/RecapStatus';
import { MeetingAccountSelector } from '../../../../../../meetings/account-selection/components/AccountSelector';
import { Popover } from 'antd';
import { MeetingPopover } from '../MeetingPopover';
import { Event } from '../..';
import { ExportOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ProviderIntegration } from '../../../../../../../redux/typings/store';

const { Container, Title } = Styles;

interface Props {
  event: Event;
  secondaryActions: React.ReactNode[];
  isReady?: boolean;
  joinMeetingAction?: React.ReactNode;
  onAccountRemoved: (eventId: string) => void;
  onAccountClicked: (accounts: ProviderIntegration[]) => void;
  onInsightToggleChanged?: (checked: boolean) => void;
}

export const MeetingCardContent: React.FC<Props> = ({
  event,
  secondaryActions,
  joinMeetingAction,
  isReady = false,
  onAccountRemoved,
  onAccountClicked,
  onInsightToggleChanged,
}) => {
  const { title, id, owner, account, prospectAccounts, status, isOwner, ownerName } = event;
  const color = getStatusConfig(status || '', '').color;

  const handleTitleClicked = () => {
    if (isReady) window.open(`/recap/${id}`);
  };

  const titleComponent = (
    <Title onClick={handleTitleClicked} isReady={isReady}>
      {title}
    </Title>
  );

  return (
    <Container color={color}>
      <h1 style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {joinMeetingAction ? joinMeetingAction : <></>}

        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          {isReady && <ExportOutlined style={{ fontSize: '14px' }} />}
          {titleComponent}
          <Popover
            placement="bottom"
            content={
              <MeetingPopover event={event} onInsightToggleChanged={onInsightToggleChanged} />
            }
            arrow={false}
          >
            <span style={{ fontSize: '12px', textDecoration: 'underline' }}>Details</span>
          </Popover>
        </div>
      </h1>

      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <div style={{ width: '200px' }}>Owner: {owner || ownerName || ''}</div>

        {secondaryActions}

        <MeetingAccountSelector
          eventId={id}
          account={account}
          hasModifyPermissions={isOwner || false}
          prospectAccounts={prospectAccounts || []}
          onAccountRemoved={onAccountRemoved}
          onAccountClicked={onAccountClicked}
        />
      </div>
    </Container>
  );
};
