import React, { useState } from 'react';
import { GoogleButton } from '../../../../Layout/FramedLayout/SignUp/GoogleButton';
import AuthenticationForm from '../../../../features/authentication/components/AuthenticationForm';
import Label from '../../../../features/shared/components/Label';
import Styles from './styles';
import { MicrosoftButton } from '../../../../features/authentication/components/MicrosoftButton';
import { SsoForm } from '../../../../componentsV2/SsoForm';
import { coreService } from '../../../../services/core/core-service';
import { AuthenticationButton } from '../../../../features/authentication/components/AuthenticationButton';

const {
  ActionsContainer,
  LoginLink,
  TermsContainer,
  FormContainer,
  SocContainer,
  SocLogo,
  TermsLabel,
  TermsLink,
  SecurityLink,
  ListContainer,
  SignInContainer,
  GoogleWorkspaceContainer,
  GoogleContainer,
  LoginOptions,
} = Styles;

const SignupForm: React.FC = () => {
  const [showSsoForm, setShowSsoForm] = useState(false);

  const handleClick = async () => {
    const params = new URLSearchParams();
    const locationParams = new URLSearchParams(window.location.search);

    const utmSource = locationParams.get('utm_source') || '';
    const utmMedium = locationParams.get('utm_medium') || '';
    const utmCampaign = locationParams.get('utm_campaign') || '';
    const utmId = locationParams.get('utm_id') || '';
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    params.append('timezone', timezone);
    params.append('utm_source', utmSource);
    params.append('utm_medium', utmMedium);
    params.append('utm_campaign', utmCampaign);
    params.append('utm_id', utmId);

    const finalUrl = `${
      process.env.REACT_APP_API_CORE
    }/users/signup/microsoft?${params.toString()}`;

    window.location.replace(finalUrl);
  };

  const handleSsoClick = () => {
    setShowSsoForm(true);
  };

  return (
    <>
      <AuthenticationForm
        footer={
          <SocContainer>
            <SocLogo src="https://assets.update.ai/soc2.png" />
            <Label style={{ fontSize: '12px' }}>
              UpdateAI takes your data privacy and security seriously. Visit our
              <SecurityLink href="https://security.update.ai/" about="_blank">
                security portal
              </SecurityLink>{' '}
              to learn more.
            </Label>
          </SocContainer>
        }
        title="Create my account"
        description={`Let's get started with your 14-day free trial. No credit card required.`}
      >
        <FormContainer>
          {showSsoForm ? (
            <SsoForm
              primaryLabel="Sign up with SSO"
              backLabel="Sign up with another option"
              getUrlFunction={email => coreService.getSsoSignupUrl(email).then(data => data.url)}
              onBack={() => setShowSsoForm(false)}
            />
          ) : (
            <LoginOptions>
              <GoogleContainer>
                <GoogleWorkspaceContainer>
                  <Label style={{ color: '#a6a6a6' }}>
                    Use your Google Workspace or Microsoft business email.
                  </Label>
                </GoogleWorkspaceContainer>
                <GoogleButton />
              </GoogleContainer>
              <MicrosoftButton label="Sign up with Microsoft" handleClick={handleClick} />
              <AuthenticationButton label="Sign up using SSO" onClick={handleSsoClick} />
            </LoginOptions>
          )}

          <ActionsContainer>
            <ListContainer>
              <SignInContainer>
                <Label>Already have an account?</Label>
                <LoginLink href="/login">Login here</LoginLink>
                <Label>.</Label>
              </SignInContainer>
            </ListContainer>
          </ActionsContainer>
          <TermsContainer>
            <TermsLabel>By signing up, you agree to our </TermsLabel>
            <TermsLink href="https://www.update.ai/legals/terms-of-use" target="_blank">
              Terms of Service
            </TermsLink>{' '}
            <TermsLabel>and</TermsLabel>{' '}
            <TermsLink href="https://www.update.ai/legals/privacy-policy" target="_blank">
              Privacy Policy
            </TermsLink>
            <TermsLabel>.</TermsLabel>
          </TermsContainer>
        </FormContainer>
      </AuthenticationForm>
    </>
  );
};

export default SignupForm;
