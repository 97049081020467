import React, { useEffect, useMemo, useState } from 'react';
import Styled from './style';
import { Mapping } from '../../../../../../../../../../features/shared/components/Icons/Mapping';
import { Select } from 'antd';

function isSingleOption(
  opt: { label: string; value: string } | { label: string; value: string }[],
): opt is { label: string; value: string } {
  return !Array.isArray(opt);
}

const { Container, LeftColumn, RightColumn, Name } = Styled;

interface Props {
  label: string;
  value: string;
  options: {
    id: string;
    label: string;
  }[];
  color?: string;
  disableUserInteractions: boolean;
  onRemoveMapping: () => unknown;
  onMappingChanged: (crmProperty: { id: string; label: string }) => unknown;
}

export const MappingField: React.FC<Props> = ({
  value,
  options,
  label,
  color,
  disableUserInteractions,
  onRemoveMapping,
  onMappingChanged,
}) => {
  const [selectedOption, setSelectedOption] = useState('');

  const availableOptions = useMemo(() => {
    return options.map(opt => ({ label: opt.label, value: opt.id }));
  }, [options]);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  return (
    <Container color={color}>
      <LeftColumn>
        <Name>{label}</Name>
      </LeftColumn>

      <RightColumn>
        <Mapping />
        <Select
          showSearch
          style={{ width: 300 }}
          key={`inner-option`}
          placeholder="Map property from CRM"
          options={availableOptions}
          value={selectedOption === '' ? undefined : selectedOption}
          onSelect={(value, option) => console.log('Selected', value, option)}
          onChange={(value, option) => {
            if (!value) {
              onRemoveMapping();
            } else if (isSingleOption(option)) {
              onMappingChanged({ id: option.value, label: option.label });
            }
          }}
          optionFilterProp="label"
          disabled={disableUserInteractions}
          allowClear
          onClear={onRemoveMapping}
        />
      </RightColumn>
    </Container>
  );
};
