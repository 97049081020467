import styled from 'styled-components';

const NotMappedText = styled.span`
  color: #ff6b54;
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  text-decoration-line: underline;
  cursor: pointer;
`;

const CrmPropertyValue = styled.span`
  color: var(--Cool-Gray-700, #0a1e2c);
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
`;

export default {
  NotMappedText,
  CrmPropertyValue,
};
