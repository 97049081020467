import styled from 'styled-components';
import { Button as AntdButton } from 'antd';
import { colors } from '../../../theme/colors';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  font-style: normal;
  color: ${colors.coolGray[500]};
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.24px;
  margin-bottom: 16px;
`;

const LastGeneratedLabel = styled.span`
  color: ${colors.coolGray['400']};

  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Button = styled(AntdButton)`
  & path {
    fill: ${colors.coolGray[500]};
  }
`;

export default { Container, Buttons, Button, LastGeneratedLabel };
