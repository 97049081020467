import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardContainers,
  EmptyState,
  EmptyStateCard,
  Gradient,
  GradientContainer,
  IconContainer,
  MessageContent,
  NoItemsDescription,
  NoItemsTitle,
  RecapBody,
  RecapContentsSection,
  SmartSummaryContainer,
  TabPane,
  VideoTranscriptContainer,
} from './styles';
import { ErrorView } from '../../componentsV2/ErrorView';
import { UpdateLoader } from '../../componentsV2/UpdateLoader';
import JoinBanner from '../../features/public/components/JoinBanner';
import { MeetingInfoSelector } from '../../features/recap/components/MeetingInfoSelector/MeetingInfoSelector';
import { NavigationBar } from '../../features/recap/components/NavigationBar/components/NavigationBar';
import { TalkRatioCard } from '../../features/recap/components/TalkRatioCard';
import { VideoAndTranscript } from '../../features/recap/components/VideoAndTranscript/VideoAndTranscript';
import { VerticalFixedLayout } from '../../features/shared/components/VerticalFixedLayout/VerticalFixedLayout';
import { ChunkNavigationProvider } from '../../features/shared/contexts/ChunkNavigationContext/ChunkNavigationProvider';
import { VideoRecapPlayerProvider } from '../../features/shared/contexts/VideoRecapPlayer/VideoRecapPlayerProvider';
import { selectUser } from '../../redux/selectors';
import { fetchRecap } from '../../redux/slices/recapSlice';
import { EventStatus } from '../../types/commonTypes';
import { VideoPlayerProvider } from '../../features/video/contexts/VideoPlayer';
import { AskMeAnythingProvider } from '../../features/askMeAnything/context/AskMeAnythingProvider';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRedirectNavigation } from '../../features/redirectNavigation/useRedirectNavigation';
import { useLocationChange } from '../../features/analytics/hooks/useLocationChange';
import { Questions } from '../../features/askMeAnything/questions';
import { useRecapAnalytics } from '../../features/recapv2/recap-analytics/hooks/useRecapAnalytics';
import { SummarizeInsightsProvider } from '../../features/insights/summarize-insights/summarize-insights.context';
import { AutomaticPlayProvider } from './automatic-play/automatic-play.context';
import { Meddicc } from '../../features/recap/components/Meddicc';
import { RecapManagementProvider } from '../../features/recapv2/recap-management/context';
import { useRecapManagement } from '../../features/recapv2/recap-management/hook';
import { InsightCards } from './cards';
import { getInsightTypeConfiguration } from '../../features/insights/insight-type-configuration/insight-type-configuration.service';
import { InsightEngingeToggle } from './components/InsightEngineToggle';

export interface Props {
  id: string;
  shareCode: string;
}

export const Recap: React.FC<Props> = ({ id, shareCode }) => {
  const [infoKey, setInfoKey] = useState('smart-summary');
  const {
    status: eventStatus,
    type,
    id: recapId,
    fetchError,
    isPublic,
    isFetching,
    publicVisibility,
    categories,
  } = useRecapManagement();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    if (tab) {
      setInfoKey(tab);
    }
  }, [location]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useLocationChange();

  const redirect = useRedirectNavigation();
  const recapAnalytics = useRecapAnalytics();
  const user = useSelector(selectUser);

  const isRecapBeingGenerated = eventStatus !== EventStatus.READY;
  const hideInsights =
    isPublic &&
    Object.keys(categories).filter(categoryName => {
      const config = getInsightTypeConfiguration(categoryName);
      const hasVisibilityKey = config.visibilityKey;
      if (!hasVisibilityKey) {
        return false;
      }
      return publicVisibility[hasVisibilityKey];
    }).length === 0;

  useEffect(() => {
    if (!user && !shareCode) {
      redirect.setRedirect();
      navigate('/signin');
    }

    if (id) dispatch(fetchRecap({ id, secret: shareCode }));
  }, [id, shareCode]);

  useEffect(() => {
    recapAnalytics.viewed();
  }, [recapId]);

  const getCardContent = () => {
    switch (infoKey) {
      case 'smart-summary': {
        return <InsightCards />;
      }

      case 'meeting-details':
        return <TalkRatioCard />;

      case 'meddicc':
        return <Meddicc />;
    }
  };

  const cardContent = getCardContent();

  const smartSummary = useMemo(() => {
    if (hideInsights) return <></>;

    return (
      <SmartSummaryContainer>
        <TabPane>
          <MeetingInfoSelector onSelect={key => setInfoKey(key)} selected={infoKey} />
        </TabPane>

        {isRecapBeingGenerated ? (
          <EmptyState>
            <EmptyStateCard>
              <IconContainer>✨</IconContainer>

              <MessageContent>
                <NoItemsTitle>Insights are processing</NoItemsTitle>

                <NoItemsDescription>
                  Our AI is working its magic to generate your Smart Summary™.
                </NoItemsDescription>
              </MessageContent>
            </EmptyStateCard>
          </EmptyState>
        ) : (
          <CardContainers>{cardContent}</CardContainers>
        )}
      </SmartSummaryContainer>
    );
  }, [isRecapBeingGenerated, cardContent, hideInsights]);

  if (isFetching) {
    return (
      <VerticalFixedLayout sider={false}>
        <div
          style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <UpdateLoader min />
        </div>
      </VerticalFixedLayout>
    );
  }

  if (fetchError) {
    return (
      <VerticalFixedLayout sider={false}>
        <ErrorView message="We're unable to locate this meeting recap. Please contact the meeting owner to confirm whether they have removed it or made it private. If you believe there's an error, you may contact support@update.ai and include the meeting URL." />
      </VerticalFixedLayout>
    );
  }

  return (
    <RecapManagementProvider id={id} secret={shareCode}>
      <AskMeAnythingProvider
        amaKey={recapId}
        suggestedQuestions={{
          displayDropdown: true,
          data: Questions.recap,
          category:
            Questions.recap.type.find(
              t => t.label.toLocaleLowerCase() === type?.toLocaleLowerCase(),
            )?.value || 'none',
        }}
      >
        <VideoRecapPlayerProvider>
          <ChunkNavigationProvider>
            <VideoPlayerProvider>
              <VerticalFixedLayout sider={false}>
                <SummarizeInsightsProvider>
                  {isPublic && !user ? <JoinBanner /> : <></>}
                  {!isPublic && <InsightEngingeToggle />}
                  <NavigationBar />
                  <div
                    style={{
                      height: `calc(100vh - ${isPublic ? '124px' : '133px'})`,
                      display: 'flex',
                    }}
                  >
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <RecapBody>
                        <AutomaticPlayProvider>
                          <Gradient>
                            <GradientContainer />
                          </Gradient>

                          <RecapContentsSection>
                            {smartSummary}

                            <VideoTranscriptContainer>
                              <VideoAndTranscript />
                            </VideoTranscriptContainer>
                          </RecapContentsSection>
                        </AutomaticPlayProvider>
                      </RecapBody>
                    </div>
                  </div>
                </SummarizeInsightsProvider>
              </VerticalFixedLayout>
            </VideoPlayerProvider>
          </ChunkNavigationProvider>
        </VideoRecapPlayerProvider>
      </AskMeAnythingProvider>
    </RecapManagementProvider>
  );
};
