import { useContext } from 'react';
import { upcomingMeetingsContext } from './upcoming-meetings.context';

export const useUpcomingMeetings = () => {
  const { store } = useContext(upcomingMeetingsContext);
  const {
    updateAccounts,
    isFetching,
    events,
    searchTerm,
    meetingType,
    pageSize,
    page,
    paginationMetadata,
    meetingTypeChanged,
    resetFilters,
    updateAccountsFiltered,
    searchTermChanged,
    pageChanged,
    keepFetching,
    refetch,
    setPurposeStatement,
    updateMeetingAccount,
  } = store();

  return {
    isFetching,
    updateAccounts,
    events,
    pageSize,
    page,
    searchTerm,
    paginationMetadata,
    meetingType,
    hasNextPage: paginationMetadata.page < paginationMetadata.totalPages,
    meetingTypeChanged,
    updateAccountsFiltered,
    changeSearchTerm: searchTermChanged,
    changePage: pageChanged,
    resetFilters,
    keepFetching,
    refetch,
    setPurposeStatement,
    updateMeetingAccount,
  };
};
