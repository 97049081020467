import { coreHost, coreService } from '../../../services/core/core-service';
import { useSession } from '../../../hooks/useSession';
import { formatInsightTime } from '../../../utils/dateUtils';
import { extractError } from '../../../utils/api';
import { useToast } from '../../../hooks/useToast';
import { AskMeAnythingMessage } from '../types';
import { ChatItem } from '../../../services/types';
import { useContext } from 'react';
import { AskMeAnythingContext } from '../context/AskMeAnythingContext';
import { storeToInsightArgs } from '../../insights/context/CustomerIntelligenceProvider/utils';
import { useAskMeAnythingAnalytics } from './useAskMeAnythingAnalytics';
import { WordEmitter, cleanupSSEMetadata, createQueryStringFromFilterValues } from '../utils';
import { queryStringToString } from '../../../utils/query-string';
import retry from 'async-retry';
import axios from 'axios';

export const useAskMeAnything = () => {
  const { error } = useToast();
  const { user } = useSession();
  const { submitted, suggestedQuestion: suggestedQuestionAnalytics } = useAskMeAnythingAnalytics();

  const {
    messages,
    isAsking,
    suggestedQuestions,
    suggestedQuestionsDefaultCategory: defaultCategory,
    suggestedQuestionsDisplayDropdown,
    askingStarted,
    askingFinished,
    addMessage,
    removeLastMessage,
    clearMessages,
    addWordToMessage,
  } = useContext(AskMeAnythingContext);

  const askInsights = async (chatMessages: ChatItem[]) => {
    const queryString = createQueryStringFromFilterValues();
    return fetch(`${coreHost}/ask/insights${queryString}`, {
      method: 'POST',
      credentials: 'include',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        chat: chatMessages,
      }),
    }).then(response => response.body);
  };

  const askRecap = async (recapId: string, chatMessages: ChatItem[]) => {
    return fetch(`${coreHost}/ask/recap`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      body: JSON.stringify({
        chat: chatMessages,
        recapId,
      }),
    }).then(response => response.body);
  };

  const askAccounts = async (
    chatMessages: ChatItem[],
    accountId: string,
    from: string | null,
    to: string | null,
  ) => {
    const params = new URLSearchParams();
    if (from) params.append('from', from);
    if (to) params.append('to', to);
    const queryString = queryStringToString(params);
    return fetch(`${coreHost}/ask/accounts/${accountId}?${queryString}`, {
      method: 'POST',
      credentials: 'include',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        chat: chatMessages,
      }),
    }).then(response => response.body);
  };

  const ask = async (args: {
    question: string;
    askFunction: (chatMessages: ChatItem[]) => Promise<ReadableStream<Uint8Array> | null>;
    suggestedQuestion?: boolean;
    location: string;
  }) => {
    const { question, askFunction, suggestedQuestion, location } = args;
    askingStarted();

    const newMessage: AskMeAnythingMessage = {
      isSenderUser: true,
      content: question,
      isWaiting: false,
      sender: user?.firstName || '',
      timestamp: formatInsightTime(Date()),
      references: [],
    };

    const chatMessages = addMessage(newMessage).map(amaMessage => {
      return {
        role: amaMessage.isSenderUser ? 'user' : 'update',
        content: amaMessage.content,
      };
    });

    addMessage({
      isSenderUser: false,
      content: '',
      sender: 'UpdateAI',
      isWaiting: true,
      timestamp: formatInsightTime(Date()),
      references: [],
    });

    try {
      const response = await retry(
        async bail => {
          return askFunction(chatMessages);
        },
        { retries: 5 },
      );
      if (response) {
        const messageIndex = chatMessages.length;

        const emitter = new WordEmitter(word => {
          addWordToMessage(messageIndex, word);
        });

        const responseWriteStream = new WritableStream({
          write: chunk => {
            const decodedText = new TextDecoder().decode(chunk);
            const words = cleanupSSEMetadata(decodedText);
            words.forEach(word => {
              emitter.addWord(word);
            });
          },
          close: () => {
            emitter.finish();
          },
        });

        response.pipeTo(responseWriteStream);
      }
    } catch (e) {
      const errorMessage = extractError(e);
      console.error(`Error asking message: ${errorMessage}`);
      error('There was an error sending the latest chat message. Please try again.');

      removeLastMessage();
    } finally {
      askingFinished();
      if (!!suggestedQuestion) {
        suggestedQuestionAnalytics.submitted(location);
      } else {
        submitted(location);
      }
    }
  };

  const copyAll = () => {
    const formatedMessages = messages
      .map(message => {
        return `${message.sender} - ${message.timestamp}\n${message.content}`;
      })
      .join('\n\n');
    navigator.clipboard.writeText(formatedMessages);
  };

  return {
    messages,
    isAsking,
    suggestedQuestions,
    suggestedQuestionsDisplayDropdown,
    defaultCategory,
    clearMessages,
    ask,
    askInsights,
    askRecap,
    copyAll,
    askAccounts,
  };
};
