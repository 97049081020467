import { Button } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 100%;
  overflow-y: scroll;
  overflow-x: none;
  height: calc(100vh - 180px);
  padding-bottom: 48px;
`;

export const Highlight = styled.span`
  color: #e6614c;
  text-decoration: underline;
`;

export const UpgradePlan = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const ModalTitle = styled.div`
  color: var(--new-color-shades-gray-950, #282828);
  text-align: center;
  font-family: Cera Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

export const ModalDescription = styled.div`
  color: var(--new-color-shades-gray-600, #818181);
  text-align: center;
  /* text-sm/leading-5/font-normal */

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 16px;
`;

export const ModalMembers = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #282828;
`;

export const RadioContainer = styled.div<{ position: string; selected: boolean }>`
  display: flex;
  width: 400px;
  padding: 17px;
  align-items: flex-start;
  gap: 12px;
  border-radius: ${({ position }) => (position === 'up' ? '6px 6px 0px 0px' : '0px 0px 6px 6px')};
  border: ${({ selected }) => (selected ? '1px solid  #ffdad4' : '1px solid #E4E4E4')};
  background: ${({ selected }) => (selected ? '#fff8f6' : '#FFF')};
`;

export default { Container, UpgradePlan, ModalContent, ModalTitle, ModalDescription, ModalMembers };
