import { Button, Switch, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { coreService } from '../../../../../services/core/core-service';
import { useToast } from '../../../../../hooks/useToast';
import { colors } from '../../../../../theme/colors';
import { Box } from '../components/Box';
import { VisibilityOption } from '../../VisibilityConfig/styles';
import { Rows } from './Row';
import { ILink } from '../../../../../services/types';
import Spinner from '../../../../../componentsV2/Spinner';

import Styles from '../styles';
const { Title, Footer, Content } = Styles;

import StylesManageShareLink from './styles';
import { ManageLinksComponent } from '../components/ManageLinksComponent';
import { useRecapManagement } from '../../../../recapv2/recap-management/hook';
const { Loader, TwoColumns } = StylesManageShareLink;

interface Props {
  toGenerateLinks: () => void;
  toLinksDetails: () => void;
  selectLink: (link: ILink & { edit: boolean }) => void;
  recapId: string;
  isExternal: boolean;
}

export const ManageShareLink: React.FC<Props> = ({
  selectLink,
  toLinksDetails,
  toGenerateLinks,
  recapId,
  isExternal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [links, setLinks] = useState<ILink[] | null>(null);

  const { error, success } = useToast();
  const { changePublicVisibility, publicVisibility } = useRecapManagement();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePublicVisibilityClicked = (ev: any, config: any) => {
    ev.preventDefault();
    ev.stopPropagation();
    changePublicVisibility(config);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleToggleAll = (ev: any, value: boolean) => {
    ev.preventDefault();
    ev.stopPropagation();
    changePublicVisibility({
      showTldr: value,
      showImportantNumbers: value,
      showGrowthOpportunities: value,
      showActionItems: value,
      showProductFeedback: value,
      showRisks: value,
      showAdvocacy: value,
      showMeetingDetails: value,
    });
  };

  const handleInitialFetch = () => {
    setIsLoading(true);
    coreService
      .getAllShareLinks(recapId)
      .then(res => {
        setLinks(res.links);
      })
      .catch(err => error(err.message))
      .finally(() => setIsLoading(false));
  };

  const handleCancelLink = async (id: string) => {
    setIsLoading(true);
    try {
      await coreService.cancelShareLink(id);
      await handleInitialFetch();
      success('Link has been deactivated.');
    } catch {
      error('An error occurred while canceling the link. Please try again.');
      setIsLoading(false);
    }
  };

  const handelCancelAllLinks = async () => {
    setIsLoading(true);
    try {
      await coreService.cancelAllShareLinks(recapId);
      await handleInitialFetch();
      success('All links have been deactivated.');
    } catch {
      error('An error occurred while canceling the links. Please try again.');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleInitialFetch();
  }, []);

  const areAllEnabled =
    publicVisibility.showTldr &&
    publicVisibility.showImportantNumbers &&
    publicVisibility.showRisks &&
    publicVisibility.showProductFeedback &&
    publicVisibility.showActionItems &&
    publicVisibility.showAdvocacy &&
    publicVisibility.showMeetingDetails;

  return (
    <>
      <Title>Manage Share Links</Title>

      <Content>
        <Box title="Link Settings (applies to all links created for this meeting recap)">
          <TwoColumns>
            <VisibilityOption style={{ width: '100%', padding: 0 }}>
              <span>Show All</span>
              <Switch
                size="small"
                checked={areAllEnabled}
                onClick={(value, event) => {
                  handleToggleAll(event, value);
                }}
              />
            </VisibilityOption>
            <VisibilityOption style={{ width: '100%', padding: 0 }}>
              <span>Show TL;DR</span>
              <Switch
                size="small"
                checked={publicVisibility.showTldr}
                onClick={(value, event) => {
                  handlePublicVisibilityClicked(event, { showTldr: value });
                }}
              />
            </VisibilityOption>

            <VisibilityOption style={{ width: '100%', padding: 0 }}>
              <span>Show Important Numbers</span>
              <Tooltip
                title={!isExternal ? 'Applies only to external meetings.' : undefined}
                placement="left"
              >
                <Switch
                  checked={publicVisibility.showImportantNumbers}
                  style={{ marginLeft: '16px' }}
                  size="small"
                  onClick={(value, event) => {
                    handlePublicVisibilityClicked(event, { showImportantNumbers: value });
                  }}
                />
              </Tooltip>
            </VisibilityOption>

            <VisibilityOption style={{ width: '100%', padding: 0 }}>
              <span>Show Action Items</span>
              <Switch
                size="small"
                checked={publicVisibility.showActionItems}
                onClick={(value, event) => {
                  handlePublicVisibilityClicked(event, { showActionItems: value });
                }}
              />
            </VisibilityOption>

            <VisibilityOption style={{ width: '100%', padding: 0 }}>
              <span>Show Product Feedback</span>
              <Switch
                checked={publicVisibility.showProductFeedback}
                style={{ marginLeft: '16px' }}
                size="small"
                onClick={(value, event) => {
                  handlePublicVisibilityClicked(event, { showProductFeedback: value });
                }}
              />
            </VisibilityOption>

            <VisibilityOption style={{ width: '100%', padding: 0 }}>
              <span>Show Risks</span>
              <Tooltip
                title={!isExternal ? 'Applies only to external meetings.' : undefined}
                placement="left"
              >
                <Switch
                  size="small"
                  checked={publicVisibility.showRisks}
                  onClick={(value, event) => {
                    handlePublicVisibilityClicked(event, { showRisks: value });
                  }}
                />
              </Tooltip>
            </VisibilityOption>

            <VisibilityOption style={{ width: '100%', padding: 0 }}>
              <span>Show Advocacy</span>
              <Switch
                checked={publicVisibility.showAdvocacy}
                style={{ marginLeft: '16px' }}
                size="small"
                onClick={(value, event) => {
                  handlePublicVisibilityClicked(event, { showAdvocacy: value });
                }}
              />
            </VisibilityOption>

            <VisibilityOption style={{ width: '100%', padding: 0 }}>
              <span>Show Growth Opportunities</span>
              <Switch
                checked={publicVisibility.showGrowthOpportunities}
                style={{ marginLeft: '16px' }}
                size="small"
                onClick={(value, event) => {
                  handlePublicVisibilityClicked(event, { showGrowthOpportunities: value });
                }}
              />
            </VisibilityOption>

            <VisibilityOption style={{ width: '100%', padding: 0 }}>
              <span>Show Talk Ratio</span>
              <Switch
                size="small"
                checked={publicVisibility.showMeetingDetails}
                onClick={(value, event) => {
                  handlePublicVisibilityClicked(event, { showMeetingDetails: value });
                }}
              />
            </VisibilityOption>
          </TwoColumns>
        </Box>

        {isLoading ? (
          <Loader>
            <Spinner animation="grow" color={colors.primary[500]} />
          </Loader>
        ) : (
          <Box
            title="Active Share Links"
            action={
              <Button
                size="small"
                ghost
                style={{ color: 'red' }}
                onClick={handelCancelAllLinks}
                disabled={links?.length === 0}
              >
                Deactivate all links
              </Button>
            }
          >
            <Rows
              list={links}
              selectLink={l => {
                selectLink(l);
                toLinksDetails();
              }}
              cancelLink={id => handleCancelLink(id)}
            />
          </Box>
        )}
      </Content>

      <Footer>
        <ManageLinksComponent />
        <Button onClick={toGenerateLinks}>Go to Generate New Link</Button>
      </Footer>
    </>
  );
};
