import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Section from '../_Misc/Section';
import { CardHolder, Container } from './style';
import { integrationBroadcast } from '../../../../App';
import {
  fetchUser,
  integrateCalendarResponse,
  integrateZoomResponse,
  integrateSlackResponse,
  integrateSalesforceResponse,
  integrateHubspotResponse,
} from '../../../../redux/slices/sessionSlice';
// import { SlackIntegrationCard } from './SlackIntegrationCard';
import { GoogleIntegrationCard } from './GoogleCalendarIntegrationCard';
import { ZoomIntegrationCard } from './ZoomIntegrationCard';
import { MicrosoftCalendarIntegrationCard } from './MicrosoftCalendarIntegrationCard';
import { HubspotIntegrationCard } from './HubspotIntegrationCard';
import { SalesforceIntegrationCard } from './SalesforceIntegrationCard';
import { Tabs } from 'antd';
import { ZapierIntegrationCard } from './ZapierIntegrationCard';
import { SlackIntegrationCard } from './SlackIntegrationCard';
import { GongIntegrationCard } from './GongIntegrationCard';

interface Props {
  name?: string;
}

const Integrations: React.FC<Props> = ({}) => {
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState('all');
  const tabs = [
    { key: 'all', label: `View All` },
    { key: 'conferencing', label: `Video Conferencing` },
    { key: 'calendar', label: `Calendar` },
    { key: 'crm', label: `CRM` },
    { key: 'channels', label: `Communication Channels` },
    { key: 'automations', label: `Automations` },
  ];

  const cards = [
    { key: 'zoom', tabs: ['all', 'conferencing'], node: <ZoomIntegrationCard /> },
    { key: 'google', tabs: ['all', 'calendar'], node: <GoogleIntegrationCard /> },
    { key: 'microsoft', tabs: ['all', 'calendar'], node: <MicrosoftCalendarIntegrationCard /> },
    { key: 'salesforce', tabs: ['all', 'crm'], node: <SalesforceIntegrationCard /> },
    { key: 'hubspot', tabs: ['all', 'crm'], node: <HubspotIntegrationCard /> },
    { key: 'slack', tabs: ['all', 'channels'], node: <SlackIntegrationCard /> },
    { key: 'zapier', tabs: ['all', 'automations'], node: <ZapierIntegrationCard /> },
    { key: 'gong', tabs: ['all', 'automations'], node: <GongIntegrationCard /> },
  ];

  integrationBroadcast.onmessage = event => {
    dispatch(fetchUser());

    if (event.data.source && event.data.source === 'NYLAS') {
      dispatch(integrateCalendarResponse(event.data.errorMessage));
    }

    if (event.data.source && event.data.source === 'ZOOM') {
      dispatch(integrateZoomResponse(event.data.errorMessage));
    }

    if (event.data.source && event.data.source === 'SLACK') {
      dispatch(integrateSlackResponse(event.data.errorMessage));
    }

    if (event.data.source && event.data.source === 'SALESFORCE') {
      dispatch(integrateSalesforceResponse(event.data.errorMessage));
    }

    if (event.data.source && event.data.source === 'HUBSPOT') {
      dispatch(integrateHubspotResponse(event.data.errorMessage));
    }
  };

  return (
    <Container>
      <Section
        title="Integrations and connected apps"
        description="Connect, tweak and turbocharge your workflow with third-party applications here!"
      />

      <Tabs
        items={tabs}
        style={{
          width: '100%',
          marginTop: '15px',
          marginBottom: '15px',
          color: '#566976',
        }}
        activeKey={selectedTab}
        onChange={(tab: string) => setSelectedTab(tab)}
      />

      <CardHolder>{cards.filter(c => c.tabs.includes(selectedTab)).map(c => c.node)}</CardHolder>
    </Container>
  );
};

export default Integrations;
