import styled from 'styled-components';
import { Magic } from '../../Icons/Magic';
import { Modal as AntModal, Input } from 'antd';
import { colors } from '../../../../../theme/colors';

const { TextArea } = Input;

const MagicIconHeader = styled(Magic)`
  fill: var(--cool-gray-300, #9ca9b2);
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 24px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.gray[50]};
  padding: 24px;
  gap: 8px;
  border-radius: 8px;
`;

const ChatContainer = styled.div`
  display: flex;
  gap: 12px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const ChatInput = styled(TextArea)`
  flex: 1;

  resize: none !important;
  height: 40px !important;
  overflow: hidden !important;
  align-items: center !important;

  & :focus {
    border: none;
    outline: none;
  }
`;

const Modal = styled(AntModal)`
  padding: 0px;

  .ant-modal-content {
    padding: 0px;
  }
`;

const ActionButtonsContainenr = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const ChatActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 12px;
  padding-right: 24px;
  gap: 10px;
`;

const Description = styled.span`
  font-size: 14px;
  color: rgb(106, 106, 106);
`;

export default {
  MagicIconHeader,
  Header,
  ChatContainer,
  Modal,
  ChatInput,
  Description,
  ActionButtonsContainenr,
  ChatActionsContainer,
  BottomContainer,
  HeaderContainer,
};
