import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const SidebarLeftSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7895 5.22953C12.9273 5.37279 13.0027 5.56491 12.9989 5.76367C12.9952 5.96243 12.9127 6.15157 12.7695 6.28953L8.83152 9.99953L12.7695 13.7095C12.8439 13.777 12.9039 13.8586 12.9461 13.9497C12.9883 14.0408 13.0118 14.1394 13.0152 14.2397C13.0186 14.34 13.0018 14.44 12.9658 14.5337C12.9299 14.6274 12.8755 14.713 12.8059 14.7853C12.7362 14.8576 12.6528 14.9152 12.5605 14.9546C12.4682 14.9941 12.369 15.0146 12.2686 15.015C12.1682 15.0154 12.0688 14.9957 11.9762 14.9569C11.8836 14.9182 11.7997 14.8613 11.7295 14.7895L7.22952 10.5395C7.15693 10.4696 7.0992 10.3857 7.05976 10.2929C7.02032 10.2001 7 10.1003 7 9.99953C7 9.89871 7.02032 9.79893 7.05976 9.70615C7.0992 9.61337 7.15693 9.52949 7.22952 9.45953L11.7295 5.20953C11.8728 5.0717 12.0649 4.99638 12.2637 5.00013C12.4624 5.00388 12.6516 5.08639 12.7895 5.22953Z"
        fill="#9EA9B1"
      />
    </svg>
  );
};
export const SidebarLeft: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={SidebarLeftSVG} {...props} />;
};
