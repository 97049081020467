import React from 'react';
import Styles from './styles';
import { Button, Checkbox, Dropdown, Space, Tooltip } from 'antd';
import {
  ApartmentOutlined,
  CalendarOutlined,
  FolderAddOutlined,
  FolderOpenOutlined,
  LinkOutlined,
  MoreOutlined,
  SubnodeOutlined,
} from '@ant-design/icons';
import { useToast } from '../../../../../../hooks/useToast';
import { InsightSmartClip } from '../../../../../../features/insights/types';
import { capitalize } from '../../../../../../helpers/TextHelper';
import { useLibraries } from '../../../../../../features/libraries/hooks/useLibraries';
import { colors } from '../../../../../../theme/colors';

interface Props {
  item: InsightSmartClip;
  onChange: (value: boolean) => unknown;
  onAddToNewFolder: () => unknown;
}

const { Container, Thumbnail, DetailsContainer, Title, Details, OptionLabel, DetailLabel } = Styles;

const MAX_CHARACTERS = 96;
const MAX_VIEWABLE_CHARACTERS = MAX_CHARACTERS - 3;

const sliceText = (text: string) => {
  let title = text.trim();
  if (title.length >= MAX_VIEWABLE_CHARACTERS) {
    title = `${title.slice(0, MAX_VIEWABLE_CHARACTERS)}...`;
  }

  return `${capitalize(title)}`;
};

const generateUrl = (eventId: string, itemId: string) => {
  const url = `${window.location.origin}/recap/${eventId}?insightId=${itemId}`;
  return url;
};

export const SmartClipItem: React.FC<Props> = ({ onChange, item, onAddToNewFolder }) => {
  const { success } = useToast();
  const { manageInsight } = useLibraries();

  const handleGoToSmartClip = () => {
    window.open(generateUrl(item.eventId, item.id), '_blank');
  };

  const handleCopyShareLink = () => {
    const url = generateUrl(item.eventId, item.id);
    navigator.clipboard.writeText(url);
    success(`Share link copied to clipboard`);
  };

  const handleAddToFolder = (insightId: string) => {
    manageInsight(insightId);
  };

  const handleAddToNewFolder = () => {
    onAddToNewFolder();
  };

  return (
    <Container>
      <Checkbox onChange={ev => onChange(ev.target.checked)} />
      <Thumbnail
        onClick={handleGoToSmartClip}
        src="https://assets.update.ai/smart_clip_thumbnail.png"
      />
      <DetailsContainer>
        <Tooltip title={capitalize(item.text)}>
          <Title onClick={handleGoToSmartClip}>{sliceText(item.text)}</Title>
        </Tooltip>

        <Details>
          <Space>
            <SubnodeOutlined style={{ fill: colors.coolGray['300'] }} />
            <DetailLabel>{`[${item.category}] ${item.subgroup}`}</DetailLabel>
          </Space>
        </Details>
        <Details>
          {item.account && item.account.name && (
            <Space>
              <ApartmentOutlined style={{ fill: colors.coolGray['300'] }} />
              <DetailLabel>{item.account.name}</DetailLabel>
            </Space>
          )}
          <Space>
            <CalendarOutlined style={{ fill: colors.coolGray['300'] }} />
            <DetailLabel>{item.date}</DetailLabel>
          </Space>
        </Details>
      </DetailsContainer>
      <Dropdown
        menu={{
          items: [
            {
              key: 'save-new-folder',
              label: (
                <Space onClick={() => handleAddToNewFolder()}>
                  <FolderAddOutlined />
                  <OptionLabel>Save to new folder</OptionLabel>
                </Space>
              ),
            },
            {
              key: 'add-to-folder',
              label: (
                <Space onClick={() => handleAddToFolder(item.id)}>
                  <FolderOpenOutlined />
                  <OptionLabel>Add to existing folder</OptionLabel>
                </Space>
              ),
            },
            {
              key: 'share',
              label: (
                <Space onClick={handleCopyShareLink}>
                  <LinkOutlined />
                  <OptionLabel>Share this Smart Clip™️</OptionLabel>
                </Space>
              ),
            },
          ],
        }}
      >
        <Button icon={<MoreOutlined />} />
      </Dropdown>
    </Container>
  );
};
