import styled from 'styled-components';

interface Props {
  minusHeight: number;
}

export const LayoutStyled = styled.div<Props>`
  height: 100vh;
  width: 100%;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
`;
