import React from 'react';
import { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { Button, Dropdown, Pagination, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ViewAll } from '../../types';
import { TopTopicsType } from '../../../../../../services/types';

import Styles from './styles';
const { ActionsContainer } = Styles;

interface TopAccountsActionProps {
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortBy: (v: any) => void;
}

const TopTopicsAction: React.FC<PropsWithChildren<TopAccountsActionProps>> = ({
  children,
  label,
  sortBy,
}) => {
  return (
    <ActionsContainer>
      {children}

      <Dropdown
        menu={{
          items: [
            {
              label: '# of Mentions',
              key: 'mentions',
              onClick: () =>
                sortBy({
                  id: 'mentions',
                  label: '# of Mentions',
                }),
            },
            {
              label: '# of Meetings',
              key: 'meetings',
              onClick: () =>
                sortBy({
                  id: 'meetings',
                  label: '# of Meetings',
                }),
            },
            {
              label: 'Total ARR',
              key: 'total_arr',
              onClick: () =>
                sortBy({
                  id: 'total_arr',
                  label: 'Total ARR',
                }),
            },
          ],
        }}
      >
        <Button>
          <Space>
            Sort by: {label} <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      {/* <Button icon={<DownloadOutlined />}>Export to CSV</Button> */}
    </ActionsContainer>
  );
};

interface TopTopicsFiltersProps {
  values: TopTopicsType | null;
  sortType: string;
  pagination?: boolean;
  label?: string;
  children?: ReactElement;
  sortBy: (v: any) => void;
  onPageChange?: (page: number, size: number) => Promise<TopTopicsType>;
  viewAllTopics?: ViewAll;
}

export const TopTopicsFilters: React.FC<TopTopicsFiltersProps> = ({
  children,
  values,
  sortType,
  pagination = false,
  label = 'Topics',
  sortBy,
  viewAllTopics,
  onPageChange,
}) => {
  const [loading, setLoading] = useState(false);
  const [elems, setElems] = useState<TopTopicsType | null>(values);

  const handlePageChange = async (page: number, size: number) => {
    if (!onPageChange) return;
    setLoading(true);
    try {
      const data = await onPageChange(page, size);
      setElems(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setElems(values);
  }, [values]);

  return (
    <>
      <TopTopicsAction sortBy={sortBy} label={sortType}>
        {viewAllTopics && <Button onClick={viewAllTopics}>View all {label}</Button>}
      </TopTopicsAction>

      {children &&
        React.cloneElement(children, {
          data: elems?.topics || [],
          loading: children.props.loading || loading,
        })}

      {pagination && (
        <Pagination
          style={{
            alignSelf: 'flex-end',
          }}
          hideOnSinglePage
          showSizeChanger={false}
          onChange={handlePageChange}
          total={elems?.pagination?.items?.total || 1}
          pageSize={elems?.pagination?.pages?.pageSize || 10}
          current={elems?.pagination?.pages?.currentPage || 1}
        />
      )}
    </>
  );
};
