import Card from '../../../../componentsV2/Card';
import Styles from './style';
import { CardContent } from '../../../../componentsV2/Card/styled';
import { useProfile } from '../../../../features/profile/profile.hook';
import { Quote } from './Quote';
import { Alert } from '../../../../componentsV2/Alert/Alert';
import { ContactCardContent } from '../../../accounts/components/Tabs/Stakeholders/ContactCard/ContactCardContent';
import { Button } from 'antd';
import { coreService } from '../../../../services/core/core-service';
import { useToast } from '../../../../hooks/useToast';

const { Container, Column, Columns } = Styles;

export const Bio: React.FC = () => {
  const { success } = useToast();
  const { profile, isFetching } = useProfile();

  const overview = profile.overview;

  const handleRegenerateBio = () => {
    coreService.generateStakeholderProfile(profile.id).then(() => {
      profile.profileProcessingRequested();
      success(`Stakeholder profile is being processed`);
    });
  };

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Alert
          height="35px"
          iconSize="20px"
          message=""
          variant="info"
          title="This stakeholder bio is generated from the meetings in the 'Meeting History' tab."
        />

        <Button
          disabled={profile.isGeneratingProfile}
          loading={profile.isGeneratingProfile}
          onClick={handleRegenerateBio}
        >
          Regenerate Bio
        </Button>
      </div>

      <Columns>
        <Column>
          {overview ? (
            <Card title="Overview" loading={isFetching}>
              <CardContent>
                <ContactCardContent
                  meetings={overview.totalMeetings}
                  attendees={overview.totalMeetings - overview.noShows}
                  lastMeeting={overview.lastMeetingDate}
                  noShows={overview.noShows}
                  percentage={overview.talkRatio}
                  name={profile.name || profile.email}
                  email={profile.email}
                  key={profile.id}
                />
              </CardContent>
            </Card>
          ) : (
            <></>
          )}

          <Card title="About" loading={isFetching}>
            <CardContent>
              <h1>Role</h1>
              <p>{profile.profile?.role.value || 'No data'}</p>

              <h1>Personal</h1>
              <p>{profile.profile?.personal.value || 'No data'}</p>
            </CardContent>
          </Card>
        </Column>

        <Column>
          <Card title="Views" loading={isFetching}>
            <CardContent>
              <h1>Positive Feedback</h1>
              <p>{profile.profile?.positiveFeedback.value || 'No data'}</p>

              <h1>Negative Feedback</h1>
              <p>{profile.profile?.negativeFeedback.value || 'No data'}</p>

              <h1>Product or Service Request</h1>
              <p>{profile.profile?.requests.value || 'No data'}</p>
            </CardContent>
          </Card>

          <Card title="Profile" loading={isFetching}>
            <CardContent>
              <h1>Communication Style</h1>
              <p>{profile.profile?.communicationStyle.value || 'No data'}</p>

              <h1>Decision Making Style</h1>
              <p>{profile.profile?.decisionMakingStyle.value || 'No data'}</p>

              {/*
              <h1>Psychological Profile</h1>
          <p>{profile.profile?.psychologicalProfile.value || ''}</p>*/}
            </CardContent>
          </Card>
        </Column>

        <Column>
          <Card title="Key Quotes" loading={isFetching}>
            <CardContent>
              {profile.profile?.keyQuotes.length > 0 ? (
                profile.profile?.keyQuotes.map((quote, idx) => {
                  return (
                    <Quote key={`quote-card-${idx}`} title={quote.event.title} text={quote.quote} />
                  );
                })
              ) : (
                <p>No data</p>
              )}
            </CardContent>
          </Card>
        </Column>
      </Columns>
    </Container>
  );
};
