import React, { ReactElement } from 'react';
import Styles from './styles';
import { Tooltip } from 'antd';

const { NotMappedText, CrmPropertyValue } = Styles;

interface Props {
  crmPropertyValue: string | null | ReactElement;
}

export const PropertyValue: React.FC<Props> = ({ crmPropertyValue }) => {
  if (!crmPropertyValue) {
    return (
      <Tooltip title="Requires CRM Setup to display the value. ">
        <NotMappedText
          onClick={() => window.open('/settings?tab=my-organization#CRM-SETUP-ID', '_blank')}
        >
          Property Not Mapped
        </NotMappedText>
      </Tooltip>
    );
  }

  return <CrmPropertyValue>{crmPropertyValue}</CrmPropertyValue>;
};
