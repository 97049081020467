import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;
  height: calc(100vh - 300px);
  width: 100%;
  min-height: 500px;
  overflow-y: auto;
`;

const ContactGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  gap: 16px;
`;

export default {
  Container,
  ContactGroup,
};
