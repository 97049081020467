import { AccountOverviewProvider } from '../../features/account/account-overview/account-overview.context';
import { AccountSearchProvider } from '../../features/account/account-search/account-search.context';
import { AccountsPage } from './page';

export const Accounts: React.FC = () => {
  return (
    <AccountSearchProvider searchId="accounts-page">
      <AccountOverviewProvider>
        <AccountsPage />
      </AccountOverviewProvider>
    </AccountSearchProvider>
  );
};
