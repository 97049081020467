import React, { useState } from 'react';
import Styled from './style';
import { Connect } from '../../../../../../../features/shared/components/Icons/Connect';
import { Disconnect } from '../../../../../../../features/shared/components/Icons/Disconnect';
import { Alert } from '../../../../../../../componentsV2/Alert/Alert';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { useOrganizationDetails } from '../../../../../../../features/organizations/hooks/useOrganizationDetails';
import { useToast } from '../../../../../../../hooks/useToast';
import { extractError } from '../../../../../../../utils/api';
import { useIntegrationRequest } from '../../../../../../../features/integrations/hooks/useIntegrationRequest';
import {
  fetchUser,
  integrateHubspotResponse,
  integrateSalesforceResponse,
} from '../../../../../../../redux/slices/sessionSlice';
import { integrationBroadcast } from '../../../../../../../App';
import { Store } from '../../../../../../../redux/typings/store';
import { OptionalTooltipWrapper } from '../../../../../../../componentsV2/OptionalTooltipWrapper';
import { ConnectCRMModal } from './ConnectCRMModal';
import { DisconnectCRMModal } from './DisconnectCRMModal';
import { SubscribeToTeamsPlanLink } from '../../../../../../../componentsV2/UpgradeToPro';
import useFeatures from '../../../../../../../hooks/useFeatures';

const {
  Container,
  Header,
  LearnMore,
  Text,
  Title,
  ActionButton,
  Footer,
  CheckBoxInfo,
  CRMConnectedStatus,
} = Styled;

export interface Props {
  disableUserInteractions: boolean;
}

const selectHubspotIntegrationId = (store: Store) =>
  store.session.user?.configuration.integration.hubspot.id;

const selectSalesforceIntegrationId = (store: Store) =>
  store.session.user?.configuration.integration.salesforce.id;

export const CRMOrganizationSection: React.FC<Props> = ({ disableUserInteractions }) => {
  const dispatch = useDispatch();

  const [shouldShowConnectModal, setShouldShowConnectModal] = useState(false);
  const [shouldShowDisconnectModal, setShouldShowDisconnectModal] = useState(false);
  const salesforceIntegrationId = useSelector(selectSalesforceIntegrationId);
  const hubspotIntegrationid = useSelector(selectHubspotIntegrationId);

  const { success, error } = useToast();

  const features = useFeatures();
  const availableFeature = features.hubspotIntegration || features.salesforceIntegration;

  const hasCrmConnected = Boolean(salesforceIntegrationId) || Boolean(hubspotIntegrationid);

  const {
    crmData,
    hasPrimaryProfileConnected,
    updateCrmSettings,
    disconnectPrimaryProfile,
    fetch: fetchOrganization,
  } = useOrganizationDetails();

  const [isLoading, setIsLoading] = useState(false);

  const handleCheckboxChange = async (newCheckBoxState: boolean) => {
    try {
      await updateCrmSettings({ pushToCrmWithPrimaryProfile: newCheckBoxState });
      success('Workspace updated successfully');
    } catch (e) {
      const errorMessage = extractError(e);
      error(errorMessage);
    }
  };

  const {
    isIntegrating: isIntegratingSalesforce,
    requestIntegration: requestIntegrationSalesforce,
  } = useIntegrationRequest({ source: 'SALESFORCE', id: selectSalesforceIntegrationId });

  const { isIntegrating: isIntegratingHubspot, requestIntegration: requestIntegrationHubspot } =
    useIntegrationRequest({ source: 'HUBSPOT', id: selectHubspotIntegrationId });

  integrationBroadcast.onmessage = event => {
    dispatch(fetchUser());
    fetchOrganization();
    if (event.data.source && event.data.source === 'SALESFORCE') {
      dispatch(integrateSalesforceResponse(event.data.errorMessage));
    }

    if (event.data.source && event.data.source === 'HUBSPOT') {
      dispatch(integrateHubspotResponse(event.data.errorMessage));
    }
  };

  const ConnectPrimaryProfileButton = (
    <OptionalTooltipWrapper
      display={!disableUserInteractions && hasCrmConnected}
      value={
        <span>
          To connect a Primary Profile, please disconnect your existing CRM connection from the{' '}
          <a href="/settings?tab=integrations" target="_blank">
            Integrations
          </a>{' '}
          tab and then re-connect your CRM account here.
        </span>
      }
    >
      <ActionButton
        onClick={() => setShouldShowConnectModal(true)}
        disabled={disableUserInteractions || hasCrmConnected || !availableFeature}
        loading={isLoading}
      >
        <Connect /> Connect Primary Profile{' '}
      </ActionButton>
    </OptionalTooltipWrapper>
  );

  const DisconnectPrimaryProfileButton = (
    <ActionButton
      onClick={() => setShouldShowDisconnectModal(true)}
      disabled={disableUserInteractions || !availableFeature}
      loading={isLoading}
    >
      <Disconnect /> Disconnect Primary Profile{' '}
    </ActionButton>
  );

  const AlertMessage = (
    <span>
      Workspace Owner or Admins must do this first before Members can connect their CRM accounts to
      UpdateAI.
    </span>
  );

  const NoPrimaryProfileSubtitle = (
    <Text>
      This unified CRM integration ensures all workspace members are associated with the same CRM
      instance, and is used as a fallback in case a member of your workspace has not connected their
      CRM account. <LearnMore>Learn More</LearnMore>
    </Text>
  );

  const PrimaryProfileMessage = hasPrimaryProfileConnected ? (
    <span>
      The primary profile is <b>{crmData?.primaryProfile}</b>
    </span>
  ) : (
    NoPrimaryProfileSubtitle
  );

  const handleConnectToCRM = async (selectedCRM: string) => {
    setIsLoading(true);
    try {
      if (selectedCRM === 'SALESFORCE') {
        await requestIntegrationSalesforce({ intendsToBePrimaryProfile: true });
      }
      if (selectedCRM === 'HUBSPOT') {
        await requestIntegrationHubspot({ intendsToBePrimaryProfile: true });
      }
    } catch (e) {
      error((e as Error).message);
    } finally {
      setIsLoading(false);
      setShouldShowConnectModal(false);
    }
  };

  const handleDisconnectToCRM = async () => {
    setIsLoading(true);
    try {
      await disconnectPrimaryProfile();
      await fetchOrganization();
    } catch (e) {
      error((e as Error).message);
    } finally {
      setIsLoading(false);
      setShouldShowDisconnectModal(false);
    }
  };

  return (
    <Container>
      <ConnectCRMModal
        isLoading={isLoading}
        isOpen={shouldShowConnectModal}
        onCancel={() => setShouldShowConnectModal(false)}
        onClose={() => setShouldShowConnectModal(false)}
        onConfirm={handleConnectToCRM}
      />

      <DisconnectCRMModal
        isLoading={isLoading}
        isOpen={shouldShowDisconnectModal}
        onCancel={() => setShouldShowDisconnectModal(false)}
        onClose={() => setShouldShowDisconnectModal(false)}
        onConfirm={handleDisconnectToCRM}
      />

      {!hasPrimaryProfileConnected && (
        <div style={{ alignSelf: 'center', width: '100%' }}>
          <Alert variant="info" title="" message={AlertMessage} width="100%" />
        </div>
      )}

      <Title>
        Primary CRM Profile Status:{' '}
        <CRMConnectedStatus connected={hasPrimaryProfileConnected}>
          {hasPrimaryProfileConnected ? 'Connected' : 'Not Connected'}
        </CRMConnectedStatus>
      </Title>

      <Header>{PrimaryProfileMessage}</Header>

      {/*hasPrimaryProfileConnected && (
        <>
          <Spacing>
            Properties list:{' '}
            {crmData.properties.isSynchronizing ? (
              <WorkingLabel>Pulling</WorkingLabel>
            ) : (
              <SuccessLabel>All properties pulled</SuccessLabel>
            )}
          </Spacing>

          <Spacing>
            Accounts list:{' '}
            {crmData.accountsList.isSynchronizing ? (
              <WorkingLabel>Pulling</WorkingLabel>
            ) : (
              <SuccessLabel>All accounts pulled</SuccessLabel>
            )}
            {crmData.accountsList.lastSynchronizedAt
              ? ` (Last synchronized at: ${new Date(
                  crmData.accountsList.lastSynchronizedAt,
                ).toLocaleString()})`
              : ''}
          </Spacing>
        </>
              )*/}

      <OptionalTooltipWrapper
        display={disableUserInteractions || !availableFeature}
        value={
          !availableFeature ? (
            <SubscribeToTeamsPlanLink title="Upgrade to a team plan to push your insights to your CRM." />
          ) : (
            'Only the Owner and Admins of the organization can change this setting.'
          )
        }
      >
        <div>
          {!hasPrimaryProfileConnected
            ? ConnectPrimaryProfileButton
            : DisconnectPrimaryProfileButton}
        </div>
      </OptionalTooltipWrapper>

      {hasPrimaryProfileConnected && (
        <Footer>
          <OptionalTooltipWrapper
            display={disableUserInteractions}
            value="Only the Owner and Admins of the organization can change this setting."
          >
            <Checkbox
              onChange={(e: CheckboxChangeEvent) => handleCheckboxChange(e.target?.checked)}
              checked={crmData?.pushToCrmWithPrimaryProfile}
              disabled={disableUserInteractions}
            />{' '}
          </OptionalTooltipWrapper>

          <CheckBoxInfo>
            Push meeting recaps to the CRM under this profile for all members of my workspace that
            do not have their profile connected.
          </CheckBoxInfo>
        </Footer>
      )}
    </Container>
  );
};
