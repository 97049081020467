import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-right: 1px solid #e4e4e4;
  height: 100%;
  padding: 0px;
`;

export const Nav = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0px 0px;
  gap: 24px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 24px;
  cursor: pointer;
  width: 100%;
`;

export const Organization = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 16px;
`;

export const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
  gap: 4px;
  width: 100%;
  height: 100%;

  .ant-menu-item {
    padding: 0px;
    margin: 12px 0px;
  }

  .ant-menu-title-content {
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 950;
    font-size: 14px;
    line-height: 24px;
    color: #282828;
  }

  :hover {
    .ant-menu-item {
      color: #ff6c54;
    }
  }

  .ant-menu-collapsed-tooltip {
    display: none;
  }
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
  padding: 0px 16px 32px;
  gap: 8px;
`;

export const Support = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 100%;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #e4e4e4;
`;

export const FeatureCard = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px 24px;
  gap: 16px;
  isolation: isolate;

  background: #f8f8f8;
  border-radius: 6px;
`;

export const Branding = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
`;

export const OrganizationControl = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 8px;
  gap: 8px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  cursor: pointer;
`;

export const OrganizationInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const OrganizationName = styled.div`
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #282828;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const OrganizationDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
`;

export const Plan = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.015em;
  color: #a6a6a6;
`;

export const NavItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 105px;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  cursor: pointer;

  color: #a6a6a6;

  :hover {
    color: #ff6c54;
    background-color: #fff0ee;
  }

  :hover div div {
    color: #ff6c54;
  }

  :hover div div svg {
    color: #ff6c54;
    stroke: #ff6c54;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
`;

export const NavItemText = styled.div`
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 950;
  font-size: 14px;
  line-height: 24px;
  color: #282828;
`;

export const OrganizationLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  background: #ebe7ff;
  border: 1px solid #bcaeff;
  border-radius: 4px;
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #5e16eb;
`;
