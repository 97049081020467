import styled from 'styled-components';

const PropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PropertyName = styled.div`
  color: var(--Cool-Gray-400, #566976);
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
`;

const ActionText = styled.span`
  color: #ff6b54;
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  text-decoration-line: underline;
  cursor: pointer;
`;

export default {
  PropertyName,
  PropertyWrapper,
  ActionText,
};
