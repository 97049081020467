import styled from 'styled-components';

const MappingGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const GroupTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  -webkit-letter-spacing: 0em;
  -moz-letter-spacing: 0em;
  -ms-letter-spacing: 0em;
  letter-spacing: 0em;
`;

export default {
  MappingGroup,
  GroupTitle,
};
