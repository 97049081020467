import React, { useEffect, useState } from 'react';
import { TopAccountRecord, TopAccountsTable } from './components/TopAccountsTable';
import { Account, TopAccountsType } from '../../../../services/types';
import { PIModal } from '../AllTopicsModal';
import { TopicDetailModal } from '../TopicDetailModal';
import { TopAccountFilters } from './components/TopAccountsFilters';
import { OnClickType, SelectedTopic } from './types';
import { useDashboardFilters } from '../../../../features/shared/dashboard-filters/dashboard-filters.hooks';
import { DateRangeFilterStore } from '../../../../features/shared/dashboard-filters/types';
import { coreService } from '../../../../services/core/core-service';
import { DashboardFilterPovider } from '../../../../features/shared/dashboard-filters/dashboard-filters.context';
import { filtersToQueryObject } from '../../utils';
import Card from '../../../../componentsV2/Card';

import Styles from './styles';
import { SortByType } from '../../types';
const { Contents } = Styles;

interface TableProps {
  elems?: Account[];
  loading?: boolean;
  onClick: OnClickType;
}

const Table: React.FC<TableProps> = ({ elems, loading, onClick }) => {
  return (
    <TopAccountsTable
      loading={loading}
      onTopicClicked={onClick}
      data={(elems || []).map(elem => {
        const advocacy = elem.topTopics.find(e => e.type === 'Advocacy');
        const risk = elem.topTopics.find(e => e.type === 'Risks');
        const productfeedback = elem.topTopics.find(e => e.type === 'Product Feedback');
        const growthOp = elem.topTopics.find(e => e.type === 'Growth Opportunities');

        const item: TopAccountRecord = {
          account: {
            id: elem.id,
            name: elem.name || '',
            provider: elem.provider || '',
          },
          arr: elem.arr || 0,
          renewalDate: elem.renewalDate ? new Date(elem.renewalDate) : null,
        };

        if (advocacy) {
          item.topAdvocacy = {
            id: advocacy.id,
            name: advocacy.name,
            mentions: advocacy.mentions,
          };
        }

        if (risk) {
          item.topRisk = {
            id: risk.id,
            name: risk.name,
            mentions: risk.mentions,
          };
        }

        if (productfeedback) {
          item.topProductFeedback = {
            id: productfeedback.id,
            name: productfeedback.name,
            mentions: productfeedback.mentions,
          };
        }

        if (growthOp) {
          item.topGrowth = {
            id: growthOp.id,
            name: growthOp.name,
            mentions: growthOp.mentions,
          };
        }

        return item;
      })}
    />
  );
};

interface TopAccountsSectionProps {
  data: TopAccountsType | null;
  sortBy: SortByType;
  loading?: boolean;
  setSortBy: (sortBy: SortByType) => void;
}

export const TopAccountsSection: React.FC<TopAccountsSectionProps> = ({
  data,
  loading,
  sortBy,
  setSortBy,
}) => {
  const [modalSortBy, modalSetSortBy] = useState({
    id: 'arr',
    label: 'ARR',
  });

  const [values, setValues] = useState(data);
  const [modalValues, modalSetValues] = useState(data);
  const [fetching, setFetching] = useState(loading);
  const [showAllAccounts, setShowAllAccounts] = useState(false);
  const [showTopics, setShowTopics] = useState<SelectedTopic | null>(null);

  const spinner = fetching || loading;

  const {
    filters,
    getListFilterOptions,
    getDateFilterOptions,
    getAccountFilterOptions,
    getSelectFilterOptions,
    getNumberRangeFilterOptions,
  } = useDashboardFilters();
  const dateRange = (filters['dateRange'] as DateRangeFilterStore)?.value?.interval;

  useEffect(() => {
    setValues(data);
    modalSetValues(data);
  }, [data]);

  useEffect(() => {
    setFetching(true);
    coreService
      .getTopAccounts(
        dateRange[0]!.toISOString(),
        dateRange[1]!.toISOString(),
        1,
        10,
        sortBy.id,
        filtersToQueryObject(filters),
      )
      .then(d => setValues(d))
      .finally(() => setFetching(false));
  }, [sortBy]);

  useEffect(() => {
    setFetching(true);
    coreService
      .getTopAccounts(
        dateRange[0]!.toISOString(),
        dateRange[1]!.toISOString(),
        1,
        10,
        modalSortBy.id,
        filtersToQueryObject(filters),
      )
      .then(d => modalSetValues(d))
      .finally(() => setFetching(false));
  }, [modalSortBy]);

  return (
    <Card
      title="Top Accounts"
      subtitle="This tool lets you analyze data exclusively from your external meetings, providing deeper insights into client interactions."
      learnMoreLink="https://google.com"
    >
      <Contents>
        <TopAccountFilters
          sortType={sortBy.label}
          sortBy={setSortBy}
          values={values}
          viewAllAccounts={() => setShowAllAccounts(true)}
        >
          <Table onClick={setShowTopics} loading={spinner} />
        </TopAccountFilters>
      </Contents>

      <PIModal
        open={showAllAccounts}
        onClose={() => setShowAllAccounts(false)}
        label="All accounts"
      >
        <TopAccountFilters
          sortType={modalSortBy.label}
          sortBy={modalSetSortBy}
          values={modalValues}
          pagination={(modalValues?.pagination?.pages?.total || 0) > 1}
          onPageChange={async (page, size) => {
            return await coreService.getTopAccounts(
              dateRange[0]!.toISOString(),
              dateRange[1]!.toISOString(),
              page,
              size,
              modalSortBy.id,
            );
          }}
        >
          <Table
            onClick={v => {
              setShowAllAccounts(false);
              setShowTopics(v);
            }}
            loading={spinner}
          />
        </TopAccountFilters>
      </PIModal>

      <DashboardFilterPovider
        filters={{
          dateRange: {
            type: 'dateRange',
            config: {
              value: 'custom',
              interval: getDateFilterOptions('dateRange'),
            },
          },
          account: {
            type: 'account',
            config: {
              selected: getAccountFilterOptions('account'),
            },
          },
          geography: {
            type: 'list',
            componentProps: {
              label: 'Geography',
            },
            config: {
              allowNoValueSet: true,
              options: getListFilterOptions('geography'),
            },
          },
          industry: {
            type: 'list',
            componentProps: {
              label: 'Industry',
            },
            config: {
              allowNoValueSet: true,
              options: getListFilterOptions('industry'),
            },
          },
          revenueSegment: {
            type: 'list',
            componentProps: {
              label: 'Revenue Segment',
            },
            config: {
              allowNoValueSet: true,
              options: getListFilterOptions('revenueSegment'),
            },
          },
          dealStage: {
            type: 'list',
            componentProps: {
              label: 'Deal Stage',
            },
            config: {
              allowNoValueSet: true,
              options: getListFilterOptions('dealStage'),
            },
          },
          lifecycleStage: {
            type: 'list',
            componentProps: {
              label: 'Lifecycle Stage',
            },
            config: {
              allowNoValueSet: true,
              options: getListFilterOptions('lifecycleStage'),
            },
          },
          arr: {
            type: 'numberRange',
            componentProps: {
              label: 'ARR',
            },
            config: {
              interval: getNumberRangeFilterOptions('arr'),
            },
          },
          daysUntilRenewal: {
            type: 'select',
            config: {
              defaultValue: getSelectFilterOptions('select'),
              options: [
                {
                  value: -1,
                  label: 'All Days Until Renewal',
                },
                {
                  value: 30,
                  label: '30 Days Until Renewal',
                },
                {
                  value: 60,
                  label: '60 Days Until Renewal',
                },
                {
                  value: 90,
                  label: '90 Days Until Renewal',
                },
                {
                  value: 120,
                  label: '120 Days Until Renewal',
                },
              ],
            },
            componentProps: {
              label: 'Days Until Renewal',
              style: { width: 200 },
            },
          },
          insightType: {
            type: 'select',
            config: {
              defaultValue: 'Risks',
              options: [
                {
                  value: 'Risks',
                  label: 'Risks',
                },
                {
                  value: 'Product Feedback',
                  label: 'Product Feedback',
                },
                {
                  value: 'Advocacy',
                  label: 'Advocacy',
                },
                {
                  value: 'Growth Opportunities',
                  label: 'Growth Opportunities',
                },
              ],
            },
            componentProps: {
              label: 'Insight Type',
              style: { width: 200 },
            },
          },
        }}
      >
        <TopicDetailModal
          currentFilters={filters}
          open={!!showTopics}
          onClose={() => setShowTopics(null)}
          topic={{
            id: showTopics?.topicId || '',
            name: showTopics?.topicName || '',
          }}
        />
      </DashboardFilterPovider>
    </Card>
  );
};
