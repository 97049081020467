import React from 'react';
import Styles from './styles';

const { Label } = Styles;

interface Props {
  name: string;
  onClick?: () => void;
}

export const TopicLabel: React.FC<Props> = ({ name, onClick }) => {
  return <Label onClick={onClick}>{name}</Label>;
};
