import React from 'react';
import Styles from './styles';
import ExtendTrialColumn from './components/ExtendTrialColumn';
import RequestUpgradeColumn from './components/RequestUpgradeColumn';

const { Container, Divider, Modal } = Styles;

interface Props {
  open: boolean;
  onClose: () => unknown;
}

const ExtendTrial: React.FC<Props> = ({ open, onClose }) => {
  return (
    <Modal footer={null} open={open} onCancel={onClose} closable>
      <Container>
        <RequestUpgradeColumn />
        <Divider />
        <ExtendTrialColumn />
      </Container>
    </Modal>
  );
};

export default ExtendTrial;
