import { Table } from '../../../../../../componentsV2/Table';
import { AccountLabel } from '../../../../../../features/shared/components/AccountLabel';
import { format } from 'date-fns';

export type TopAccountsItemType = {
  id: string;
  name: string;
  mentions: number;
};

export interface TopAccountRecord {
  account: {
    id: string;
    name: string;
    provider: string;
  };
  arr: number | null;
  renewalDate: Date | null;
}

export interface Props {
  elems?: TopAccountRecord[];
  loading?: boolean;
}

export const TopAccountsCondensedTable: React.FC<Props> = ({ elems: data, loading = false }) => {
  return (
    <Table
      pagination={false}
      loading={loading}
      columns={[
        {
          title: 'Account',
          key: 'accounts',
          render: value => {
            return <AccountLabel account={value} />;
          },
        },
        {
          title: 'ARR',
          key: 'arr',
          dataIndex: 'arr',
          render: value => `$ ${value?.arr || 0}`,
        },
        {
          title: 'Renewal',
          key: 'renewal',
          dataIndex: 'renewalDate',
          render: value => {
            if (!value) return `-`;

            const differenceInDays = (value.getTime() - new Date().getTime()) / 1000 / 3600 / 24;
            if (differenceInDays < 0) {
              return `Today`;
            }

            if (differenceInDays <= 120) {
              return `In ${Math.floor(differenceInDays)} days`;
            }

            return format(value, 'yyyy-MM-dd');
          },
        },
        {
          title: '# of Mentions',
          key: 'mentions',
          dataIndex: 'mentions',
          render: value => `${value}`,
        },
        {
          title: '# of Meetings',
          key: 'meetings',
          dataIndex: 'meetings',
          render: value => `${value}`,
        },
      ]}
      data={data || []}
    />
  );
};
