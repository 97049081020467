import { useEffect, useMemo, useState } from 'react';
import { Modal } from '../../../componentsV2/Modal';
import { omit } from 'lodash';
import styled from 'styled-components';
import { Flex, Select } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../services/core/core-service';
import { Table, TableColumnDefinition } from '../../../componentsV2/Table';
import { Topic } from '../../../services/types';
import { TopicLabel } from '../../../pages/portfolio-insights/components/TopicLabel';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 700px;
  overflow-y: auto;
`;

interface Props {
  open: boolean;
  onClose?: () => unknown;
  onTopicClicked: (topic: { id: string; name: string }) => unknown;
  filters: {
    from: string;
    to: string;
    geography?: string[];
    renewalIn?: number;
    lifecycleStage?: string[];
    industry?: string[];
    dealStage?: string[];
    contractValueMin?: number;
    contractValueMax?: number;
    accountIds?: string[];
    insightType?: string;
  };
  orderBy?: string;
  columns: {
    accounts: boolean;
    mentions: boolean;
    meetings: boolean;
    arr: boolean;
  };
}

export const ViewAllTopicsModal: React.FC<Props> = props => {
  const { filters } = props;
  const [isOpen, setIsOpen] = useState(props.open);
  const [orderBy, setOrderBy] = useState('mentions');
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (props.orderBy) {
      setOrderBy(props.orderBy);
    }
  }, [props.orderBy]);

  useEffect(() => {
    setIsOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    if (props.onClose) props.onClose();
    setIsOpen(false);
  };

  const orderByOptions = useMemo(() => {
    const options: any[] = [];

    if (props.columns.accounts) {
      options.push({ value: 'accounts', label: '# of Accounts' });
    }

    if (props.columns.mentions) {
      options.push({ value: 'mentions', label: '# of Mentions' });
    }

    if (props.columns.meetings) {
      options.push({ value: 'meetings', label: '# of Meetings' });
    }

    if (props.columns.arr) {
      options.push({ value: 'arr', label: 'Total ARR' });
    }

    return options;
  }, [props.columns]);

  const tableColumns = useMemo(() => {
    const columns: TableColumnDefinition<Topic>[] = [
      {
        title: 'Topics',
        key: 'topic',
        dataIndex: 'topic',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (v, record) => {
          return (
            <TopicLabel
              name={record.name}
              onClick={() => {
                props.onTopicClicked({
                  id: record.id,
                  name: record.name,
                });
              }}
            />
          );
        },
      },
    ];

    if (props.columns.accounts) {
      columns.push({
        title: '# Accounts',
        key: 'accounts',
        dataIndex: 'accounts',
        sorter: (a, b) => a.accounts - b.accounts,
      });
    }

    if (props.columns.mentions) {
      columns.push({
        title: '# Mentions',
        key: 'mentions',
        dataIndex: 'mentions',
        sorter: (a, b) => a.mentions - b.mentions,
      });
    }

    if (props.columns.meetings) {
      columns.push({
        title: '# Meetings',
        key: 'meetings',
        dataIndex: 'meetings',
        sorter: (a, b) => a.meetings - b.meetings,
      });
    }

    if (props.columns.arr) {
      columns.push({
        title: 'Total ARR',
        key: 'total-arr',
        dataIndex: 'totalArr',
        sorter: (a, b) => a.totalArr - b.totalArr,
        render: value => `$ ${value}`,
      });
    }

    return columns;
  }, [props.columns]);

  const { data: topicData, isFetching } = useQuery({
    queryKey: [
      filters.from,
      filters.to,
      filters.geography,
      filters.dealStage,
      filters.insightType,
      filters.renewalIn,
      filters.industry,
      filters.accountIds,
      filters.lifecycleStage,
      filters.contractValueMax,
      filters.contractValueMin,
      orderBy,
      page,
    ],
    queryFn: async () => {
      const response = await coreService.getTopTopics(
        filters.from,
        filters.to,
        page,
        10,
        orderBy,
        omit(filters, ['from', 'to']),
      );
      return response;
    },
  });

  return (
    <Modal hideFooter open={isOpen} width={1296} title={'View All Topics'} onClose={handleClose}>
      <Container>
        <Flex align="center" gap={4}>
          <span>Sort by:</span>
          <Select value={orderBy} options={orderByOptions} onChange={value => setOrderBy(value)} />
        </Flex>
        <Table
          pagination={{
            pageSize: 10,
            current: page,
            total: topicData?.pagination.pages.total,
            onChange: pageNumber => setPage(pageNumber),
          }}
          loading={isFetching}
          columns={tableColumns}
          data={topicData?.topics || []}
        />
      </Container>
    </Modal>
  );
};
