import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const OpenNewSVG = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.66675 11.3337L11.3334 4.66699M11.3334 4.66699H4.66675M11.3334 4.66699V11.3337"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const OpenNew: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={OpenNewSVG} {...props} />;
};
