import React, { PropsWithChildren } from 'react';
import { Button } from 'antd';
import Styles from './styles';
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const {
  Header,
  Footer,
  Title,
  Actions,
  Contents,
  HelpArticleContainer,
  HelpArticleLabel,
  AntModal,
} = Styles;

interface Props {
  title: string;
  open: boolean;
  hideFooter?: boolean;
  onClose: () => unknown;
  helpArticle?: {
    label: string;
    url: string;
  };
  primaryAction?: {
    label: string;
    loading?: boolean;
    disabled?: boolean;
    onClick: () => unknown;
    danger?: boolean;
  };
  secondaryAction?: {
    label: string;
    loading?: boolean;
    disabled?: boolean;
    onClick: () => unknown;
    danger?: boolean;
  };
  width?: number;
}

export type ModalProps = PropsWithChildren<Props>;

export const Modal: React.FC<ModalProps> = ({
  open,
  title,
  helpArticle,
  secondaryAction,
  onClose,
  primaryAction,
  children,
  hideFooter,
  width = 752,
}) => {
  const handleArticledClicked = () => {
    if (helpArticle) {
      window.open(helpArticle.url, '_blank');
    }
  };

  const handleCloseClicked = () => {
    onClose();
  };

  const showFooter = typeof hideFooter === 'undefined' || hideFooter === false;

  return (
    <AntModal
      width={width}
      centered
      open={open}
      style={{ padding: '0px' }}
      closable={false}
      title={
        <Header>
          <Title>{title}</Title>
          <CloseOutlined
            onClick={handleCloseClicked}
            style={{ color: '#C3C9CE', cursor: 'pointer' }}
          />
        </Header>
      }
      footer={
        showFooter && (
          <Footer>
            <HelpArticleContainer>
              {helpArticle && (
                <>
                  <QuestionCircleOutlined />

                  <HelpArticleLabel onClick={handleArticledClicked}>
                    {helpArticle.label}
                  </HelpArticleLabel>
                </>
              )}
            </HelpArticleContainer>
            <Actions>
              {secondaryAction && (
                <Button
                  danger={secondaryAction.danger}
                  type={'default'}
                  onClick={secondaryAction.onClick}
                  loading={secondaryAction.loading}
                  disabled={secondaryAction.disabled}
                >
                  {secondaryAction.label}
                </Button>
              )}
              {primaryAction && (
                <Button
                  danger={primaryAction.danger}
                  type={'primary'}
                  loading={primaryAction.loading}
                  disabled={primaryAction.disabled}
                  onClick={primaryAction.onClick}
                >
                  {primaryAction.label}
                </Button>
              )}
            </Actions>
          </Footer>
        )
      }
    >
      <Contents>{children}</Contents>
    </AntModal>
  );
};
