import React from 'react';
import { Badge, Space } from 'antd';
import { format } from 'date-fns';
import { AccordionCard } from '../../../AccordionCard';
import { AccountLabel } from '../../../../../../../../features/shared/components/AccountLabel';
import { colors } from '../../../../../../../../theme/colors';

import Styles from './styles';
const { MeetingDate, MeetingTitle } = Styles;

interface Props {
  id: string;
  account: {
    id: string;
    name: string;
    provider: string;
  };
  title: string;
  scheduledStartDate: Date;
  insights: {
    id: string;
    type: string;
    text: string;
  }[];
}

export const Meeting: React.FC<Props> = ({ id, account, title, scheduledStartDate, insights }) => {
  return (
    <AccordionCard
      open={true}
      header={
        <Space>
          <AccountLabel account={account} />
          <MeetingTitle>{title}</MeetingTitle>
          <MeetingDate>{format(scheduledStartDate, 'MMM dd @ hh aa')}</MeetingDate>
        </Space>
      }
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        {insights.map(insight => (
          <a
            style={{
              color: colors.gray[600],
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
            href={`/recap/${id}?insightId=${insight.id}`}
            target="_blank"
            key={insight.id}
          >
            <div
              style={{
                backgroundColor: colors.gray[200],
                padding: '2px 5px',
                borderRadius: '10px',
                color: colors.gray[700],
                textDecoration: 'none',
                height: '22px',
              }}
            >
              {insight.type}
            </div>
            {insight.text}
          </a>
        ))}
      </div>
    </AccordionCard>
  );
};
