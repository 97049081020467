import React, { useEffect, useMemo, useState } from 'react';
import { useAccountOverview } from '../../../../../features/account/account-overview/account-overview.hook';
import { Checkbox, Flex, Space, Switch } from 'antd';
import {
  EventActionItem,
  EventWithActionItems,
} from '../../../../../features/account/account-action-items/types';
import { useAccountActionItems } from '../../../../../features/account/account-action-items/use-account-action-items.hook';
import styled from 'styled-components';
import { Input } from 'antd';
import { useDebounce } from 'use-debounce';
import { coreService } from '../../../../../services/core/core-service';
import { DateSelector } from '../../../../../features/shared/components/DateSelector';
import { addDays, format } from 'date-fns';
import { MeetingTitleLink } from '../../../../../components/common/MeetingTitleLink';
import { UpdateLoader } from '../../../../../componentsV2/UpdateLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';

const { Search } = Input;

interface Props {
  event: EventWithActionItems;
}

const MeetingDetail = styled.div`
  color: var(--Cool-Gray-400, #566976);
  font-family: 'Cera Pro';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 120% */
`;

const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  width: 100%;
  height: 100%;
`;

const CompletedLabel = styled.span`
  color: #282828;
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
`;

const ActionItem: React.FC<{ actionItem: EventActionItem & { eventId: string } }> = ({
  actionItem,
}) => {
  const [isWorking, setIsWorking] = useState(false);
  const [isChecked, setIsChecked] = useState(actionItem.completed);

  useEffect(() => {
    setIsChecked(actionItem.completed);
  }, [actionItem]);

  const handleValueChange = async (value: boolean) => {
    setIsChecked(value);
    setIsWorking(true);
    try {
      if (value) {
        await coreService.hideActionItem(actionItem.eventId, actionItem.id);
      } else {
        await coreService.showActionItem(actionItem.eventId, actionItem.id);
      }
    } catch (error) {
    } finally {
      setIsWorking(false);
    }

    setTimeout(() => {
      setIsWorking(false);
    }, 2000);
  };
  return (
    <Checkbox
      disabled={isWorking}
      onChange={ev => handleValueChange(ev.target.checked)}
      checked={isChecked}
      style={{ textDecoration: isChecked ? 'line-through' : 'none', fontSize: '12px' }}
    >
      <Flex align="center" gap={4}>
        {actionItem.text}
        <FontAwesomeIcon
          onClick={ev => {
            ev.preventDefault();
            ev.stopPropagation();
            window.open(`/recap/${actionItem.eventId}?insightId=${actionItem.id}`);
          }}
          size="1x"
          icon={faExternalLink}
        />
      </Flex>
    </Checkbox>
  );
};
const ActionItemMeeting: React.FC<Props> = ({ event }) => {
  return (
    <Flex vertical gap={8}>
      <Flex vertical gap={4}>
        <MeetingTitleLink id={event.id} title={event.title} />
        <MeetingDetail>
          {format(new Date(event.scheduledStartDate), 'eeee, MMMM d, yyyy @ h:mma')}
        </MeetingDetail>
        <MeetingDetail>Owner: {event.owner}</MeetingDetail>
      </Flex>

      {event.actionItems.map(actionItem => {
        return <ActionItem key={actionItem.id} actionItem={{ ...actionItem, eventId: event.id }} />;
      })}
    </Flex>
  );
};

export const ActionItems: React.FC = () => {
  const { selectedAccount } = useAccountOverview();
  const [includeCompleted, setIncludeCompleted] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterDates, setFilterDates] = useState<[Date, Date] | [null, null]>([
    addDays(new Date(), -365),
    new Date(),
  ]);

  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

  const { events, isFetching } = useAccountActionItems({
    accountId: selectedAccount.id,
    search: debouncedSearchTerm,
    includeCompleted,
    from: filterDates[0] || new Date(),
    to: filterDates[1] || new Date(),
  });

  const eventWithActionItems = events.filter(ev => ev.actionItems.length > 0);

  return (
    <Flex vertical gap={16}>
      <Flex justify="space-between">
        <Flex gap={8}>
          <Search
            placeholder="Search by meeting title, host, or participant"
            style={{ width: '400px' }}
            value={searchTerm}
            onChange={ev => setSearchTerm(ev.target.value)}
          />
        </Flex>
        <Space>
          <CompletedLabel>Include completed</CompletedLabel>
          <Switch
            onChange={checked => setIncludeCompleted(checked)}
            value={includeCompleted}
            size="small"
            title="Show completed"
          />
        </Space>
      </Flex>
      {isFetching ? (
        <UpdateLoader min />
      ) : !eventWithActionItems.length ? (
        <Error>No pending action items for this account</Error>
      ) : (
        eventWithActionItems.map(ev => <ActionItemMeeting key={ev.id} event={ev} />)
      )}
    </Flex>
  );
};
