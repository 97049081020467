import React from 'react';
import Styles from './styles';
import { Button, Dropdown } from 'antd';
import { PlaySmartClip } from '../../../../features/shared/components/Icons/PlaySmartClip';
import { ClipFolderItem } from '../../../../features/shared/components/Icons/ClipFolderItem';
import { FolderFolderItem } from '../../../../features/shared/components/Icons/FolderFolderItem';
import { MoreOutlined } from '@ant-design/icons';
import { Unstar } from '../../../../features/shared/components/Icons/Unstar';
import { secondsToHms } from '../../../../utils/dateUtils';
import { SmartClipTypeIndicator } from '../../../../features/libraries/components/SmartClipTypeIndicator';
import { SmartClipThumbnail } from '../../../../componentsV2/SmartClipThumbnail';

const {
  Container,
  Details,
  DetailDataContainer,
  DetailData,
  DetailLabel,
  Name,
  StarredByLabel,
  StarredByName,
  MenuTitle,
  ItemContainer,
  ItemLabel,
} = Styles;

interface Props {
  checked: boolean;
  name: string;
  type: string;
  folderName: string;
  thumbnail: string | null;
  starredBy: string;
  daysSinceMeeting: string;
  duration: string;
  itemId: string;
  eventId: string;
  onCheckChange: (value: boolean) => unknown;
  onRemove: () => unknown;
  onClick: () => unknown;
}

function formatDuration(seconds: number) {
  console.log(seconds);
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const formattedHrs = hrs < 10 ? `0${hrs}` : hrs;
  const formattedMins = mins < 10 ? `0${mins}` : mins;
  const formattedSecs = secs < 10 ? `0${secs}` : secs;

  return `${formattedHrs}:${formattedMins}:${formattedSecs}`;
}

export const FolderItem: React.FC<Props> = ({
  onClick,
  onRemove,
  starredBy,
  name,
  type,
  daysSinceMeeting,
  duration,
  folderName,
  itemId,
  eventId,
  thumbnail,
}) => {
  const days = Number(daysSinceMeeting) > 1 || Number(daysSinceMeeting) === 0 ? 'days ' : 'day ';

  return (
    <Container>
      {/*<Checkbox checked={checked} onChange={e => onCheckChange(e.target.checked)} />*/}
      <SmartClipThumbnail
        itemId={itemId}
        thumbnail={thumbnail}
        eventId={eventId}
        styles={{ width: '150px', height: '84px' }}
      />

      <Details>
        <Name id="folder-item-name" onClick={onClick}>
          {name.replaceAll('•', '').trim()}
        </Name>
        <span>
          <StarredByLabel>Starred by </StarredByLabel>
          <StarredByName>{starredBy}</StarredByName>
        </span>
        <DetailData>
          <DetailDataContainer>
            <PlaySmartClip />
            <DetailLabel>
              {daysSinceMeeting} {days}
              ago
            </DetailLabel>
          </DetailDataContainer>
          <DetailDataContainer>
            <ClipFolderItem />
            <DetailLabel>{secondsToHms(parseInt(duration))}</DetailLabel>
          </DetailDataContainer>
          <DetailDataContainer>
            <FolderFolderItem />
            <DetailLabel>{folderName}</DetailLabel>
          </DetailDataContainer>
          {type && (
            <DetailDataContainer>
              <SmartClipTypeIndicator type={type} />
            </DetailDataContainer>
          )}
        </DetailData>
      </Details>
      <Dropdown
        trigger={['click']}
        menu={{
          items: [
            {
              key: 'header',
              disabled: true,
              label: <MenuTitle>Smart Clip™️ Options</MenuTitle>,
            },
            {
              key: 'divider-1',
              type: 'divider',
            },
            {
              key: 'unstar-item',
              label: (
                <ItemContainer onClick={() => onRemove()}>
                  <Unstar />
                  <ItemLabel>Unstar insight</ItemLabel>
                </ItemContainer>
              ),
            },
          ],
        }}
      >
        <Button type="text" icon={<MoreOutlined />} />
      </Dropdown>
    </Container>
  );
};
