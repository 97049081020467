import React, { useEffect, useMemo, useState } from 'react';
import Style from './style';
import { ActionBar } from './ActionBar';
import { useToast } from '../../hooks/useToast';
import { format } from 'date-fns';
import { Timeline } from 'antd';
import { ClockCircleOutlined, CopyFilled } from '@ant-design/icons';
import { colors } from '../../theme/colors';
import { StoryTimelineEntry } from '../../features/account/account-story/types';
import { ACCOUNT_DATE_FORMAT } from '../../pages/accounts/components/Tabs';

interface Props {
  accountName: string;
  storyTimeline: StoryTimelineEntry[];
  storyNarrative: string | null;
  isGenerating: boolean;
  lastGeneratedAt: Date | null;
  onGenerate: () => void;
  style?: React.CSSProperties;
}

type ViewType = 'timeline' | 'narrative';

export const StoryTimeline: React.FC<Props> = ({
  accountName,
  storyNarrative,
  storyTimeline,
  isGenerating,
  lastGeneratedAt,
  onGenerate,
  style = {},
}) => {
  const { Container, Content, CopyAll } = Style;

  const [view, setView] = useState<ViewType>('timeline');
  const [timeline, setTimeline] = useState<StoryTimelineEntry[]>([]);
  const { success } = useToast();

  const handleFilter = () => {
    setTimeline([...timeline.reverse()]);
  };

  const mappingFunction = () => {
    switch (view) {
      case 'timeline': {
        return () =>
          timeline
            .map(s => `${format(new Date(s.date), ACCOUNT_DATE_FORMAT)}\n${s.story}`)
            .join('\n\n');
      }

      case 'narrative': {
        return () => storyNarrative;
      }
    }
  };

  const handleCopyAll = () => {
    const text = `${accountName.toUpperCase()} \n\n` + mappingFunction()();
    navigator.clipboard.writeText(text);
    success('Copied to clipboard');
  };

  const renderView = useMemo(() => {
    if (!lastGeneratedAt || !storyNarrative || !storyTimeline.length) {
      return (
        <div data-testid="account-story-narrative-items">
          <Content>No story generated yet</Content>
        </div>
      );
    }

    switch (view) {
      case 'timeline': {
        return (
          <Timeline
            data-testid="account-story-timeline-items"
            items={timeline.map(s => {
              return {
                dot: (
                  <ClockCircleOutlined
                    className="timeline-clock-icon"
                    style={{ fontSize: '24px' }}
                  />
                ),
                color: colors.coolGray[400],
                children: (
                  <Content>
                    <span>{format(new Date(s.date), ACCOUNT_DATE_FORMAT)}</span>
                    {s.story}
                  </Content>
                ),
              };
            })}
          />
        );
      }

      case 'narrative': {
        return (
          <div data-testid="account-story-narrative-items">
            <Content>{storyNarrative}</Content>
          </div>
        );
      }
    }
  }, [view, lastGeneratedAt, timeline]);

  useEffect(() => {
    setTimeline(storyTimeline);
  }, [storyTimeline]);

  return (
    <div>
      <Container style={style}>
        <ActionBar
          onGenerate={onGenerate}
          onClick={v => setView(v as ViewType)}
          onFilter={handleFilter}
          defaultValue={view}
          lastGeneratedAt={lastGeneratedAt}
          disableGenerate={isGenerating}
          isGenerating={isGenerating}
        />
        {renderView}
      </Container>

      {lastGeneratedAt && !isGenerating && (
        <CopyAll onClick={handleCopyAll} data-testid="account-story-copy-all-button">
          <CopyFilled />
          Copy all
        </CopyAll>
      )}
    </div>
  );
};
