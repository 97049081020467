import { Button } from 'antd';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GoogleButton } from '../../../../Layout/FramedLayout/Login/GoogleButton';
import AuthenticationForm from '../../../../features/authentication/components/AuthenticationForm';
import Label from '../../../../features/shared/components/Label';
import Styles from './styles';
import { MicrosoftButton } from '../../../../features/authentication/components/MicrosoftButton';
import { SsoForm } from '../../../../componentsV2/SsoForm';
import { coreService } from '../../../../services/core/core-service';
import { AuthenticationButton } from '../../../../features/authentication/components/AuthenticationButton';

const {
  FormContainer,
  ActionsContainer,
  LoginLink,
  Line,
  DividerContainer,
  RegisterContainer,
  ErrorMessage,
  LoginOptions,
} = Styles;

interface Props {
  onStandardLogin: () => unknown;
}

const SigninForm: React.FC<Props> = ({ onStandardLogin }) => {
  const [params] = useSearchParams();
  const [showSso, setShowSso] = useState(false);

  const handleClick = async () => {
    const finalUrl = `${process.env.REACT_APP_API_CORE}/users/microsoft/authenticate`;
    window.location.replace(finalUrl);
  };

  return (
    <AuthenticationForm title="Welcome back" description="Sign in with your preferred method">
      <FormContainer>
        {showSso ? (
          <SsoForm
            getUrlFunction={email => coreService.getSsoSigninUrl(email).then(data => data.url)}
            backLabel="Sign in with another option"
            primaryLabel="Sign in with SSO"
            onBack={() => setShowSso(false)}
          />
        ) : (
          <ActionsContainer>
            <LoginOptions>
              <GoogleButton />
              <div style={{ marginLeft: '25px' }}>
                <MicrosoftButton label="Sign in with Microsoft" handleClick={handleClick} />
              </div>
              <div style={{ marginLeft: '25px' }}>
                <AuthenticationButton label="Sign in with SSO" onClick={() => setShowSso(true)} />
              </div>
            </LoginOptions>

            <DividerContainer>
              <Line />
              <Label style={{ margin: '0px 8px' }}>OR</Label>
              <Line />
            </DividerContainer>
            <Button onClick={onStandardLogin} type="primary">
              Login with user and password
            </Button>
          </ActionsContainer>
        )}
        <RegisterContainer>
          <Label>Don't have an account?</Label>
          <LoginLink href="/signup">Register here</LoginLink>.
        </RegisterContainer>
        <ErrorMessage>{params.get('errorMessage') || ''}</ErrorMessage>
      </FormContainer>
    </AuthenticationForm>
  );
};

export default SigninForm;
