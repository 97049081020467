import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../services/core/core-service';

export const useFilterValues = () => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['filter-values'],
    queryFn: async () => {
      const data = await coreService.getAllInsightsEngine(
        new Date().toISOString(),
        new Date().toISOString(),
        'mentions',
        'mentions',
      );
      return data.filters;
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  return {
    filters: data,
    isFetching,
    refetch,
  };
};
