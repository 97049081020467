import React, { useEffect, useRef, useState } from 'react';
import Styles from './styles';
import { ClearOutlined, CloseOutlined, CopyFilled, SendOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useAskMeAnything } from '../../../../askMeAnything/hooks/useAskMeAnything';
import { ChatItem } from '../../../../../services/types';
import { useToast } from '../../../../../hooks/useToast';
import { SuggestedQuestions } from '../SuggestedQuestions';
import { MessagesList } from './MessagesList';
import { UpgradeToPro } from '../../../../../componentsV2/UpgradeToPro';
import useFeatures from '../../../../../hooks/useFeatures';

const {
  MagicIconHeader,
  Header,
  ChatContainer,
  Modal,
  ChatInput,
  ActionButtonsContainenr,
  ChatActionsContainer,
  BottomContainer,
  HeaderContainer,
  Description,
} = Styles;

interface Props {
  open: boolean;
  location: string;
  description?: string;
  askFunction: (chatMessages: ChatItem[]) => Promise<ReadableStream<Uint8Array> | null>;
  onClose?: () => unknown;
}

export const AskMeAnythingModal: React.FC<Props> = ({
  open,
  location,
  onClose,
  askFunction,
  description,
}) => {
  const [question, setQuestion] = useState('');
  const {
    messages,
    isAsking,
    suggestedQuestions,
    defaultCategory,
    suggestedQuestionsDisplayDropdown,
    ask,
    clearMessages,
    copyAll,
  } = useAskMeAnything();
  const { success } = useToast();
  const messageCount = useRef(0);

  const sendMessage = () => {
    if (!isAsking) {
      ask({ question: question, askFunction: askFunction, location: location });
      setQuestion('');
    }
  };

  const handleCopyAll = () => {
    copyAll();
    success('Messages copied to clipboard');
  };

  const buttonLabel = isAsking ? 'Waiting for response' : 'Submit';
  const noMessages = messages.length === 0;

  const features = useFeatures();
  const availableFeature = features.ama;

  useEffect(() => {
    const totalMessages = messages.length;
    if (totalMessages !== messageCount.current) {
      const lastMessagePosition = messages.length - 1;
      const lastMessageKey = `ama-${lastMessagePosition}`;
      document.getElementById(lastMessageKey)?.scrollIntoView();
      messageCount.current = totalMessages;
    }
  }, [messages]);

  return (
    <Modal
      centered
      width={'770px'}
      closable={false}
      bodyStyle={{ padding: '0px', minHeight: '430px' }}
      style={{ borderRadius: '8px', height: '730px' }}
      title={
        <HeaderContainer>
          <Header>
            <MagicIconHeader />
            <span style={{ flex: 1 }}>Ask me anything</span>
            <CloseOutlined
              onClick={ev => {
                ev.preventDefault();
                ev.stopPropagation();
                if (onClose) onClose();
              }}
            />
          </Header>
          <Description>{description}</Description>
        </HeaderContainer>
      }
      footer={null}
      open={open}
    >
      <UpgradeToPro plan="pro" display={!availableFeature}>
        <>
          <MessagesList messages={messages} />
          <ChatActionsContainer>
            <Button
              disabled={noMessages}
              icon={<CopyFilled />}
              onClick={handleCopyAll}
              style={{ borderRadius: '8px' }}
            >
              Copy All
            </Button>

            <Button
              disabled={noMessages}
              icon={<ClearOutlined />}
              onClick={clearMessages}
              style={{ borderRadius: '8px' }}
            >
              Clear Chat
            </Button>
          </ChatActionsContainer>
          <BottomContainer>
            <SuggestedQuestions
              send={m => {
                ask({
                  question: m,
                  askFunction: askFunction,
                  suggestedQuestion: true,
                  location: location,
                });
              }}
              display={suggestedQuestionsDisplayDropdown}
              disabled={isAsking}
              context={suggestedQuestions!}
              defaultCategory={defaultCategory!}
            />

            <ChatContainer>
              <ChatInput
                value={question}
                onKeyDown={ev => {
                  if (ev.key === 'Enter' && !ev.shiftKey) {
                    ev.stopPropagation();
                    ev.preventDefault();
                    sendMessage();
                  }
                }}
                onChange={ev => {
                  setQuestion(ev.target.value);
                }}
                placeholder="Enter your prompt..."
              />

              <ActionButtonsContainenr>
                <Button
                  onClick={sendMessage}
                  icon={<SendOutlined />}
                  style={{ width: '200px', height: '40px', borderRadius: '8px' }}
                  disabled={!question || isAsking}
                  type="primary"
                  loading={isAsking}
                >
                  {buttonLabel}
                </Button>
              </ActionButtonsContainenr>
            </ChatContainer>
          </BottomContainer>
        </>
      </UpgradeToPro>
    </Modal>
  );
};
