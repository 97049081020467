import React, { useEffect, useState } from 'react';
import { ButtonAction, IntegrationCard } from '../Components/IntegrationCard';
import { useIntegrationRequest } from '../../../../../features/integrations/hooks/useIntegrationRequest';
import { Store } from '../../../../../redux/typings/store';
import { useSelector } from 'react-redux';
import {
  selectCrmProfile,
  selectHubspotIntegration,
  selectSalesforceIntegration,
  selectUser,
} from '../../../../../redux/selectors';
import { SuccessConnectionDataLabel } from '../Components/SuccessConnectionDataLabel';
import { format } from 'date-fns';
import { extractError } from '../../../../../utils/api';
import { useToast } from '../../../../../hooks/useToast';
import { coreService } from '../../../../../services/core/core-service';
import plug from '../../../../../assets/svg/actions/plug.svg';
import unplug from '../../../../../assets/svg/actions/unplug.svg';
import { OnlyOneConnected } from '../Components/OnlyOneConnected';
import { useOrganizationDetails } from '../../../../../features/organizations/hooks/useOrganizationDetails';
import Style from '../Components/IntegrationCard/styles';
import useFeatures from '../../../../../hooks/useFeatures';
import { SubscribeToTeamsPlanLink } from '../../../../../componentsV2/UpgradeToPro';

const { Information } = Style;

interface CrmSyncData {
  isSyncing: boolean;
  lastSyncDate: string | null;
}

const selectSalesforceIntegrationId = (store: Store) =>
  store.session.user?.configuration.integration.salesforce.id;

export const SalesforceIntegrationCard: React.FC = () => {
  const { isIntegrating, requestIntegration, hasIntegration, removeIntegration, isRemoving } =
    useIntegrationRequest({ source: 'SALESFORCE', id: selectSalesforceIntegrationId });
  const user = useSelector(selectUser);
  const { hasPrimaryProfileConnected, crmData } = useOrganizationDetails();

  const [crmSyncStatus, setCrmSyncStatus] = useState<CrmSyncData>({
    isSyncing: false,
    lastSyncDate: null,
  });
  const [isRequestingRefresh, setIsRequestingRefresh] = useState(false);
  const integrationData = useSelector(selectSalesforceIntegration);
  const hubspotIntegrationData = useSelector(selectHubspotIntegration);
  const crmProfile = useSelector(selectCrmProfile);
  const { error: errorToast, success: successToast } = useToast();

  const features = useFeatures();
  const availableFeature = features.salesforceIntegration;

  const hasHubspotConnected = Boolean(hubspotIntegrationData && hubspotIntegrationData.id);

  let tooltipMessage: React.ReactElement | string | undefined = undefined;
  if (!availableFeature) {
    tooltipMessage = (
      <SubscribeToTeamsPlanLink title="Upgrade to a team plan to push your insights to Salesforce." />
    );
  } else {
    if (!hasPrimaryProfileConnected) {
      tooltipMessage = 'Your organization needs a primary profile connected first.';
    } else if (crmData.provider === 'hubspot') {
      tooltipMessage =
        'Your workspace may only be connected to one CRM type at a time. Please contact the Owner or Admins.';
    } else {
      tooltipMessage = undefined;
    }
  }

  const changeCrmSyncStatus = (isSyncing: boolean, lastSyncDate: Date) => {
    setCrmSyncStatus({
      isSyncing,
      lastSyncDate: format(lastSyncDate, 'EEE - MMM dd - HH:mm:ss'),
    });
  };

  useEffect(() => {
    if (
      crmProfile &&
      typeof crmProfile.lastSyncStarted !== 'undefined' &&
      typeof crmProfile.isSyncing !== 'undefined'
    ) {
      if (crmProfile.lastSyncStarted !== null) {
        changeCrmSyncStatus(crmProfile.isSyncing, new Date(crmProfile.lastSyncStarted));
      } else {
        setCrmSyncStatus({ isSyncing: crmProfile.isSyncing, lastSyncDate: 'never' });
      }
    }
  }, [crmProfile]);

  const refreshIntegration = async () => {
    if (user) {
      changeCrmSyncStatus(true, new Date());
      try {
        await coreService.refreshCrmIntegration(user.id);
        successToast('Refresh requested successfully.');
      } catch (error) {
        const message = extractError(error);
        errorToast(message);
      } finally {
        setIsRequestingRefresh(false);
      }
    }
  };

  const disableRefreshButton =
    (crmSyncStatus && crmSyncStatus.isSyncing) ||
    isRequestingRefresh ||
    Boolean(crmData?.primaryProfile !== user?.email);

  const connectionButton: ButtonAction = hasIntegration
    ? {
        type: 'button',
        label: 'Disconnect',
        icon: <img src={plug} />,
        onClick: () => {
          if (availableFeature) removeIntegration();
        },
        buttonProps: {
          loading: isRemoving,
          disabled: !!!integrationData?.id || !availableFeature,
          style: {
            width: '102px',
            height: '34px',
          },
        },
      }
    : {
        type: 'button',
        label: 'Connect',
        icon: <img src={unplug} />,
        onClick: () => {
          if (availableFeature) requestIntegration();
        },
        tooltip: tooltipMessage,
        buttonProps: {
          loading: isIntegrating,
          type: 'primary',
          disabled:
            hasHubspotConnected ||
            !hasPrimaryProfileConnected ||
            (hasPrimaryProfileConnected && crmData?.provider === 'hubspot') ||
            !availableFeature,
          style: {
            width: '102px',
            height: '34px',
          },
        },
      };

  const actionButtons: ButtonAction[] = [connectionButton];

  return (
    <IntegrationCard
      title="Salesforce"
      description="Push all your recaps to Salesforce CRM."
      logoSrc="https://assets.update.ai/salesforce_logo.png"
      actions={actionButtons}
      // learnMore="https://help.update.ai/en/articles/7211777-how-to-integrate-salesforce-or-hubspot-crm-into-updateai"
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {hasPrimaryProfileConnected &&
          crmData.provider === 'salesforce' &&
          crmData?.pushToCrmWithPrimaryProfile &&
          !integrationData?.id && (
            <Information>
              Recaps will pushed to the CRM under the user profile <b>{crmData?.primaryProfile}</b>{' '}
              Link your own CRM profile if you’d like meeting recaps to be synced under your own CRM
              profile.
            </Information>
          )}
        {hasIntegration && (
          <SuccessConnectionDataLabel label="Connected: " value={integrationData?.email || ''} />
        )}

        {hasHubspotConnected && (
          <OnlyOneConnected elem={<>You can only connect one CRM at a time. Disconnect one.</>} />
        )}
      </div>
      {/* <IssueRecapMapAutomatic /> */}
    </IntegrationCard>
  );
};
