import React from 'react';
import Styles from '../styles';
import { Button } from 'antd';
import Avatar from '../../../../../../../features/shared/components/Avatar';
import { OpenNew } from '../../../../../../../features/shared/components/Icons/OpenNew';
import { useEmail } from '../../../../../../../features/email/useEmail';

const { UserItemContainer, LabelDiscrete, UserName, UserDataContainer } = Styles;

interface Props {
  email: string;
  fullName: string;
}

const UserItem: React.FC<Props> = ({ email, fullName }) => {
  const firstLetter = fullName[0].toUpperCase();
  const { getEmailLink } = useEmail();

  return (
    <UserItemContainer>
      <Avatar>{firstLetter}</Avatar>
      <UserDataContainer>
        <UserName>{fullName}</UserName>
        <LabelDiscrete>{email}</LabelDiscrete>
      </UserDataContainer>
      <Button
        onClick={() =>
          window.open(getEmailLink({ to: [email], subject: 'Request upgrade', body: '' }), '_blank')
        }
        icon={<OpenNew style={{ stroke: '#A6A6A6' }} />}
        type="default"
      >
        Email
      </Button>
    </UserItemContainer>
  );
};

export default UserItem;
