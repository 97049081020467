import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const TrophySVG = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="#FFF6C6" />
      <path
        d="M28.5 30.75H19.5M28.5 30.75C29.2956 30.75 30.0587 31.0661 30.6213 31.6287C31.1839 32.1913 31.5 32.9544 31.5 33.75H16.5C16.5 32.9544 16.8161 32.1913 17.3787 31.6287C17.9413 31.0661 18.7043 30.75 19.5 30.75M28.5 30.75V27.375C28.5 26.754 27.997 26.25 27.375 26.25H26.504M19.5 30.75V27.375C19.5 26.754 20.004 26.25 20.625 26.25H21.497M26.504 26.25H21.497M26.504 26.25C25.9423 25.28 25.6067 24.1957 25.522 23.078M21.497 26.25C22.0583 25.2799 22.3936 24.1956 22.478 23.078M25.522 23.078C26.5299 22.8454 27.4697 22.3834 28.27 21.728M25.522 23.078C24.5205 23.309 23.4795 23.309 22.478 23.078M22.478 23.078C21.4704 22.8452 20.53 22.3833 19.73 21.728M17.25 16.236C16.268 16.379 15.296 16.553 14.334 16.756C14.557 18.0648 15.2076 19.2627 16.1839 20.1624C17.1603 21.062 18.4074 21.6126 19.73 21.728M17.25 16.236V16.5C17.25 18.608 18.216 20.49 19.73 21.728M17.25 16.236V14.721C19.456 14.41 21.71 14.25 24 14.25C26.291 14.25 28.545 14.41 30.75 14.72V16.236M30.75 16.236V16.5C30.75 18.608 29.784 20.49 28.27 21.728M30.75 16.236C31.7273 16.3782 32.6998 16.5516 33.666 16.756C33.443 18.0646 32.7926 19.2625 31.8164 20.1621C30.8403 21.0617 29.5924 21.6124 28.27 21.728"
        stroke="#B75A06"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const Trophy: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={TrophySVG} {...props} />;
};
