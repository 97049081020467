import React, { useEffect, useState } from 'react';
import { TitledVerticalFlexLayout } from '../../features/shared/components/VerticalFixedLayout/VerticalFixedLayout';
import { Button, Drawer, Dropdown, Flex, Space, Spin } from 'antd';
import { Overview } from './components/Overview';
import { AccountTabs } from './components/Tabs';
import Styles from './style';
import { OptionalTooltipWrapper } from '../../componentsV2/OptionalTooltipWrapper';
import BetaBadge from '../../features/shared/components/Badges/Beta';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { AccountSelector } from './components/AccountSelector';
import { useAccountSearch } from '../../features/account/account-search/account-search.hook';
import { useAccountOverview } from '../../features/account/account-overview/account-overview.hook';
import { DownOutlined, LineChartOutlined } from '@ant-design/icons';
import { PropertyMappingProvider } from '../../features/crm/property-mapping/components/PropertyMappingProvider';
import { AskMeAnythingProvider } from '../../features/askMeAnything/context/AskMeAnythingProvider';
import { AskMeAnything } from '../../features/shared/components/AskMeAnything';
import { useFavouriteAccount } from '../../features/account/hooks/useFavouriteAccount';
import { ChatItem } from '../../services/types';
import { Questions } from '../../features/askMeAnything/questions';
import { useAskMeAnything } from '../../features/askMeAnything/hooks/useAskMeAnything';
import { AccountStoryProvider } from '../../features/account/account-story/context/account-story-context';
import { AccountStory } from './components/Tabs/AccountStory';
import { DateSelector } from '../../features/shared/components/DateSelector';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UpgradeToPro } from '../../componentsV2/UpgradeToPro';
import useFeatures from '../../hooks/useFeatures';

const {
  Content,
  LeftColumn,
  RightColumn,
  CenteredContent,
  Header,
  FilterAndActionsContainer,
  FiltersContainer,
  ActionsContainer,
  Body,
} = Styles;

export const AccountsPage: React.FC = () => {
  const {
    selectedAccountFetching,
    selectedAccount,
    setSelectedAccount,
    setDateRange,
    favouriteStatusChanged,
  } = useAccountOverview();
  const navigate = useNavigate();
  const { accounts, isFetching: isFetchingAccounts } = useAccountSearch();
  const { askAccounts } = useAskMeAnything();
  const { dateRange } = useAccountOverview();
  const [showTimelineDrawer, setShowTimelineDrawer] = useState(false);
  const [dateOption, setDateOption] = useState('all');
  const params = useParams();
  const { search } = useLocation();
  const [currentTab, setCurrentTab] = useState('past-meetings');

  const features = useFeatures();
  const availableFeature = features.accountInsights;

  const goToAccount = (accountId: string, tab?: string) => {
    const searchParams = new URLSearchParams();
    if (tab) {
      searchParams.append('tab', tab);
    }

    const queryString = `${
      Array.from(searchParams.keys()).length > 0 ? `?${searchParams.toString()}` : ''
    }`;
    navigate(`/accounts/${accountId}${queryString}`);
  };

  const handleAccountSelected = (accountId: string) => {
    goToAccount(accountId);
  };

  const { favouriteButton } = useFavouriteAccount(
    selectedAccount.id,
    selectedAccount.isFavourited,
    favouriteStatusChanged,
  );

  const isLoading = isFetchingAccounts || selectedAccountFetching;

  const handleOpenInCrm = () => {
    window.open(selectedAccount.url, '_blank');
  };

  const handleViewTrend = (category: string) => {
    window.open(
      `/insights?type=${category}&accountId=${selectedAccount.mappingId},${selectedAccount.name},${selectedAccount.provider}`,
      '_blank',
    );
  };

  const handleSelectedTab = (tabName: string) => {
    goToAccount(selectedAccount.id, tabName);
  };

  useEffect(() => {
    if (accounts.length && !params.accountId) {
      goToAccount(accounts[0].id);
    }
  }, [accounts, params]);

  useEffect(() => {
    if (params.accountId) {
      setSelectedAccount(params.accountId);
    }

    if (search) {
      const params = new URLSearchParams(search);
      const tab = params.get('tab');
      if (tab) {
        setCurrentTab(tab);
      }
    }
  }, [accounts, params, search]);

  return (
    <TitledVerticalFlexLayout
      title={
        <Header>
          <>Account Insights</>
        </Header>
      }
    >
      <UpgradeToPro plan="startup" display={!availableFeature}>
        {isLoading ? (
          <CenteredContent>
            <Spin />
          </CenteredContent>
        ) : (
          <Body>
            <Drawer
              contentWrapperStyle={{ width: '600px' }}
              title="Account Story"
              placement="right"
              open={showTimelineDrawer}
              closable
              onClose={() => setShowTimelineDrawer(false)}
            >
              <AccountStoryProvider accountId={selectedAccount.id}>
                <AccountStory accountName={selectedAccount.name || 'Account'} />
              </AccountStoryProvider>
            </Drawer>

            <Flex align="center" justify="space-between">
              <Flex gap={8}>
                <AccountSelector onSelect={handleAccountSelected} value={selectedAccount} />

                <DateSelector
                  direction={'past'}
                  value={dateOption}
                  onDateOptionChanged={setDateOption}
                  onDateRangeChanged={setDateRange}
                  predefinedRanges={{
                    '3m': false,
                    '6m': false,
                    '12m': false,
                    today: false,
                    '1d': false,
                    '7d': false,
                    '30d': false,
                  }}
                  defaultValues={dateRange}
                  allowAll
                />
                {favouriteButton}
                {/*<Dropdown
                  menu={{
                    items: [
                      {
                        label: (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            View Risks Trends
                            <FontAwesomeIcon icon={faExternalLink} />
                          </div>
                        ),
                        key: 'Risks',
                        onClick: () => handleViewTrend('Risks'),
                      },
                      {
                        label: (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            View Product Feedback Trends
                            <FontAwesomeIcon icon={faExternalLink} />
                          </div>
                        ),
                        key: 'Product Feedback',
                        onClick: () => handleViewTrend('Product Feedback'),
                      },
                      {
                        label: (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            View Advocacy Trends
                            <FontAwesomeIcon icon={faExternalLink} />
                          </div>
                        ),
                        key: 'Advocacy',
                        onClick: () => handleViewTrend('Advocacy'),
                      },
                    ],
                  }}
                >
                  <Button>
                    <Space>
                      <LineChartOutlined />
                      <span>View Account Insight Trends</span>
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>*/}
              </Flex>
              <Flex gap={8}>
                <Button onClick={() => setShowTimelineDrawer(true)} type="default">
                  <Space>
                    <span>📖</span> <span>View Account Story</span>
                  </Space>
                </Button>
                <ActionsContainer>
                  {selectedAccount.provider !== 'email' && (
                    <OptionalTooltipWrapper
                      display={!selectedAccount.url}
                      value="This account does not have an URL set"
                    >
                      <Button
                        onClick={handleOpenInCrm}
                        disabled={!selectedAccount.url}
                        type="default"
                      >
                        <Space>
                          <FontAwesomeIcon icon={faExternalLink} />
                          Open in CRM
                        </Space>
                      </Button>
                    </OptionalTooltipWrapper>
                  )}

                  <AskMeAnythingProvider
                    amaKey={selectedAccount.id}
                    suggestedQuestions={{
                      data: Questions.accounts,
                      category: 'all',
                      displayDropdown: false,
                    }}
                  >
                    <AskMeAnything
                      customText="Ask me anything"
                      location="accounts"
                      askFunction={(chatMessages: ChatItem[]) =>
                        askAccounts(
                          chatMessages,
                          selectedAccount.id,
                          dateRange[0] ? dateRange[0].toISOString() : null,
                          dateRange[1] ? dateRange[1].toISOString() : null,
                        )
                      }
                      styles={{ width: '200px', alignSelf: 'flex-end', marginRight: '8px' }}
                      disabled={false}
                    />
                  </AskMeAnythingProvider>
                </ActionsContainer>
              </Flex>
            </Flex>
            <FilterAndActionsContainer></FilterAndActionsContainer>

            <Content>
              <LeftColumn>
                <AccountTabs currentTab={currentTab} onTabSelected={handleSelectedTab} />
              </LeftColumn>

              <RightColumn>
                {selectedAccount && (
                  <PropertyMappingProvider>
                    <Overview />
                  </PropertyMappingProvider>
                )}
              </RightColumn>
            </Content>
          </Body>
        )}
      </UpgradeToPro>
    </TitledVerticalFlexLayout>
  );
};
