import React, { useContext } from 'react';
import Styles from './style';
import { differenceInDays } from 'date-fns';
import { Divider, Tooltip } from 'antd';
import { Card } from '../../../../components/Card';
import { Mapping } from '../../../../features/crm/property-mapping/types';
import { propertyMappingContext } from '../../../../features/crm/property-mapping/property-mapping.context';
import { PropertyValue } from './components/PropertyValue';

import { useAccountOverview } from '../../../../features/account/account-overview/account-overview.hook';
import { DealNameProperty } from './components/DealNameProperty';
import { formatDateToDayMonthYear } from '../../../../utils/dateUtils';

const { SectionWrapper, SectionTitle, PropertyWrapper, PropertyName } = Styles;

const getCrmPropertyValue = (
  mapping: Mapping[],
  propertyId: string,
  accountProperty?: string | number,
  defaultMessage?: string,
  formattingFn: (value: any) => string = value => value,
) => {
  const property = mapping.find(m => m.property.id === propertyId);

  if (!property) {
    return null;
  }

  if (!property.crmProperty) {
    return null;
  }

  if (accountProperty) {
    return formattingFn(accountProperty);
  }

  return defaultMessage || 'No value provided';
};

export const Overview: React.FC = () => {
  const { store } = useContext(propertyMappingContext);
  const { selectedAccount: account, primaryDealChanged } = useAccountOverview();
  const { mapping } = store();

  const daysEngaged = (date?: Date) => {
    const difference = differenceInDays(new Date(), new Date(date || ''));
    if (isNaN(difference)) {
      return '-';
    } else {
      return `${difference} ${difference > 1 || difference === 0 ? 'days' : 'day'} ago`;
    }
  };

  const resolveLastMeetingLabel = (
    lastMeetingInformation?: null | {
      date: Date;
      users: { id: string; email: string; fullName: string }[];
    },
  ) => {
    if (!lastMeetingInformation || !lastMeetingInformation.users.length) return <span>-</span>;

    const { date, users } = lastMeetingInformation;

    const formatedDate = daysEngaged(new Date(date || ''));

    const usersLabel = users.length > 1 ? 'Multiple' : users[0].email;

    const participantsEmails = (
      <>
        <strong>{'Meeting participants\n'}</strong>
        {users.map(user => user.email).join('\n')}
      </>
    );

    if (users.length > 1) {
      return (
        <span>
          {' '}
          {formatedDate} <Tooltip title={participantsEmails}>{`(${usersLabel})`}</Tooltip>
        </span>
      );
    } else {
      return <span>{`${formatedDate} (${usersLabel})`}</span>;
    }
  };

  const handlePrimaryDealChanged = (primaryDealId: string) => {
    const deal = account.deals.find(deal => deal.id === primaryDealId);
    if (deal) {
      primaryDealChanged(deal);
    }
  };

  return (
    <Card title="Overview" style={{ width: '250px' }}>
      <SectionWrapper>
        <SectionTitle>Company properties</SectionTitle>
        <PropertyWrapper>
          <PropertyName>Lifecycle Stage</PropertyName>
          <PropertyValue
            crmPropertyValue={getCrmPropertyValue(
              mapping,
              'lifecycleStage',
              account.lifecycleStage,
            )}
          />
        </PropertyWrapper>
        <PropertyWrapper>
          <PropertyName>Revenue Segment</PropertyName>
          <PropertyValue
            crmPropertyValue={getCrmPropertyValue(
              mapping,
              'revenueSegment',
              account.revenueSegment,
            )}
          />
        </PropertyWrapper>
        <PropertyWrapper>
          <PropertyName>Geography</PropertyName>
          <PropertyValue
            crmPropertyValue={getCrmPropertyValue(mapping, 'geography', account.geography)}
          />
        </PropertyWrapper>
        <PropertyWrapper>
          <PropertyName>Industry</PropertyName>
          <PropertyValue
            crmPropertyValue={getCrmPropertyValue(mapping, 'industry', account.industry)}
          />
        </PropertyWrapper>
        <PropertyWrapper>
          <PropertyName>Last meeting</PropertyName>
          <PropertyValue crmPropertyValue={resolveLastMeetingLabel(account.lastMeetingDateAll)} />
        </PropertyWrapper>
      </SectionWrapper>

      <Divider />

      <SectionWrapper>
        <SectionTitle>Deal properties</SectionTitle>
        <DealNameProperty
          deals={account.deals}
          primaryDealId={account.primaryDeal?.id || null}
          onPrimaryDealChanged={handlePrimaryDealChanged}
          accountId={account.id}
        />
        <PropertyWrapper>
          <PropertyName>Deal Stage</PropertyName>
          <PropertyValue
            crmPropertyValue={getCrmPropertyValue(
              mapping,
              'dealStage',
              account.primaryDeal?.dealStage,
            )}
          />
        </PropertyWrapper>
        <PropertyWrapper>
          <PropertyName>Close Date</PropertyName>
          <PropertyValue
            crmPropertyValue={getCrmPropertyValue(
              mapping,
              'closedDate',
              account.primaryDeal?.closedDate,
              undefined,
              value => formatDateToDayMonthYear(value),
            )}
          />
        </PropertyWrapper>
        <PropertyWrapper>
          <PropertyName>Contract Value</PropertyName>
          <PropertyValue
            crmPropertyValue={getCrmPropertyValue(
              mapping,
              'contractValue',
              account.primaryDeal?.contractValue,
              undefined,
              value => `$ ${value}`,
            )}
          />
        </PropertyWrapper>
      </SectionWrapper>
    </Card>
  );
};
