import React, { useState } from 'react';
import Style from './style';
import Amex from './logos/amex.png';
import Apple from './logos/applePay.png';
import Discovery from './logos/discover.png';
import Google from './logos/googlePay.png';
import JCB from './logos/jcb.png';
import Mastercard from './logos/mastercard.png';
import Union from './logos/unionPay.png';
import Visa from './logos/visa.png';
import { Button } from 'antd';
import { twoDigitNumber } from '@updateai/ui/dist/utils/timeUtils';

import { CardProvider } from '..';
import { coreAPI } from '../../../../../API/core';

interface Props {
  provider: CardProvider;
  last4: string;
  expiration: string;
  isCanceled: boolean;
}

const brandToLogo = {
  amex: Amex,
  apple: Apple,
  discover: Discovery,
  google: Google,
  jcb: JCB,
  mastercard: Mastercard,
  unionpay: Union,
  visa: Visa,
};

export const CardHolder: React.FC<Props> = ({ provider, last4, expiration, isCanceled }) => {
  const { Container, Info, Number, Expiration } = Style;

  const [loading, setLoading] = useState(false);

  const getLogo = (provider: CardProvider) => {
    return <img style={{ height: '34px' }} src={brandToLogo[provider]} />;
  };

  const edit = async () => {
    const api = new coreAPI();
    setLoading(true);

    try {
      const url: string = await api.editPaymentMethod();
      if (url) window.open(url);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {getLogo(provider)}
      <Info>
        <Number>
          {provider[0].toUpperCase() + provider.slice(1).toLowerCase()}
          <p style={{ margin: 0, fontSize: '24px' }}>{' ···· ···· ···· '}</p>
          {last4}
        </Number>

        <Expiration>
          Expiry date {twoDigitNumber(expiration.split('/')[0]) + '/' + expiration.split('/')[1]}
        </Expiration>
      </Info>

      <Button
        children="Edit"
        style={{ width: '94px' }}
        disabled={isCanceled}
        onClick={() => edit()}
        loading={loading}
      />
    </Container>
  );
};
