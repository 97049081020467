import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 20px 16px;
`;

const Buttons = styled.div`
  display: flex;
  padding: 12px 16px;
  gap: 20px;
`;

const Title = styled.div`
  display: flex;
  align-self: stretch;
  color: var(--Gray-950, #282828);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
`;

const Columns = styled.div`
  display: flex;
  padding-bottom: 15px;
  width: 100%;
  justify-content: space-between;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-md, 8px);
  flex: 1 0 0;
  border-radius: var(--radius-md, 8px);
  background: var(--white, #fff);
`;

const RightColumn = styled.div`
  display: flex;
  gap: 40px;
  background: var(--white, #fff);
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-md, 8px);
  align-self: stretch;

  color: var(--Cool-Gray-400, #566976);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-xs, 16px);
  align-self: stretch;
`;

const Divider = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px 0px 0px;

  width: 100%;
  height: 1px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

const MappingGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const GroupTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  -webkit-letter-spacing: 0em;
  -moz-letter-spacing: 0em;
  -ms-letter-spacing: 0em;
  letter-spacing: 0em;
`;

const Cancel = styled.a`
  font-style: normal;
  text-decoration: underline;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #e6614c;
  flex: none;
  flex-grow: 0;
  padding-left: 4px;
  align-self: center;
`;

export default {
  Container,
  Title,
  Buttons,
  Columns,
  LeftColumn,
  RightColumn,
  Header,
  Body,
  Divider,
  MappingGroup,
  GroupTitle,
  Cancel,
};
