import styled from 'styled-components';
import { Collapse } from 'antd';
import { colors } from '../../../../../../../../../../theme/colors';

const Container = styled.div<{ color?: string }>`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  width: 100%;
  background: ${props => (props.color ? props.color : colors.coolGray[100])};
`;

const LeftColumn = styled.div`
  display: flex;
  height: 44px;
  width: 100%;
  padding: 12px 24px 12px 12px;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  color: var(--Cool-Gray-500, #0d283b);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const RightColumn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-xs, 12px);
  align-self: stretch;
  padding-right: 10px;
`;

const StyledColapse = styled(Collapse)`
  width: 100%;
  .ant-collapse-header {
    padding: 0px !important;
    border-radius: 12px !important;
  }

  .ant-collapse-item {
    border-bottom: none !important;
  }

  .ant-collapse ant-collapse-icon-position-start ant-collapse-small {
    border: 0px !important;
    background-color: none !important;
  }

  .ant-collapse-content-active {
    border: 0px !important;
  }

  .ant-collapse-content-box {
    padding: 0px !important;
  }
`;

const LeftColumnInnerMapping = styled.div`
  display: flex;
  justify-content: left;
  gap: 16px;
`;

const Name = styled.div`
  display: flex;
  min-width: 130px;
  width: 100%;
  align-items: start;
`;

const InnerRow = styled.div`
  display: flex;
  height: 44px;
  align-items: center;
  color: var(--Cool-Gray-500, #0d283b);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 12px 44px 12px 12px;
  width: 100%;
  justify-content: space-between;
`;

const Grouping = styled.div`
  display: flex;
  gap: 24px;
`;

export default {
  Container,
  LeftColumn,
  RightColumn,
  StyledColapse,
  LeftColumnInnerMapping,
  Name,
  InnerRow,
  Grouping,
};
