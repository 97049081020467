import { assetsDomain } from '../../App';
import style from './style';

interface Props {
  styles?: React.CSSProperties;
  itemId: string;
  thumbnail: string | null;
  eventId: string;
}

export const SmartClipThumbnail: React.FC<Props> = ({ styles, itemId, eventId, thumbnail }) => {
  const { Container } = style;

  const handleClick = () => {
    window.open(`/recap/${eventId}?insightId=${itemId}`);
  };

  const thumbnailPath = !thumbnail ? 'smart_clip_thumbnail.jpg' : `${thumbnail}`;

  return (
    <Container
      src={`${assetsDomain}/${thumbnailPath}`}
      onClick={handleClick}
      style={{ ...styles }}
    />
  );
};
