import { Modal } from '../../../../componentsV2/Modal';
import { TopAccountsCondensedTable } from './components/TopAccountsCondensedTable';
import { TopAccountFilters } from '../TopAccounts/components/TopAccountsFilters';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../../services/core/core-service';
import { useDashboardFilters } from '../../../../features/shared/dashboard-filters/dashboard-filters.hooks';
import {
  DateRangeFilterStore,
  KeyToFilterStore,
} from '../../../../features/shared/dashboard-filters/types';
import { MentionsContainer } from './components/MentionsContainer';
import { FiltersGroup } from '../../styles';
import { filtersToQueryObject } from '../../utils';
import { useState } from 'react';
import Card from '../../../../componentsV2/Card';

import Styles from './styles';
const { Container, CardContents, ActionsContainer, Details, DetailLabel } = Styles;

interface Props {
  open: boolean;
  topic: {
    id: string;
    name: string;
  };
  currentFilters?: KeyToFilterStore | null;
  onClose: () => void;
}

export const TopicDetailModal: React.FC<Props> = ({ topic, open, onClose }) => {
  const [sortBy, setSortBy] = useState({
    id: 'mentions',
    label: '# of Mentions',
  });

  const { filters, render } = useDashboardFilters();
  const dateRange = (filters['dateRange'] as DateRangeFilterStore)?.value?.interval;

  const { data: topAccounts, isFetching: topAccountsFetching } = useQuery({
    queryKey: ['topic-modal-accounts', filters, topic.id, sortBy],
    queryFn: async () => {
      const dateRange = (filters['dateRange'] as DateRangeFilterStore)?.value?.interval;
      if (!dateRange || !topic.id) return null;

      return await coreService.getTopAccountsByTopicId(
        topic.id,
        dateRange[0]!.toISOString(),
        dateRange[1]!.toISOString(),
        1,
        10,
        sortBy.id,
        filtersToQueryObject(filters),
      );
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const { data: topMentions, isFetching: topicMentionsFetching } = useQuery({
    queryKey: ['topic-modal-topics', filters, topic.id],
    queryFn: async () => {
      const dateRange = (filters['dateRange'] as DateRangeFilterStore)?.value?.interval;
      if (!dateRange || !topic.id) return null;

      return await coreService.getTopMentionsByTopicId(
        topic.id,
        dateRange[0]!.toISOString(),
        dateRange[1]!.toISOString(),
        1,
        10,
        sortBy.id,
        filtersToQueryObject(filters),
      );
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  return (
    <Modal hideFooter width={1296} open={open} title={topic.name} onClose={onClose}>
      <Container>
        <FiltersGroup>
          {render('dateRange')}
          {render('account')}
          {render('industry')}
          {render('lifecycleStage')}
          {render('arr')}
          {render('geography')}
          {render('revenueSegment')}
          {render('dealStage')}
          {render('daysUntilRenewal')}
          {/*render('insightType')*/}
        </FiltersGroup>

        <Card title="Accounts">
          <CardContents>
            <TopAccountFilters
              sortType={sortBy.label}
              sortBy={setSortBy}
              values={topAccounts || null}
              pagination={!!dateRange}
              onPageChange={async (page, size) => {
                return await coreService.getTopAccountsByTopicId(
                  topic.id,
                  dateRange[0]!.toISOString(),
                  dateRange[1]!.toISOString(),
                  page,
                  size,
                  sortBy.id,
                  filtersToQueryObject(filters),
                );
              }}
            >
              <TopAccountsCondensedTable loading={topAccountsFetching} />
            </TopAccountFilters>
          </CardContents>
        </Card>

        <Card title="Mentions">
          <CardContents>
            {/* <ActionsContainer>
              <Button>
              <Space>
              <span>Export to CSV</span>
              <DownloadOutlined />
              </Space>
              </Button>
              </ActionsContainer> */}

            <MentionsContainer
              fetching={topicMentionsFetching}
              mentions={topMentions || null}
              onPageChange={async (page, size) => {
                return await coreService.getTopMentionsByTopicId(
                  topic.id,
                  dateRange[0]!.toISOString(),
                  dateRange[1]!.toISOString(),
                  page,
                  size,
                  sortBy.id,
                  filtersToQueryObject(filters),
                );
              }}
            />
          </CardContents>
        </Card>
      </Container>
    </Modal>
  );
};
