import { useEffect } from 'react';
import { StoryTimeline } from '../../../../../componentsV2/StoryTimeline';
import { useAccountStory } from '../../../../../features/account/account-story/hooks/useAccountStory';
import { useAccountOverview } from '../../../../../features/account/account-overview/account-overview.hook';

type ViewType = 'timeline' | 'narrative';

interface Props {
  accountName: string;
}

export const AccountStory: React.FC<Props> = ({ accountName }) => {
  const { storyNarrative, storyTimeline, isGenerating, lastGeneratedAt, load, generate } =
    useAccountStory();
  const { selectedAccount } = useAccountOverview();

  useEffect(() => {
    load([
      selectedAccount.story.timeline,
      selectedAccount.story.narrative,
      selectedAccount.story.isGenerating,
      selectedAccount.story.lastGeneratedAt
        ? new Date(selectedAccount.story.lastGeneratedAt)
        : null,
    ]);
  }, [selectedAccount]);

  return (
    <StoryTimeline
      style={{ padding: '16px' }}
      storyNarrative={storyNarrative}
      storyTimeline={storyTimeline}
      accountName={accountName}
      isGenerating={isGenerating}
      lastGeneratedAt={lastGeneratedAt}
      onGenerate={generate}
    />
  );
};
