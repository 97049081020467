import { Button, Modal } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import { UpdateLoader } from '../../../../../componentsV2/UpdateLoader';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { coreAPI } from '../../../../../API/core';
import { SmartSummaryItem } from '../../../../../API/types';
import { SearchItem } from '../SearchItem';
import { compareDesc } from 'date-fns';
import style from './style';

interface Props {
  search: {
    accountId: string;
    from?: string;
    to?: string;
    keywords: string;
    userId: string;
  };
  open: boolean;
  onClose: () => void;
}

export const SearchModal: React.FC<Props> = ({ search, open, onClose }) => {
  const { Container, Header, Items, Title, SubTitle } = style;
  const [items, setItems] = useState<SmartSummaryItem[]>([]);

  const result = useQuery({
    queryKey: [search.keywords, search.from, search.to, search.accountId],
    queryFn: async () => {
      const core = new coreAPI();
      return await core.searchSmartSummaryByKey(
        search.accountId,
        search.keywords,
        search.userId,
        search.from,
        search.to,
      );
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (result.isSuccess) {
      setItems(result.data.smartSummaries);
    }
  }, [result]);

  if (result.isLoading) {
    return (
      <Modal open={open} closable={false} centered width={764} footer={null}>
        <Container style={{ justifyContent: 'center' }}>
          <UpdateLoader min text="Searching" />
        </Container>
      </Modal>
    );
  }

  return (
    <Modal open={open} closable={false} centered width={764} footer={null}>
      <Container>
        <Header>
          <Title>
            <FileSearchOutlined style={{ scale: '1.3', marginRight: '10px' }} />
            Search results
          </Title>
          <SubTitle>
            We found {items.length} results related to "{search.keywords}"
          </SubTitle>
        </Header>

        <Items>
          {items
            .sort((a, b) =>
              compareDesc(new Date(a.scheduledStartDate), new Date(b.scheduledStartDate)),
            )
            .map(i => (
              <SearchItem
                key={i.id + 'search-item'}
                title={i.title}
                onClick={() => window.open(`/recap/${i.id}`)}
                date={i.scheduledStartDate}
                headline={i.headline}
              />
            ))}
        </Items>

        <Button
          type="primary"
          style={{ width: '118px', height: '38px', alignSelf: 'end' }}
          onClick={onClose}
        >
          Close
        </Button>
      </Container>
    </Modal>
  );
};
