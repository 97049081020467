import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const RequestUpgradeSVG = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="#D2F9E5" />
      <path
        d="M15.75 25.5L26.25 14.25L24 22.5H32.25L21.75 33.75L24 25.5H15.75Z"
        stroke="#032B21"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const RequestUpgrade: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={RequestUpgradeSVG} {...props} />;
};
