import styled from 'styled-components';
import { colors } from '../../../../../../../theme/colors';

const Container = styled.div<{ isSelected: boolean }>`
  display: grid;
  flex: 1;
  align-items: center;
  box-sizing: border-box;
  grid-template-columns: 30% 20% 27% 23%;
  padding: 8px 12px;
  border-radius: ${props => (props.isSelected ? '0' : '12px')};
  background: ${props => (props.isSelected ? '#FFF8F6' : '#fff')};
  border-radius: ${props => (props.isSelected ? '8px 8px 0 0' : '8px')};

  :hover {
    background-color: #fff8f6;
  }
`;

const DateAndTitleSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 4px;
`;

const TitleFrame = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  display: inline-block;
  color: #0a1e2c;
  font-style: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    color: ${colors.primary['500']};
  }
`;

const Description = styled.span`
  color: #566976;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  padding-left: 10px;
  width: 100%;
`;

const HostSection = styled(Column)`
  justify-content: start;
  gap: 4px;
  padding-left: 20px;
`;

const MeetingTypeSection = styled(Column)`
  color: #0d283b;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  gap: 4px;
  justify-content: start;
`;

const ParticipantsSection = styled(Column)`
  white-space: nowrap;
  font-style: normal;
  color: #0d283b;
  font-weight: 400;
  line-height: 20px;
  font-size: 12px;
`;

const Participants = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Host = styled.div`
  display: block;
  align-items: center;
  color: #0d283b;
  font-style: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  max-width: 180px;
`;

const ActionsSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
`;

const Type = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding-left: 15px;
`;

export default {
  Container,
  DateAndTitleSection,
  TitleFrame,
  Title,
  Description,
  ParticipantsSection,
  Participants,
  HostSection,
  Host,
  MeetingTypeSection,
  ActionsSection,
  Type,
};
